// export const baseurl = "http://stubie.us-east-1.elasticbeanstalk.com/api/";
export const baseurl = "https://api.stubieapp.com/api/";

export const loginApi = `${baseurl}Login/login`;
export const registerApi = `${baseurl}Studio/register`;
export const addStudioApi = `${baseurl}Studio/add_studio`;
export const getstudioType = `${baseurl}Studio/studiotype`;
export const getStudioApi = `${baseurl}Studio/getstudiolist`;
export const otpApi = `${baseurl}Login/verifycode`;
export const updatepasswordApi = `${baseurl}Login/updatepassword`;
export const forgotpasswordApi = `${baseurl}Login/forgotpassword`;
export const logoutApi = `${baseurl}Studio/logout`;
export const logoutApiCus = `${baseurl}Customer/logout`;
export const changepasswordApi = `${baseurl}Login/changepassword`;
export const uploadimageApi = `${baseurl}Studio/uploadimage`;
export const createroomApi = `${baseurl}Studio/createroom`;
export const amenandequiApi = `${baseurl}Studio/equipmentandamenities`;
export const getProfileApi = `${baseurl}Studio/getprofile`;
export const editProfileApi = `${baseurl}Studio/editprofile`;
export const uploadRoomImagesApi = `${baseurl}Studio/upload_roomimages`;
export const studioDetailsApi = `${baseurl}Studio/studiodetail`;
export const roomListApi = `${baseurl}Studio/roomslist`;
export const editAddOnApi = `${baseurl}Studio/editaddon`;
export const roomDeleteApi = `${baseurl}Studio/roomdelete`;
export const contactUsApi = `${baseurl}Studio/contactus`;
export const bankDetailApi = `${baseurl}Studio/bankdetail`;
export const pastBookingApi = `${baseurl}Studio/pastbookings`;
export const aboutStudioApi = `${baseurl}Studio/aboutstudio`;
export const editStudioDetailsApi = `${baseurl}Studio/editstudio`;
export const getBankDetailApi = `${baseurl}Studio/getbankdetail`;
export const reviewListApi = `${baseurl}Studio/reviewlist`;
export const faqApi = `${baseurl}Studio/faqlist`;
export const editStudioImageApi = `${baseurl}Studio/editstudioimage`;
export const earningApi = `${baseurl}Studio/earnings`;
export const CustomerRegistrationApi = `${baseurl}Customer/cusregistration`;
export const customerUploadImageApi = `${baseurl}Customer/uploadimage`;
export const uploadMediaApi = `${baseurl}Studio/mediaupload`;
export const MediaListApi = `${baseurl}Studio/medialist`;
export const CustomerDetailsApi = `${baseurl}Customer/customerdetail`;
export const SearchApi = `${baseurl}Customer/search`;
export const EditRoomApi = `${baseurl}Studio/editroom`;
export const RoomDetailApi = `${baseurl}Studio/roomdetail`;
export const UploadAddOnImagesApi = `${baseurl}Studio/upload_addon`;
export const customerStudioListApi = `${baseurl}Customer/studiolist`;
export const EditCustomerApi = `${baseurl}Customer/editcustomer`;
export const EditCustomerImageApi = `${baseurl}Customer/editcustomerimage`;
export const DefaultImageApi = `${baseurl}Studio/default_image`;
export const CusStudioDetailApi = `${baseurl}Customer/studiodetail`;
export const CusRoomListApi = `${baseurl}Customer/roomlist`;
export const DeclineApi = `${baseurl}Studio/declinebooking`;
export const CancelledBookingApi = `${baseurl}Studio/cancelledbookings`;
export const AcceptBookingApi = `${baseurl}Studio/acceptbooking`;
export const PendingBookingApi = `${baseurl}Studio/pendingbookings`;
export const ConfirmedBookingApi = `${baseurl}Studio/confirmedbookings`;
export const CustomerRoomDetailApi = `${baseurl}Customer/roomdetail`;
export const CustomerAvailabilityApi = `${baseurl}Customer/availabilitycalendar`;
export const CustomerMediaListApi = `${baseurl}Customer/medialist`;
export const CustomerAddressApi = `${baseurl}Customer/addcustomeraddress`;
export const StudioDeleteApi = `${baseurl}Studio/studiodelete`;
export const AllBookingsApi = `${baseurl}Studio/allbookings`;
export const CustomerSummaryApi = `${baseurl}Customer/summary`;
export const StudioReplyApi = `${baseurl}Studio/reply`;
export const ChatImageUploadApi = `${baseurl}Studio/chatupload`;
export const CreateStripeApi = `${baseurl}Customer/createstripecustomer`;
export const RoomBookingApi = `${baseurl}Customer/roombooking`;
export const CustomerBookingDetailApi = `${baseurl}Customer/bookingdetail`;
export const GetCityListApi = `${baseurl}Customer/getcitylist`;
export const CityListApi = `${baseurl}Customer/citystudiolist`;
export const CustomerRatingApi = `${baseurl}Customer/rating`;
export const CustomerCancelBookingApi = `${baseurl}Customer/cancelbooking`;
export const MediaDeleteApi = `${baseurl}Studio/mediadelete`;
export const CusContactUsApi = `${baseurl}Customer/contactus`;
export const CusReplyApi = `${baseurl}Customer/reply`;
export const GetStudioListApi = `${baseurl}Customer/getstudiolist`;
export const CustomerReviewApi = `${baseurl}Customer/reviewlist`;
export const AboutUsApi = `${baseurl}Login/aboutus`;
export const ChangeStudioAddressApi = `${baseurl}Studio/changeaddress`;
export const SubscribeApi = `${baseurl}Studio/subscription`;
