import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  AcceptBookingApi,
  CustomerBookingDetailApi,
  CustomerCancelBookingApi,
  CustomerDetailsApi,
  CustomerRatingApi,
  DeclineApi,
} from "../Apis";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import moment from "moment";
import ReactStars from "react-rating-stars-component";

function CusBooking() {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [studioId, setStudioId] = useState("");
  const [namebooking, setNamebooking] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [rating, setRating] = useState("");
  const [hover, setHover] = useState(0);
  const [error, setError] = useState({});
  const [note, setNote] = useState("");
  const [Result, setResult] = useState([]);
  const [RoomDetails, setRoomDetails] = useState([]);
  const [Dates, setDates] = useState("");
  const [Start, setStart] = useState("");
  const [End, setEnd] = useState("");
  const [AddOn, setAddOn] = useState([]);
  const [Studio, setStudio] = useState([]);
  const [Hours, setHours] = useState("");
  const [CustomerData, setCustomerData] = useState([]);
  const [AddPrice, setAddPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [price, setPrice] = useState("");
  let location = useLocation();
  let navigate = useNavigate();

  const CustomerDetail = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      devicetype: "abc",
      devicetoken: "abc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setCustomerData(result.data);
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const checkout = () => {
    let price2 =
      parseFloat(Result?.serviceFee) +
      parseFloat(Result?.tax) +
      parseFloat(RoomDetails.price_per_hour) * parseFloat(Hours);
    console.log("price222", price2);

    let price1 =
      parseFloat(Result?.serviceFee) +
      parseFloat(Result?.tax) +
      parseFloat(RoomDetails.price_per_hour) * parseFloat(Hours) +
      parseFloat(price);

    navigate("/checkout", {
      state: {
        Result: Result,
        RoomDetails: RoomDetails,
        Dates: Dates,
        Start: Start,
        End: End,
        AddOn: AddOn,
        Hours: Hours,
        totalPrice: AddOn.length === 0 ? price2 : price1,
      },
    });
  };
  useEffect(() => {
    CustomerDetail();
    if (location?.state?.StudioId) {
      setStudioId(location?.state?.StudioId);
    }

    if (location?.state?.data) {
      setResult(location?.state?.data);
      console.log("resullsl", location?.state?.data);
    }

    if (location?.state?.RoomDetail) {
      setRoomDetails(location?.state?.RoomDetail);
      console.log("roooooo", location?.state?.RoomDetail);
    }
    //console.log("stuudud", location?.state?.RoomDetail);
    if (location?.state?.dat) {
      setDates(location?.state?.dat);
    }
    if (location?.state?.start) {
      setStart(location?.state?.start);
    }

    if (location?.state?.end) {
      setEnd(location?.state?.end);
    }
    if (location?.state?.addOn) {
      setAddOn(location?.state?.addOn);
      let price = location?.state?.addOn.map((n) => Number(n.price));

      const sumWithInitial = price.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      //console.log("tooooo", sumWithInitial);
      setPrice(sumWithInitial);
    }
    if (location?.state?.studioDetails) {
      setStudio(location?.state?.studioDetails);
    }
    if (location?.state?.Hours) {
      setHours(location?.state?.Hours);
      console.log("horsssss", location?.state?.Hours);
    }
    if (location?.state?.addonPrice) {
      setAddPrice(location?.state?.addonPrice);
      //console.log("asasga", location?.state?.addonPrice);
    }

    console.log(
      "feeee",
      Number(
        (parseFloat(RoomDetails.price_per_hour) / 100) *
          parseFloat(Result?.serviceFee)
      )
    );
    console.log(
      "ftttt",
      Number(
        (parseFloat(Result?.tax) / 100) *
          parseFloat(RoomDetails.price_per_hour) +
          (parseFloat(RoomDetails.price_per_hour) / 100) *
            parseFloat(Result?.serviceFee)
      )
    );

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header />
      <div className="stubieprofilebannersection">
        <div className="container-fluid">
          <h1 className="changepasswordmainheadingsetting">Booking Detail</h1>
        </div>
      </div>
      <div className="bookingdeatailmainsection bookingdetails-studio pagenewclass">
        <div className="container">
          {Studio !== undefined ? (
            <>
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Studio Detail</h5>
                      <div className="bookingstudiodetail">
                        <div className="stubiestudiodetailimagebox">
                          {Studio.profile_pic !== "" ? (
                            <img
                              src={
                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                Studio.profile_pic
                              }
                            />
                          ) : (
                            <img src="images/profiledefault.jpg" />
                          )}
                        </div>

                        <div className="stubiestudiotextdetail">
                          <h6>{Studio.studio_name}</h6>
                          <h4>{Studio.studio_location}</h4>
                        </div>
                      </div>
                      <div className="stubiebookingroomname">
                        <h2>Room Name</h2>
                        <p>{RoomDetails.room_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Customer Detail</h5>
                      <div className="stubiebookingroomname">
                        <h2>Customer Name</h2>
                        <p>
                          {CustomerData.firstname} {CustomerData.lastname}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Date and Time</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="stubiebookingroomname">
                            <h2>Start Date and Time</h2>
                            <p>
                              {moment(Start, ["HH.mm"]).format("hh:mm a")}{" "}
                              {moment(Dates, "DD/MM/YYYY").format("MM-DD-YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="stubiebookingroomname">
                            <h2>End Date and Time</h2>
                            <p>
                              {moment(End, ["HH.mm"]).format("hh:mm a")}{" "}
                              {moment(Dates, "DD/MM/YYYY").format("MM-DD-YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Bill Details</h5>
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Room Price</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          {RoomDetails?.price_per_hour ? (
                            <h6>
                              $
                              {new Intl.NumberFormat("en-US").format(
                                Number(RoomDetails?.price_per_hour)?.toFixed(2)
                              )}
                              {/* {Number(RoomDetails?.price_per_hour)?.toFixed(2)} */}{" "}
                              Per Hour
                            </h6>
                          ) : (
                            <h6>$ 0</h6>
                          )}
                        </div>
                      </div>
                      {AddOn?.length !== 0 ? (
                        <div className="bookingstudiodetail d-flex">
                          <div className="stubiestudiotextdetail">
                            <h6 className="bookingpricesetting">
                              Addons Price
                            </h6>
                          </div>
                          <div className="stubiestudiotextdetailprice ">
                            {price ? (
                              <h6>
                                $
                                {new Intl.NumberFormat("en-US").format(
                                  Number(price)?.toFixed(2)
                                )}
                                {/* {Number(price)?.toFixed(2)} */}
                              </h6>
                            ) : (
                              <h6>$ 0</h6>
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Service Fee</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          {Result?.serviceFee ? (
                            <h6>
                              $
                              {new Intl.NumberFormat("en-US").format(
                                Number(Result?.serviceFee)?.toFixed(2)
                              )}
                              {/* {Number(Result?.serviceFee)?.toFixed(2)} */}
                            </h6>
                          ) : (
                            <h6>$ 0</h6>
                          )}
                        </div>
                      </div>
                      {Result?.tax === 0 ? null : (
                        <div className="bookingstudiodetail d-flex">
                          <div className="stubiestudiotextdetail">
                            <h6 className="bookingpricesetting">Tax Fee </h6>
                          </div>
                          <div className="stubiestudiotextdetailprice">
                            {Result?.tax ? (
                              <h6>
                                $
                                {new Intl.NumberFormat("en-US").format(
                                  Number(Result?.tax)?.toFixed(2)
                                )}
                                {/* {Number(Result?.tax)?.toFixed(2)} */}
                              </h6>
                            ) : (
                              <h6>$ 0</h6>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">
                            Number of Hours
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          <h6>{Hours}</h6>
                          {/* {Result?.tax ? (
                            <h6>$ {Number(Result?.tax)?.toFixed(2)}</h6>
                          ) : (
                            <h6>$ 0</h6>
                          )} */}
                        </div>
                      </div>
                      {/* <h6>
                        {AddOn.length === 0 ? (
                          <>
                            ${" "}
                            {parseFloat(Result?.serviceFee) +
                              parseFloat(Result?.tax) +
                              parseFloat(RoomDetails.price_per_hour)}
                          </>
                        ) : (
                          <>
                            ${" "}
                            {parseFloat(Result?.serviceFee) +
                              parseFloat(Result?.tax) +
                              parseFloat(RoomDetails.price_per_hour) +
                              parseFloat(price)}
                          </>
                        )}
                      </h6> */}
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting customtotalpricesetting">
                            Total Price
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice  ">
                          {AddOn.length === 0 ? (
                            <>
                              <h6 className="customtotalmoneysetting">
                                ${" "}
                                {new Intl.NumberFormat("en-US").format(
                                  parseFloat(Result?.serviceFee) +
                                    parseFloat(Result?.tax) +
                                    parseFloat(RoomDetails.price_per_hour) *
                                      parseFloat(Hours)
                                )}
                                {/* {parseFloat(Result?.serviceFee) +
                                  parseFloat(Result?.tax) +
                                  parseFloat(RoomDetails.price_per_hour)} */}
                              </h6>
                            </>
                          ) : (
                            <>
                              <h6 className="customtotalmoneysetting">
                                ${" "}
                                {new Intl.NumberFormat("en-US").format(
                                  parseFloat(Result?.serviceFee) +
                                    parseFloat(Result?.tax) +
                                    parseFloat(RoomDetails.price_per_hour) *
                                      parseFloat(Hours) +
                                    parseFloat(price)
                                )}
                                {/* {parseFloat(Result?.serviceFee) +
                                  parseFloat(Result?.tax) +
                                  parseFloat(RoomDetails.price_per_hour) +
                                  parseFloat(price)} */}
                              </h6>
                            </>
                          )}
                        </div>
                        {/* <div className="stubiestudiotextdetailprice  ">
                          {AddOn.length === 0 ? (
                            <>
                              {RoomDetails?.price_per_hour &&
                              Result?.serviceFee &&
                              Result?.tax ? (
                                <>
                                  <h6 className="customtotalmoneysetting">
                                    $ {"   "}
                                    {parseFloat(Result?.serviceFee) +
                                      parseFloat(Result?.tax) +
                                      parseFloat(RoomDetails?.price_per_hour)}
                                  </h6>
                                </>
                              ) : (
                                <h6>$ 0</h6>
                              )}
                            </>
                          ) : (
                            <>
                              {RoomDetails?.price_per_hour &&
                              Result?.serviceFee &&
                              Result?.tax &&
                              price ? (
                                <>
                                  <h6 className="customtotalmoneysetting">
                                    $ {"  "}
                                    {parseFloat(Result?.serviceFee) +
                                      parseFloat(Result?.tax) +
                                      parseFloat(RoomDetails?.price_per_hour) +
                                      parseFloat(price)}
                                  </h6>
                                </>
                              ) : (
                                <h6>$ 0</h6>
                              )}
                            </>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bookingchatbtnleft">
                      <Link
                        to={`/customer`}
                        state={{
                          pending: 3,
                          studio: Studio.userid,
                          studioName: Studio.studio_owner,
                          studioImage:
                            "https://stubies.s3.us-east-2.amazonaws.com/" +
                            Studio.profile_pic,
                        }}
                        className=" roomaddbuttonsave"
                      >
                        <img src="images/chatbookingpage.png" /> Have a
                        Question?
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bookingdeatailfinalbtnright">
                      <ul>
                        <li>
                          <button
                            type="button"
                            onClick={() => {
                              checkout();
                              // let a =
                              //   parseFloat(Result?.serviceFee) +
                              //   parseFloat(Result?.tax) +
                              //   parseFloat(RoomDetails.price_per_hour);
                              // let b = new Intl.NumberFormat("en-US").format(a);
                              // console.log("priiriri", b);
                            }}
                            className="bookingdeclinebtnsetting"
                          >
                            Proceed to Pay {"  "}
                            {AddOn.length === 0 ? (
                              <>
                                <h6>
                                  ${" "}
                                  {new Intl.NumberFormat("en-US").format(
                                    parseFloat(Result?.serviceFee) +
                                      parseFloat(Result?.tax) +
                                      parseFloat(RoomDetails.price_per_hour) *
                                        parseFloat(Hours)
                                  )}
                                  {/* {parseFloat(Result?.serviceFee) +
                                  parseFloat(Result?.tax) +
                                  parseFloat(RoomDetails.price_per_hour)} */}
                                </h6>
                              </>
                            ) : (
                              <>
                                <h6>
                                  ${" "}
                                  {new Intl.NumberFormat("en-US").format(
                                    parseFloat(Result?.serviceFee) +
                                      parseFloat(Result?.tax) +
                                      parseFloat(RoomDetails.price_per_hour) *
                                        parseFloat(Hours) +
                                      parseFloat(price)
                                  )}
                                  {/* {parseFloat(Result?.serviceFee) +
                                  parseFloat(Result?.tax) +
                                  parseFloat(RoomDetails.price_per_hour) +
                                  parseFloat(price)} */}
                                </h6>
                              </>
                            )}
                            {/* {AddOn.length === 0 ? (
                              <>
                                {RoomDetails.price_per_hour &&
                                Result?.serviceFee &&
                                Result?.tax ? (
                                  <>
                                    <h6>
                                      $ {"  "}
                                      {parseFloat(Result?.serviceFee) +
                                        parseFloat(Result?.tax) +
                                        parseFloat(RoomDetails.price_per_hour)}
                                    </h6>
                                  </>
                                ) : (
                                  <h6>$ 0</h6>
                                )}
                              </>
                            ) : (
                              <>
                                {RoomDetails.price_per_hour &&
                                Result?.serviceFee &&
                                Result?.tax &&
                                price ? (
                                  <>
                                    <h6>
                                      $ {"  "}
                                      {parseFloat(Result?.serviceFee) +
                                        parseFloat(Result?.tax) +
                                        parseFloat(RoomDetails.price_per_hour) +
                                        parseFloat(price)}
                                    </h6>
                                  </>
                                ) : (
                                  <h6>$ 0</h6>
                                )}
                              </>
                            )} */}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            </>
          ) : null}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default CusBooking;
