import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Loader from "../Loader/Loader";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import Swiper styles
import "swiper/css";

import { Autoplay, Pagination, Navigation } from "swiper";

// import required modules
// import { Navigation } from "swiper";
import bootstrap from "bootstrap";
import { forgotpasswordApi } from "../Apis";

function Forgot() {
  const [values, setValues] = useState({
    email: "",
  });

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleData = (e) => {
    const { name, value } = e.target;
    // console.log(name);
    setValues({ ...values, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const Data = (e) => {
    e.preventDefault();
    // CallApi();
    //setIsLoading(true);
    setError(validate(values));
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      Data();
    }
  };
  //form validations
  const validate = (values) => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!values.email) {
      error.email = "email is required.";
    } else if (!reg.test(values.email)) {
      error.email = "Invalid email .";
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      CallApi();
    }
    return error;
  };

  const CallApi = () => {
    setIsLoading(true);
    var data = JSON.stringify({
      emailid: values.email,
    });

    var config = {
      method: "post",
      url: forgotpasswordApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          navigate("/otp", { state: { email: values.email } });
          setMessage(response.data.message);
          //alert(response.data.message);
        } else {
          setMessage(response.data.message);
          //alert(response.data.message);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}

      <div className="loginmainsection">
        <div className="row g-0 flex-direction-row">
          <div className="col-md-6">
            <div className="loginleftsection custommargintopforgot">
              <div className="mainloginlogobox">
                <Link to="/">
                  <img src="images/mainlogo.png" />
                </Link>
              </div>
              <h2>Forgot Password</h2>
              <h6>
                Please enter the email you used at the time of registration to
                get the password reset instructions.
              </h6>
              <form>
                <div className="row">
                  {message && (
                    <span className="customvalidationdesignlogin">
                      {message}
                    </span>
                  )}
                  <div className="col-md-12">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="stubie@example.com"
                        name="email"
                        value={values.email}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />
                      {error.email && (
                        <span className="customvalidationdesignlogin">
                          {error.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mainloginbutton">
                      <div className="loginbuttonlink">
                        <button type="submit" onClick={Data}>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="loginrighttsection">
              <div className="loginrightinnertextsectionupper">
                <Swiper
                  spaceBetween={30}
                  autoplay={{
                    delay: 2800,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  //navigation
                  //pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  <SwiperSlide>
                    <div className="sliderwidthtext">
                      <h1>
                        Booking your next <span>session</span> <br />
                        is now at your fingertips
                      </h1>
                      <p>
                        Unlock your creative potential within extraordinary
                        studio spaces.Discover - Reserve - Create for all
                        creatives such as  musicians, photographers, dancers,
                        filmmaker more.  Elevate your artistry with
                        state-of-the-art studios for musicians, photographers,
                        dancers, and filmmakers.
                      </p>
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Forgot;
