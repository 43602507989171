import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faqApi } from "../Apis";
import Persona from "persona";

function GetVerifiedCus() {
  const [inquiryId, setInquiryId] = useState("");
  const [status, setStatus] = useState("");
  let navigate = useNavigate();
  let location = useLocation();
  const [options, setOptions] = useState({
    templateId: "tmpl_JAZjHuAT738Q63BdgCuEJQre",
  });

  const [flowType, setFlowType] = useState("embedded");

  const embeddedClientRef = useRef(null);

  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: "sandbox",
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }
        client.open();
      },
      onStart: (inquiryId) => {
        console.log(JSON.stringify(inquiryId));
      },
      onComplete: (inquiryId) => {
        console.log(JSON.stringify(inquiryId));
        setInquiryId(inquiryId.inquiryId);
        setStatus(inquiryId.status);
        console.log("ghhhhh", inquiryId);
        console.log("tttttt", inquiryId.status);
        fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="col-md-9">
        <div className="settingmainlinksbuttondetailboxright">
          <div className="row">
            <div className="col-md-12">
              <h4 className="settingeditstudioprofileheading  custompastbookingheading">
                Start Verification Process
              </h4>
            </div>
            <div className="col-md-12">
              {flowType === "embedded" && !!options.templateId && (
                <section>
                  <button className="govtid" onClick={createClient}>
                    Start Verification
                    {/* Upload Government ID */}
                  </button>
                  {/* <button
                  onClick={() => embeddedClientRef.current.open()}
                ></button> */}
                  {/* <p>
              Close widget with <code>window.exit(force?: boolean)</code>.
            </p> */}
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GetVerifiedCus;
