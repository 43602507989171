import React, { useState, useEffect } from "react";

function CusFAQ(){
const[data,setData]=useState([])
    const cusFaq=()=>{
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        fetch(
          "http://stubie.us-east-1.elasticbeanstalk.com/api/Customer/faqlist",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            //console.log(result)
        setData(result.data)
        })
          .catch((error) => { 
            console.log("error", error)
        });
    }
    
    useEffect(()=>{
cusFaq()
   window.scrollTo(0, 0);
    },[])

    return (
      <>
        <div className="col-md-9">
          <div className="settingmainlinksbuttondetailboxright">
            <div className="row">
              <div className="col-md-12">
                <h4 className="settingeditstudioprofileheading  custompastbookingheading">
                  FAQ
                </h4>
              </div>
              <div className="col-md-12">
                {data.map((item, id) => (
                  <div className="faqdropdowninfosection">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + id}
                            aria-expanded="false"
                            aria-controls={"collapseOne" + id}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={"collapseOne" + id}
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">{item.answer}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default CusFAQ;