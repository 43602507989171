import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Login/Login";
import SignUp from "./Components/SignUp/SignUp";
import CreatePassword from "./Components/CreatePassword/CreatePassword";
import Forgot from "./Components/Forgot/Forgot";
import OTP from "./Components/OTP/OTP";
import UpdatePassword from "./Components/UpdatePassword/UpdatePassword";
import Home from "./Components/Home/Home";
import MyProfile from "./Components/Home/MyProfile";
import ChangePassword from "./Components/ChangePassword/ChangePassword";
import PastBooking from "./Components/Home/PastBooking";
import PendingBooking from "./Components/Home/PendingBooking";
import CanceledBooking from "./Components/Home/CanceledBooking";
import ConfirmedBooking from "./Components/Home/ConfirmedBooking";
import StudioProfileRoom from "./Components/Home/StudioProfileRooms";
import AboutUs from "./Components/Home/AboutUs";
import ContactUs from "./Components/Home/ContactUs";
import Protected from "./Components/Protected";
import BookingDetails from "./Components/Home/BookingDetails";
import SignUpCustomer from "./Components/SignUp/SignUpCustomer";
import RoomProfile from "./Components/Home/RoomProfile";
import Customer from "./Components/Home/Customer";
import CusStudioDetail from "./Components/Home/CusStudioDetail";
import CusRoomDetail from "./Components/Home/CusRoomDetail";
import CheckOut from "./Components/Home/CheckOut";
import TermsAndConditions from "./Components/Home/TermsAndConditions";
import CusBookingDetail from "./Components/Home/CusBookingDetail";
import StudioPersona from "./Components/Home/StudioPersona";
import TermsAndCondBank from "./Components/Home/TermsAndCondBank";
import TermsAndCondAdd from "./Components/Home/TermsAndCondAdd";
import TermsCustomer from "./Components/Home/TermsCustomer";
import CusBooking from "./Components/Home/CusBooking";
//import "swiper/css/bundle";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Protected Cmp={Home} />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signupcustomer" element={<SignUpCustomer />} />
          <Route path="/createpassword" element={<CreatePassword />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/updatepassword" element={<UpdatePassword />} />

          <Route path="/myprofile" element={<Protected Cmp={MyProfile} />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/pastbooking" element={<PastBooking />} />
          <Route path="/pendingbooking" element={<PendingBooking />} />
          <Route path="/canceledbooking" element={<CanceledBooking />} />
          <Route path="/confirmedbooking" element={<ConfirmedBooking />} />
          <Route
            path="/studioprofileroom"
            element={<Protected Cmp={StudioProfileRoom} />}
          />
          <Route path="/aboutus" element={<AboutUs />} />
          {/* <Route path="/aboutus" element={<Protected Cmp={AboutUs} />} /> */}
          {/* <Route path="/contactus" element={<Protected Cmp={ContactUs} />} /> */}
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/bookingdetails"
            element={<Protected Cmp={BookingDetails} />}
          />
          <Route
            path="/roomprofile"
            element={<Protected Cmp={RoomProfile} />}
          />
          <Route path="/customer" element={<Protected Cmp={Customer} />} />
          <Route
            path="/cusstudiodetail"
            element={<Protected Cmp={CusStudioDetail} />}
          />
          <Route
            path="/cusroomdetail"
            element={<Protected Cmp={CusRoomDetail} />}
          />
          {/* <Route
            path="/checkout"
            element={<Protected Cmp={CheckOut} />}
          /> */}
          <Route path="/checkout" element={<CheckOut />} />
          {/* <Route
            path="/termsandconditions"
            element={<Protected Cmp={TermsAndConditions} />}
          /> */}
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/termsandcondbank" element={<TermsAndCondBank />} />
          <Route path="/termsandcondadd" element={<TermsAndCondAdd />} />
          <Route path="/termscustomer" element={<TermsCustomer />} />
          {/* <Route
            path="/termsandcondbank"
            element={<Protected Cmp={TermsAndCondBank} />}
          /> */}
          <Route
            path="/cusbookingdetail"
            element={<Protected Cmp={CusBookingDetail} />}
          />
          <Route path="/cusbooking" element={<Protected Cmp={CusBooking} />} />
          <Route path="/studiopersona" element={<StudioPersona />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
