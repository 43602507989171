import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

function CreatePassword() {
  const [formValues, setFormValues] = useState({
    userid: "",
    new_password: "",
    old_password: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  let navigate = useNavigate();

  let location = useLocation();

  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const myData = (e) => {
    //console.log("hello submit");
    e.preventDefault();
    //CallApi();
    setError(validate(formValues));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  //form validations
  const validate = (formValues) => {
    let error = {};

    if (!formValues.new_password) {
      error.new_password = "password is required.";
    } else if (formValues.new_password.length < 6) {
      error.new_password = "password is not valid.";
    }
    if (!formValues.old_password) {
      error.old_password = "password is required.";
    } else if (formValues.old_password.length < 6) {
      error.old_password = "password is not valid.";
    }

    console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      CallApi();
    }
    return error;
  };

  const CallApi = () => {
    var data = JSON.stringify({
      userid: "f96cfe78-197a-4cdc-8065-3d9cff1cda2f",
      new_password: formValues.new_password,
      old_password: formValues.old_password,
    });

    var config = {
      method: "post",
      url: "http://stubie.us-east-1.elasticbeanstalk.com/api/Login/changepassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          navigate("/login");
          setMessage(response.data.message);
          //alert(response.data.message);
        } else {
          setMessage(response.data.message);
          //alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="loginmainsection">
      <div className="row g-0 flex-direction-row">
        <div className="col-md-6">
          <div className="loginleftsection">
            <div className="mainloginlogobox">
              <Link to="/">
                <img src="images/mainlogo.png" />
              </Link>
            </div>
            <h2 className="customcreateaccountheadingsetting">
              Create A New Password
            </h2>

            <form onSubmit={myData}>
              <div className="row">
                {message && (
                  <span className="customvalidationdesignlogin">{message}</span>
                )}
                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="**********"
                    name="old_password"
                    value={formValues.old_password}
                    onChange={handleData}
                    onKeyDown={handleKeyPress}
                  />
                  {error.old_password && (
                    <span className="customvalidationdesignlogin">
                      {error.old_password}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="**********"
                    name="new_password"
                    value={formValues.new_password}
                    onChange={handleData}
                    onKeyDown={handleKeyPress}
                  />
                  {error.new_password && (
                    <span className="customvalidationdesignlogin">
                      {error.new_password}
                    </span>
                  )}
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="**********"
                    name="new_password"
                    value={formValues.new_password}
                    onChange={handleData}
                    onKeyDown={handleKeyPress}
                  />
                  {error.new_password && (
                    <span className="customvalidationdesignlogin">
                      {error.new_password}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mainloginbutton">
                    <div className="loginbuttonlink">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="loginrighttsection">
            <div className="loginrightinnertextsectionupper">
              <h1>
                Modern <span>Interior</span> <br />
                Design Studio
              </h1>
              <p>
                Unleash creativity in extraordinary studio spaces. Discover,
                book, and create in inspiring sanctuaries at Stubie. Elevate
                your artistry with state-of-the-art studios for musicians,
                photographers, dancers, and filmmakers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreatePassword;
