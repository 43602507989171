import React, { useEffect, useState, useRef } from "react";
import {
  amenandequiApi,
  DefaultImageApi,
  editAddOnApi,
  EditRoomApi,
  UploadAddOnImagesApi,
  uploadRoomImagesApi,
} from "../Apis";
import axios from "axios";
import { Modal } from "bootstrap";
import Loader from "../Loader/Loader";

function EditRoom(props) {
  const [room_name, setRoomName] = useState("");
  const [description, setDescription] = useState("");
  const [capacity, setCapacity] = useState("");
  const [price_per_hour, setPrice] = useState("");
  const [equipments1, setEquipments1] = useState([]);
  const [amenities1, setAmenities1] = useState([]);
  const [equipments3, setEquipments3] = useState([]);
  const [amenities3, setAmenities3] = useState([]);
  const [equipments2, setEquipments2] = useState("");
  const [amenities2, setAmenities2] = useState("");
  const [addOns, setAddOns] = useState([]);
  const [addOns1, setAddOns1] = useState([]);
  const [images, setImages] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checked1, setChecked1] = useState([]);
  const [addOnName, setAddOnName] = useState("");
  const [addOnId, setAddOnId] = useState("");
  const [addOnPrice, setAddOnPrice] = useState("");
  const [addOnDescription, setAddOnDescription] = useState("");
  const [addOnImage, setAddOnImage] = useState("");
  const [addservice, setAddService] = useState(false);
  const [otheramenities, setOtherAmenities] = useState("");
  const [otherequipments, setOtherEquipments] = useState("");
  const [otherquip, setOtherequip] = useState(false);
  const [otheramen, setOtheramen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [addOnName1, setAddOnName1] = useState("");
  const [addOnPrice1, setAddOnPrice1] = useState("");
  const [addOnImage1, setAddOnImage1] = useState("");
  const [addOnDescription1, setAddOnDescription1] = useState("");
  const [status, setStatus] = useState(false);
  const [deleteid, setDeleteId] = useState("");
  const [deletearray, setdeletedArray] = useState("");
  const [loading, setLoading] = useState(false);
  const [offlineId, setOffLineId] = useState("");

  const equandamen = (q) => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(amenandequiApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setEquipments1(result.equipments);
        let data = result.equipments.map((n) => n.name);

        setAmenities1(result.amenities);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const EditRoomValidation = () => {
    let error = {};
    if (!capacity) {
      error.capacity = "Total Number of Guests are required.";
    }
    if (!price_per_hour) {
      error.price_per_hour = "Price is required.";
    }
    if (!description) {
      error.description = "Description is required.";
    }
    if (!room_name) {
      error.room_name = "Room Name is required.";
    }
    if (checked.length === 0) {
      error.equipments2 = "Equipments are required.";
    }
    if (checked1.length === 0) {
      error.amenities2 = "Amenities are required.";
    }
    if (!images) {
      error.images = "Room Images are required.";
    }
    // console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      EditRoom();
    }
    return error;
  };

  const roomEdit = () => {
    setError(EditRoomValidation());
  };

  const EditRoom = () => {
    setIsLoading(true);
    props.loading(true);
    let equi = checked.map((item) => item.name);
    //alert(JSON.stringify(equi))
    let equi1 = equi.toString();
    //console.log("equipments",equi1)
    let amen = checked1.map((item) => item.name);
    let amen1 = amen.toString();
    // console.log("amenities", amen1);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      addons: addOns1,
      amenities: amen1,
      capacity: capacity,
      deleteaddons: String(deleteid),
      deleteimages: String(deletearray),
      description: description,
      equipments: equi1,
      images: images,
      price_per_hour: price_per_hour,
      room_name: room_name,
      room_id: props.roomdata.data.id,
      studio_id: props.roomdata.data.studio_id,
      userid: props.roomdata.data.userid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(EditRoomApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        var myModalEl = document.getElementById("editroommodal");
        var modal = Modal.getInstance(myModalEl);
        modal.hide();
        setDeleteId([]);
        props.loading(false);
        props.onpress(result);
        setdeletedArray([]);
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log("error", error);
        //window.location.reload();
        props.loading(false);
        setIsLoading(false);
      });
  };

  const handleChecke = (e) => {
    let filtered = [];
    let alreadyData = [];
    if (e.target.checked) {
      //filtered = [...equipments, e.target.value];
      let filtered1 = equipments1.filter(
        (item) => item.name === e.target.value
      );
      filtered = [...checked, ...filtered1];
    } else {
      filtered.splice(equipments1.indexOf(e.target.value), 1);
      alreadyData = checked;
      alreadyData.splice(checked.indexOf(e.target.value), 1);
      //console.log(e.target.value);
    }

    setChecked([...filtered, ...alreadyData]);
    //console.log(filtered);

    setError("");
  };

  const handleChecke1 = (e) => {
    let filtereddata = [];
    let alreadyData = [];
    if (e.target.checked) {
      //filtered = [...equipments, e.target.value];
      let filtered2 = amenities1.filter((item) => item.name === e.target.value);
      filtereddata = [...checked1, ...filtered2];
    } else {
      filtereddata.splice(amenities1.indexOf(e.target.value), 1);
      alreadyData = checked1;
      alreadyData.splice(checked1.indexOf(e.target.value), 1);
      //console.log(e.target.value);
    }
    setChecked1([...filtereddata, ...alreadyData]);

    // console.log(filtereddata);

    setError("");
  };

  const EditAddOnValidations = () => {
    let error = {};
    if (!addOnName) {
      error.addOnName = "Name is required.";
    }
    if (!addOnPrice) {
      error.addOnPrice = "Price is required.";
    }
    if (!addOnDescription) {
      error.addOnDescription = "Description is required.";
    }
    // if (!addOnImage) {
    //   error.addOnImage = "Image is required.";
    // }
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      EditAddOns();
    }
    return error;
  };

  const EditAddOns = () => {
    if (addOnId) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        studio_id: props.roomdata.data.studio_id,
        userid: props.roomdata.data.userid,
        room_id: props.roomdata.data.id,
        addon_id: addOnId,
        name: addOnName,
        price: addOnPrice,
        description: addOnDescription,
        image: addOnImage,
      });
      //console.log("iiii", addOnId);
      //console.log(raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(editAddOnApi, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          //console.log(result);
          if (result.error !== "1") {
            let index = addOns.findIndex((n) => n.id === addOnId);
            addOns.splice(index, 1, result.data);

            setAddOnImage("");
            setAddOnId("");

            setStatus(false);
            setAddOns(addOns);
          }
        })
        .catch((error) => {
          setStatus(false);
          console.log("error", error);
        });
    } else {
      let index = addOns.findIndex((n) => n.offlineId === offlineId);
      let object2 = {
        offlineId: offlineId,
        name: addOnName,
        price: addOnPrice,
        description: addOnDescription,
        image: addOnImage,
      };
      addOns.splice(index, 1, object2);

      setAddOnImage("");
      setAddOnId("");
      setOffLineId("");
      setStatus(false);
      setAddOns(addOns);
    }
  };

  const defaultImage = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      image_id: images[0].url,
      studio_id: props.roomdata.data.studio_id,
      room_id: props.roomdata.data.id,
      userid: props.roomdata.data.userid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DefaultImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const uploadRoomImages = async (newFile, id) => {
    setLoading(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("userid", props.roomdata.data.userid);
    data.append("images", newFile);
    //data.append("folder", Folder);
    data.append("studio_id", props.roomdata.data.studio_id);

    const response = await axios.post(uploadRoomImagesApi, data);
    setLoading(false);
    let image = response.data.data;

    let array = { id: id, url: image[0], status: "0 " };
    defaultImage();
    return array;
  };

  const uploadAddImages = async (newFile, type) => {
    var FormData = require("form-data");

    var data = new FormData();
    data.append("userid", props.roomdata.data.userid);
    data.append("image", newFile);
    data.append("studio_id", props.roomdata.data.studio_id);
    const response = await axios.post(UploadAddOnImagesApi, data);
    if (type === "edit") {
      setAddOnImage(response.data.data);
    } else {
      setAddOnImage1(response.data.data);
    }
  };

  const handleRoomAdd = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    uploadAddImages(newFile, "add");
    //setAddImgErr("");
    setError("");
  };

  const handleRoomEdit = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    uploadAddImages(newFile, "edit");
    //setAddImgErr("");
    setError("");
  };

  const handleRoom = async (e) => {
    let array = [];
    for (let i = 0; i <= e.target.files.length - 1; i++) {
      var file = e.target.files[i];
      let size = e.target.files[i].type.replace("/", ".");
      var blob = file.slice(0, file.size, e.target.files[i].type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, {
        type: e.target.files[i].type,
      });

      let data = await uploadRoomImages(newFile, i);
      array.push(data);
    }

    setImages([...images, ...array]);
    //setImageErr("");
  };

  const AddOnValidations = () => {
    let error = {};
    if (!addOnName1) {
      error.addOnName1 = "Name is required.";
    }
    if (!addOnPrice1) {
      error.addOnPrice1 = "Price is required.";
    }
    if (!addOnDescription1) {
      error.addOnDescription1 = "Description is required.";
    }
    // if (!addOnImage1) {
    //   error.addOnImage = "Image is required.";
    // }
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      Add1();
    }
    return error;
  };

  const Add1 = () => {
    let Array = [];
    let object = {
      addonimages: addOnImage1.toString(),
      addonname: addOnName1,
      addonprice: addOnPrice1,
      addondescription: addOnDescription1,
    };
    let object2 = {
      offlineId: addOns.length + 1,
      image: addOnImage1.toString(),
      name: addOnName1,
      price: addOnPrice1,
      description: addOnDescription1,
    };
    if (
      (addOnName1 !== "" &&
        addOnPrice1 !== "" &&
        addOnDescription1 !== "" &&
        addOnImage1.toString() !== "") ||
      addOnImage1.toString() === ""
    ) {
      Array = [object];
      let Array2 = [object2];

      let addonArray = [...addOns1, ...Array];

      setAddOns1(addonArray);

      setAddOns([...Array2, ...addOns]);
      //console.log("addddd", addonArray);
    }
  };

  const AddServices = () => {
    setError(AddOnValidations());
  };

  const DeleteImage = (e) => {
    const index = images.findIndex((n) => n.id === e.id);
    let newPreview = images;
    newPreview.splice(index, 1);
    setImages([...newPreview]);
    let deleteImage = e.url;
    setdeletedArray([...deletearray, ...deleteImage]);
    // console.log("delete", newPreview);
  };

  const DeleteImage1 = (e) => {
    const index = addOns.findIndex((n) => n.id === e.id);
    let newPreview = addOns;
    newPreview.splice(index, 1);
    setAddOns([...newPreview]);
    let array = [e.id];
    setDeleteId([...deleteid, ...array]);
    // console.log("delete", newPreview);
  };

  useEffect(() => {
    //console.log("ggjj", props.roomdata);
    setRoomName(props.roomdata.data.room_name);
    setCapacity(props.roomdata.data.capacity);
    setPrice(props.roomdata.data.price_per_hour);
    setDescription(props.roomdata.data.description);
    let data = props.roomdata.equipments.map((n) => n.name);
    setEquipments3(data);
    equandamen(data);
    setChecked(props.roomdata.equipments);
    // console.log(props.roomdata.equipments.map((n) => n.name));
    setAmenities3(props.roomdata.amenities.map((n) => n.name));
    setChecked1(props.roomdata.amenities);

    setAddOns(props.roomdata.addons);
    // console.log("adddddddd", props.roomdata.addons);
    if (addOns.length !== 0) {
      setAddOnName(props.roomdata.addons[0].name);
      //console.log("nameemem", props.roomdata.addons[0].name);
      setAddOnPrice(props.roomdata.addons[0].price);
      setAddOnDescription(props.roomdata.addons[0].description);
      setAddOnImage(props.roomdata.addons[0].image);
      // console.log(props.roomdata.addons[0].image);
    }
    setImages(props.roomdata.images);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4">
                <div className="eyegroup">
                  <label
                    htmlFor=""
                    className="form-label customlabelloginpagesetting"
                  >
                    Room Name
                  </label>
                  <input
                    type="text"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="stubiestudio"
                    name="room_name"
                    value={room_name}
                    onChange={(e) => {
                      setRoomName(e.target.value);
                      setError("");
                    }}
                  />
                  <div>
                    <span className="customvalidationdesignlogin">
                      {error.room_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="eyegroup">
                  <label
                    htmlFor=""
                    className="form-label customlabelloginpagesetting"
                  >
                    Total Guest
                  </label>
                  <input
                    type="number"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="Total Guest"
                    name="capacity"
                    value={capacity}
                    onChange={(e) => {
                      setCapacity(e.target.value);
                      setError("");
                    }}
                  />
                  <div>
                    <span className="customvalidationdesignlogin">
                      {error.capacity}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="eyegroup">
                  <label
                    htmlFor=""
                    className="form-label customlabelloginpagesetting"
                  >
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="price"
                    name="price_per_hour"
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setError("");
                    }}
                    value={price_per_hour}
                  />
                  <div>
                    <span className="customvalidationdesignlogin">
                      {error.price_per_hour}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="eyegroup">
                  <label
                    htmlFor=""
                    className="form-label customlabelloginpagesetting"
                  >
                    Description
                  </label>
                  <div className="form-floating">
                    <textarea
                      className="form-control custom-textarea"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      style={{ height: "100px" }}
                      name="description"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setError("");
                      }}
                    ></textarea>
                    <label htmlFor="floatingTextarea2">Description</label>
                    <div>
                      <span className="customvalidationdesignlogin">
                        {error.description}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="eyegroup">
                  <label
                    htmlFor=""
                    className="form-label customlabelloginpagesetting"
                  >
                    Equipment Provided
                  </label>
                  {equipments1 !== undefined ? (
                    <>
                      <div className="form-checkcontainer f-l  ">
                        {equipments1.map((item, key) => (
                          <>
                            <div key={key} className="form-check me-xl-3">
                              {equipments3.includes(item.name) === true ? (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  onChange={(e) => handleChecke(e)}
                                  id={`daw-${item.id}`}
                                  defaultChecked={item.name}
                                />
                              ) : (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  onChange={(e) => handleChecke(e)}
                                  id={`daw-${item.id}`}
                                />
                              )}
                              <label
                                className="form-check-label"
                                htmlFor={`daw-${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}

                  <span className="customvalidationdesignlogin">
                    {error.equipments2}
                  </span>
                </div>
              </div>
              <div>
                <button
                  className="btnaddservice-room mt-3"
                  onClick={() => {
                    setOtherequip(!otherquip);
                    // setAddOnName("");
                    // setAddOnPrice("");
                    // setAddOnDescription("");
                    // setAddImg("");
                  }}
                >
                  Other Equipment
                </button>
              </div>
              {otherquip && (
                <div className="col-md-12">
                  <div className="eyegroup">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Use (,) to add multiple equipment
                    </label>
                    <div className="form-floating">
                      <textarea
                        className="form-control custom-textarea"
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                        placeholder="Enter other equipments"
                        name="otherequipments"
                        value={otherequipments}
                        onChange={(e) => {
                          setOtherEquipments(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12 mt-3">
                <div className="eyegroup">
                  <label
                    htmlFor=""
                    className="form-label customlabelloginpagesetting"
                  >
                    Amenities
                  </label>
                  {amenities1 !== undefined ? (
                    <>
                      <div className="form-checkcontainer f-l  ">
                        {amenities1.map((item, key) => (
                          <>
                            <div key={key} className="form-check me-xl-3">
                              {amenities3.includes(item.name) === true ? (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  id={`smoke-${item.id}`}
                                  onChange={(e) => handleChecke1(e)}
                                  defaultChecked={item.name}
                                />
                              ) : (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  id={`smoke-${item.id}`}
                                  onChange={(e) => handleChecke1(e)}
                                />
                              )}
                              <label
                                className="form-check-label"
                                htmlFor={`smoke-${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}

                  {otheramen && (
                    <div className="col-md-12">
                      <div className="eyegroup">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Use (,) to add multiple Amenities
                        </label>
                        <div className="form-floating">
                          <textarea
                            className="form-control custom-textarea"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            placeholder="Enter other amenities"
                            name="otheramenities"
                            value={otheramenities}
                            onChange={(e) => {
                              setOtherAmenities(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}
                  <span className="customvalidationdesignlogin">
                    {error.amenities2}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  className="btnaddservice-room mt-3"
                  onClick={() => {
                    setOtheramen(!otheramen);
                    // setAddOnName("");
                    // setAddOnPrice("");
                    // setAddOnDescription("");
                    // setAddImg("");
                  }}
                >
                  Other Amenities
                </button>
              </div>
              <div>
                <button
                  className="btnaddservice-room mt-3"
                  onClick={() => setAddService(!addservice)}
                >
                  Add Services
                </button>
              </div>
              {addservice && (
                <>
                  <div className="col-md-12">
                    <h5 className="addsertitle">Add Services</h5>
                  </div>

                  <div className="col-md-4">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="Enter Title"
                      name="addOnName1"
                      value={addOnName1}
                      onChange={(e) => {
                        setAddOnName1(e.target.value);
                        setError("");
                      }}
                    />

                    <span className="customvalidationdesignlogin">
                      {error.addOnName1}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Price
                    </label>
                    <input
                      type="number"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="Enter Price"
                      name="addOnPrice1"
                      value={addOnPrice1}
                      onChange={(e) => {
                        setAddOnPrice1(e.target.value);
                        setError("");
                      }}
                    />

                    <span className="customvalidationdesignlogin">
                      {error.addOnPrice1}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Upload Service Image(Optional)
                    </label>
                    <input
                      type="file"
                      className="form-control custominputtypeloginpagesetting"
                      onChange={handleRoomAdd}
                      //value={addOnImage}
                      name="addOnImage1"
                    />
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Description
                    </label>
                    <div className="form-floating">
                      <textarea
                        className="form-control custom-textarea"
                        placeholder="Leave a comment here"
                        id="floatingTextarea44"
                        style={{ height: "100px" }}
                        name="addOnDescription1"
                        value={addOnDescription1}
                        onChange={(e) => {
                          setAddOnDescription1(e.target.value);
                          setError("");
                        }}
                      ></textarea>
                      <label htmlFor="floatingTextarea44">Description</label>
                    </div>

                    <span className="customvalidationdesignlogin">
                      {error.addOnDescription1}
                    </span>

                    <div>
                      <button
                        onClick={() => {
                          AddServices();
                          setAddService(false);
                        }}
                        type="button"
                        className="btn btn-primary roomaddbuttonsave"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </>
              )}
              {status && (
                <>
                  <div className="col-md-12">
                    <h5 className="addsertitle">Edit Services</h5>
                  </div>

                  <div className="col-md-4">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="Enter Title"
                      name="addOnName"
                      value={addOnName}
                      onChange={(e) => {
                        setAddOnName(e.target.value);
                        setError("");
                      }}
                    />

                    <span className="customvalidationdesignlogin">
                      {error.addOnName}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Price
                    </label>
                    <input
                      type="number"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="Enter Price"
                      name="addOnPrice"
                      value={addOnPrice}
                      onChange={(e) => {
                        setAddOnPrice(e.target.value);
                        setError("");
                      }}
                    />

                    <span className="customvalidationdesignlogin">
                      {error.addOnPrice}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Upload Service Image(Optional)
                    </label>
                    <input
                      type="file"
                      className="form-control custominputtypeloginpagesetting"
                      onChange={handleRoomEdit}
                      // value={addOnImage}
                      name="addOnImage"
                    />
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Description
                    </label>
                    <div className="form-floating">
                      <textarea
                        className="form-control custom-textarea"
                        placeholder="Leave a comment here"
                        id="floatingTextarea44"
                        style={{ height: "100px" }}
                        name="addOnDescription"
                        value={addOnDescription}
                        onChange={(e) => {
                          setAddOnDescription(e.target.value);
                          setError("");
                        }}
                      ></textarea>
                    </div>

                    <span className="customvalidationdesignlogin">
                      {error.addOnDescription}
                    </span>

                    <div>
                      <button
                        onClick={() => {
                          setError(EditAddOnValidations());
                          setStatus(false);
                        }}
                        type="button"
                        className="btn btn-primary roomaddbuttonsave"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </>
              )}
              {addOns.length !== 0 ? (
                <>
                  {addOns.map((item) => (
                    <>
                      <div className="row">
                        <div className="col-md-6 addaddonrowedit">
                          <div className="media roomsmedia d-flex addroomdox">
                            <div className="rooms-media-img addon-img">
                              {item.image ? (
                                <>
                                  <img
                                    onClick={() => setStatus(true)}
                                    className="mr-3"
                                    src={
                                      `https://stubies.s3.us-east-2.amazonaws.com/` +
                                      item.image
                                    }
                                    //src="images/roomsimg.jpg"
                                    alt="Room Image"
                                  />
                                </>
                              ) : null}
                            </div>
                            <div className="media-body">
                              <h5 className="mt-0">{item.name}</h5>
                              <p>{item.description}</p>
                              <h6>
                                <img
                                  className="pricetagim"
                                  src="images/pricetag.png"
                                  alt=""
                                />
                                ${item.price}
                              </h6>
                            </div>
                            <div className="d-flex editremovebox">
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                                onClick={() => {
                                  setAddOnName(item.name);
                                  setOffLineId(item.offlineId);
                                  setAddOnId(item.id);
                                  setAddOnPrice(item.price);
                                  setAddOnDescription(item.description);
                                  if (item.image) {
                                    setAddOnImage(item.image);
                                  }

                                  setStatus(true);
                                }}
                              ></i>
                              <img
                                onClick={() => {
                                  DeleteImage1(item);
                                  setAddOnId("");
                                  setAddOnPrice("");
                                  setAddOnDescription("");
                                  setAddOnImage("");
                                  setStatus(false);
                                }}
                                className="deleterooms"
                                src="images/deleteiconimg.png"
                                alt="Room Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : null}

              <div className="col-md-4 mt-3">
                <label
                  htmlFor=""
                  className="form-label customlabelloginpagesetting"
                >
                  Upload Room Images
                </label>
                <input
                  type="file"
                  className="form-control custominputtypeloginpagesetting"
                  onChange={(e) => handleRoom(e)}
                  name="url"
                  multiple
                />
              </div>
              {loading ? (
                <>
                  <div className="col-md-6">
                    <p>Loading....</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-12">
                    <div className="popaddroomimgcontainer">
                      {images.length !== 0 ? (
                        <>
                          {images.map((item) => {
                            return (
                              <>
                                <div className="popaddroomimg">
                                  <img
                                    className="mr-3"
                                    src={
                                      `https://stubies.s3.us-east-2.amazonaws.com/` +
                                      item.url
                                    }
                                    //src="images/roomsimg.jpg"
                                    alt="Room Image"
                                  />
                                  <span onClick={() => DeleteImage(item)}>
                                    X
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => roomEdit()}
              className="btn btn-primary savechangesbtnmodlroom"
            >
              Save changes
            </button>
          </div>
        </>
      )}
    </>
  );
}
export default EditRoom;
