import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/css";

import { Autoplay, Pagination, Navigation } from "swiper";

// import required modules
// import { Navigation } from "swiper";
import bootstrap from "bootstrap";
import { otpApi } from "../Apis";
function OTP() {
  const [values, setValue] = useState({
    code: "",
    emailid: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const myData = (e) => {
    e.preventDefault();
    //CallApi();
    // setIsLoading(true);
    setError(validate(values));
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  const navigate = useNavigate();
  let location = useLocation();
  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setValue({ ...values, [name]: value });
    setError({ ...error, [name]: "" });
  };

  //form validations
  const validate = (values) => {
    let error = {};

    if (!values.code) {
      error.code = "Code is required.";
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      CallApi();
    }
    return error;
  };

  const CallApi = () => {
    setIsLoading(true);
    let email = location.state.email;
    //console.log("hjk",email)
    var data = JSON.stringify({
      emailid: email,
      code: values.code,
    });

    var config = {
      method: "post",
      url: otpApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          navigate("/updatepassword", {
            state: { data: response.data.data },
          });
          setMessage(response.data.message);
          //alert(response.data.message);
        } else {
          setMessage(response.data.message);
          //alert(response.data.message);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}

      <div className="loginmainsection">
        <div className="container-fluid">
          <div className="row flex-direction-row g-0">
            <div className="col-md-6">
              <div className="loginleftsection custommargintopforgot">
                <div className="mainloginlogobox">
                  <Link to="/">
                    <img src="images/mainlogo.png" />
                  </Link>
                </div>
                <h2>Verification Code</h2>
                <h6>
                  Confirm your account by entering the OTP code sent by email.
                </h6>

                <form onSubmit={myData}>
                  <div className="row">
                    {message && (
                      <span className="customvalidationdesignlogin">
                        {message}
                      </span>
                    )}
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="0 0 0 0 0 0"
                        name="code"
                        value={values.code}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />
                      {error.code && (
                        <span className="customvalidationdesignlogin">
                          {error.code}
                        </span>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="mainloginbutton">
                        <div className="loginbuttonlink">
                          <button type="submit">SUBMIT</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="loginrighttsection">
                <div className="loginrightinnertextsectionupper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2800,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                    //navigation
                    //pagination={{ clickable: true }}
                    //scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log("slide change")}
                  >
                    <SwiperSlide>
                      <div className="sliderwidthtext">
                        <h1>
                          Booking your next <span>session</span> <br />
                          is now at your fingertips
                        </h1>
                        <p>
                          Unlock your creative potential within extraordinary
                          studio spaces.Discover - Reserve - Create for all
                          creatives such as  musicians, photographers, dancers,
                          filmmaker more.  Elevate your artistry with
                          state-of-the-art studios for musicians, photographers,
                          dancers, and filmmakers.
                        </p>
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OTP;
