import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  CusReplyApi,
  CusRoomListApi,
  CusStudioDetailApi,
  CustomerMediaListApi,
  CustomerReviewApi,
  GetStudioListApi,
} from "../Apis";
import Footer from "./Footer";
import Header from "./Header";
import moment from "moment";
import Swal from "sweetalert2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
// Import Swiper styles
import "swiper/css";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";

function CusStudioDetail(props) {
  const [studioDetail, setStudioDetail] = useState([]);
  const [studioDetail1, setStudioDetail1] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [bdata, setbdata] = useState();
  const [mediaList, setMediaList] = useState([]);
  const [operationHours, setOperationHours] = useState([]);
  const [review, setReview] = useState([]);
  const [replylist, setReplyList] = useState([]);
  const [reply, setReply] = useState(false);
  const [reviewid, setReviewId] = useState("");
  const [msgText, setMsgText] = useState("");
  const [StudioList, setStudioList] = useState([]);
  const [studioid, setStudioId] = useState("");
  const [videoThumb, setVideoThumb] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [images, setImages] = useState([]);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [viewData, setViewData] = useState([]);
  const [blackoutDays, setBlackoutDays] = useState([]);
  let location = useLocation();
  const refs = useRef({
    video: null,
  });
  const messageEl = useRef(null);

  const StudioDetail = (studio) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      id: studio,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CusStudioDetailApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("detailll", result);
        setStudioDetail(result.data);
        setOperationHours(result.productionHours);
        setBlackoutDays(result.blackoutdays);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Roomlist = (studio) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      id: studio,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CusRoomListApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setRoomList(result.data);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const MediaList = (studio) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      id: studio,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerMediaListApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setMediaList(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const ReviewList = (studio) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      studio_id: studio,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerReviewApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setReview(result.data);
        setReplyList(review.replylist);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const ReplyApi = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      reply: msgText,
      review_id: String(reviewid),
      time: moment().format("LT"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CusReplyApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          Swal.fire(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const sendMsg = () => {
    ReplyApi();
  };

  const GetStudioList = () => {
    let local = localStorage.getItem("userid");
    let UserId = location?.state?.userId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: UserId,
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetStudioListApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setStudioList(result.data);

        // StudioDetail(result.data[0]);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handle = (name) => {
    let selected = StudioList.find((n) => n.name === name);

    StudioDetail(selected.studio_id);

    Roomlist(selected.studio_id);
    MediaList(selected.studio_id);
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      sendMsg();
      setMsgText("");
      setReply(false);
    }
  };

  useEffect(() => {
    let studio = location?.state?.studioid;
    let h = location?.state?.studioDetails;
    let l = location?.state?.lat;
    let data = location?.state?.data;
    setbdata(data);
    setLat(l);
    let lg = location?.state?.lng;
    setLng(lg);
    // setStudioDetail1(h)
    StudioDetail(studio);
    Roomlist(studio);
    MediaList(studio);
    ReviewList(studio);
    GetStudioList();
    setViewData(location?.state?.viewData);
    // console.log("viddd", location?.state?.viewData);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {studioDetail.length !== 0 ? (
        <>
          <div className="studioprofilebanner">
            <Link
              className="backlink"
              to="/customer"
              state={{ lng: lng, lat: lat, data: bdata }}
            >
              <img src="images/backbtn1.png" />
            </Link>
            <img
              src={
                `https://stubies.s3.us-east-2.amazonaws.com/` +
                studioDetail.profile_pic
              }
            />
          </div>
          <div className="studioprofileinfo">
            <div className="container">
              <div className="row">
                <div className="col-md-2 ">
                  <div className="studioprofileinfoimg">
                    <img
                      src={
                        `https://stubies.s3.us-east-2.amazonaws.com/` +
                        studioDetail.profile_pic
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="studioprofileinfoimgingo">
                    <h4>{studioDetail.studio_name}</h4>
                    <div className="reviewstarstudio d-flex">
                      <div className="inforeviestar">
                        {Array(5)
                          .fill(0)
                          .map((_, idx) => (
                            <label key={idx}>
                              <img
                                src={
                                  idx < studioDetail.rating
                                    ? "images/fillstar.png"
                                    : "images/blankstar.png"
                                }
                                key={idx}
                              />
                            </label>
                          ))}
                      </div>
                      <button
                        className="btn readreviewbtn"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#reviewsmodal"
                      >
                        <img src="images/readrevieimg.png" /> Read Reviews
                      </button>
                    </div>
                  </div>
                  <p className="studiodescription">
                    {studioDetail.description}
                  </p>
                </div>
                <div className="col-md-4 ">
                  <div className="profilebtnright">
                    <div className="stubielinksbox customstubieprofilemessagebtnbox">
                      <ul>
                        {studioDetail.studio_id !== undefined ? (
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle customselectstubielink"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {studioDetail.studio_name}
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              {StudioList !== undefined ? (
                                <>
                                  {StudioList.map((item) => (
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        onClick={() => handle(item.name)}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </>
                              ) : null}
                            </ul>
                          </li>
                        ) : null}
                        <li>
                          <Link
                            to={"/customer"}
                            state={{
                              pending: 3,
                              studio: studioDetail.userid,
                              studioName: studioDetail.studio_owner,
                              studioImage:
                                studioDetail.profile_pic !== ""
                                  ? "https://stubies.s3.us-east-2.amazonaws.com/" +
                                    studioDetail.profile_pic
                                  : "https://stubies.s3.us-east-2.amazonaws.com/b731c60c-c7de-46cd-87fc-a51e3baf3afd/StudioPic/1683117465693",
                              // studioImage:
                              //   "https://stubies.s3.us-east-2.amazonaws.com/" +
                              //   studioDetail.profile_pic,
                            }}
                            className="customstubielinksforprofile"
                          >
                            <img src="images/stubiecustomerprofilemessageimg.png" />
                            Message
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="mystudiotabmainsection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mystudiotabinnersection">
                <ul
                  className="nav nav-tabs customstudiotabbar"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      className="nav-link   customtabbtnsetting active"
                      id="mystudios-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#mystudios"
                      type="button"
                      role="tab"
                      aria-controls="mystudios"
                      aria-selected="true"
                    >
                      Rooms
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      className="nav-link   customtabbtnsetting"
                      id="bankinformation-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bankinformation"
                      type="button"
                      role="tab"
                      aria-controls="bankinformation"
                      aria-selected="true"
                    >
                      Media
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      className="nav-link   customtabbtnsetting"
                      id="changepassword-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#changepassword"
                      type="button"
                      role="tab"
                      aria-controls="changepassword"
                      aria-selected="true"
                    >
                      Details
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="mystudios"
                    role="tabpanel"
                    aria-labelledby="mystudios-tab"
                  >
                    <div className="roomsmediamain">
                      <div className="container">
                        <div className="row">
                          {roomList.length !== 0 ? (
                            <>
                              {roomList.map((item, key) => (
                                <>
                                  <div className="col-md-6" key={key}>
                                    <div
                                      className="media roomsmedia d-flex"
                                      key={key}
                                    >
                                      <div className="rooms-media-img">
                                        <img
                                          className="mr-3"
                                          src={
                                            `https://stubies.s3.us-east-2.amazonaws.com/` +
                                            item.banner
                                          }
                                          alt="Room Image"
                                        />
                                      </div>
                                      <div className="media-body">
                                        <h5 className="mt-0">
                                          {item.room_name}
                                        </h5>
                                        <p>{item.description}</p>
                                        <div className="pricetagcustomerstuidoroom d-flex">
                                          <h6>
                                            <img
                                              className="pricetagim"
                                              src="images/pricetag.png"
                                              alt=""
                                            />
                                            ${item.price_per_hour}
                                          </h6>
                                          <div className="seedetailstuidoprofileroombox">
                                            <Link
                                              to={`/cusroomdetail`}
                                              className="pastbookinginnerseedetailbtn"
                                              state={{
                                                roomid: item.id,
                                                studioId: item.studio_id,
                                                studioDetail: studioDetail,
                                                blackoutDays: blackoutDays,
                                              }}
                                            >
                                              <img
                                                src="images/seestudioicon.png"
                                                className="pastbookingseedetailimg"
                                              />
                                              See Detail
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show "
                    id="bankinformation"
                    role="tabpanel"
                    aria-labelledby="bankinformation-tab"
                  >
                    <div className="container">
                      <div className="row">
                        {mediaList.length !== 0 ? (
                          <>
                            {mediaList.map((item, t) => (
                              <div className="col-md-4" key={t}>
                                <div className="glryimg" key={t}>
                                  <a
                                    key={t}
                                    data-bs-toggle="modal"
                                    data-bs-target="#editroommodal"
                                    href="#"
                                  >
                                    {item.url.includes("video") ? (
                                      <video
                                        key={t}
                                        className="glryimgimg"
                                        //poster={videoThumb}
                                        //id="video"
                                        //ref={(el) => (refs.current.video = el)}
                                        controls
                                        src={
                                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                                          item.url
                                        }
                                      >
                                        <source
                                          src={
                                            `https://stubies.s3.us-east-2.amazonaws.com/` +
                                            item.url
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        key={t}
                                        className="glryimgimg"
                                        src={
                                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                                          item.url
                                        }
                                        alt=""
                                      />
                                    )}
                                    {/* <img
                                      className="glryimgimg"
                                      src={
                                        `https://stubies.s3.us-east-2.amazonaws.com/` +
                                        item.url
                                      }
                                      alt=""
                                    /> */}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show "
                    id="changepassword"
                    role="tabpanel"
                    aria-labelledby="changepassword-tab"
                  >
                    <div className="studio-info-tab">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="title-h">
                              Operational Hours ({studioDetail.timezone})
                            </h3>
                          </div>
                          {operationHours.length !== 0 ? (
                            <>
                              {operationHours.map((item, d) => (
                                <div className="col-md-6" key={d}>
                                  <div className="studiotimeinfo d-flex">
                                    <h4 className="w-50">{item.day}</h4>
                                    {item.check === "1" ? (
                                      <>
                                        <p className="w-50 text-end">
                                          {moment(item.available_from, [
                                            "HH.mm",
                                          ]).format("hh:mm a")}{" "}
                                          TO{" "}
                                          {moment(item.available_to, [
                                            "HH.mm",
                                          ]).format("hh:mm a")}{" "}
                                          {studioDetail.timezone}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p className="w-50 text-end">Closed</p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}

                          <div className="col-md-12 staddrss">
                            <h3 className="title-h">Studio Address</h3>

                            {studioDetail.chat_status === "1" ? (
                              <p>
                                {studioDetail.street},{studioDetail.city},
                                {studioDetail.state}
                              </p>
                            ) : (
                              <p>{studioDetail.city}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div
        className="modal fade opacity-animate"
        id="reviewsmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reviews</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              {review.length !== undefined ? (
                <>
                  {review.map((n, key) => (
                    <div className="col-md-12" key={key}>
                      <div className="reviewcontainer" key={key}>
                        <div className="media reviewbox d-flex" key={key}>
                          <div className="reviewbox-user-img" key={key}>
                            <img
                              key={key}
                              className="mr-3"
                              src={
                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                n.customer_image
                              }
                              //src="images/review-user.jpg"
                              alt="Room Image"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="mt-0">{n.customer_name}</h5>
                            <div className="inforeviestar d-flex reviewboxstar">
                              {Array(5)
                                .fill(0)
                                .map((_, idx) => (
                                  <label key={idx}>
                                    <img
                                      src={
                                        idx < n.rating
                                          ? "images/fillstar.png"
                                          : "images/blankstar.png"
                                      }
                                      key={idx}
                                    />
                                  </label>
                                ))}
                            </div>
                            <p>{n.review}</p>
                            <h6
                              className="replybtn"
                              onClick={() => {
                                setReply(true);
                                setReviewId(n.id);
                              }}
                            >
                              Reply
                            </h6>
                          </div>
                          <h4 className="comtdate"> {n.time}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="modal-body">
                  <p>No Reviews</p>
                </div>
              )}
              {reply === true ? (
                <>
                  <div className="position-relative send-review-btn">
                    <input
                      type="text"
                      placeholder="Type Message"
                      className="form-control custominputtypeloginpagesetting customtextbarreview"
                      name="msgText"
                      value={msgText}
                      onKeyPress={msgText.length !== 0 ? onKeyPress : ""}
                      onChange={(e) => setMsgText(e.target.value)}
                    />
                    {msgText.length !== 0 ? (
                      <img
                        src="images/chatsendbtn.png"
                        className="customsendbtnsetting"
                        onClick={() => {
                          sendMsg();
                          setMsgText("");
                          setReply(false);
                        }}
                      />
                    ) : (
                      <img
                        src="images/chatsendbtn.png"
                        className="customsendbtnsetting"
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal opacity-animate fade"
        id="editroommodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-addrooms modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title" id="exampleModalLabel">
                Edit Room
              </h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body slider-room-popup">
              {" "}
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {mediaList.map((item) => (
                  <SwiperSlide>
                    <div className="sliderroomcenter roomimagepopupslider">
                      <div className="">
                        {item.url.includes("video") ? (
                          <video
                            className="glryimgimg"
                            //poster={videoThumb}
                            //id="video"
                            // ref={(el) => (refs.current.video = el)}
                            controls
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              item.url
                            }
                          >
                            <source
                              src={
                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                item.url
                              }
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            className="glryimgimg"
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              item.url
                            }
                            alt=""
                          />
                        )}
                        {/* <img
                          className="glryimgimg"
                          src={
                            `https://stubies.s3.us-east-2.amazonaws.com/` +
                            item.url
                          }
                          alt=""
                        /> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CusStudioDetail;
