import Persona from "persona";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader/Loader";

//import "./App.css";

function StudioPersona() {

 
  const [options, setOptions] = useState({
    templateId: "tmpl_JAZjHuAT738Q63BdgCuEJQre",
  });

  const [flowType, setFlowType] = useState("embedded");

  const embeddedClientRef = useRef(null);

 
  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: "sandbox",
      onLoad: (error) => {
        if (error) {
          // console.error(
          //   `Failed with code: ${error.code} and message ${error.message}`
          // );
        }
        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (inquiryId) => {
        //console.log(`Sending finished inquiry ${inquiryId} to backend`);
        fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  return (
    <>
      {/* {isLoading ? (
        <>
          <Loader/>
        </>
      ) : null} */}

      {/* <Persona.Inquiry
        templateId="itmpl_Ygs16MKTkA6obnF8C3Rb17dm"
        is-webview={true}
        environment="sandbox"
        onLoad={() => {
          console.log("Loaded inline");
        }}
        onComplete={({ inquiryId, status, fields }) => {
          // Inquiry completed. Optionally tell your server about it.
          console.log(`Sending finished inquiry ${inquiryId} to backend`);
        }}
      /> */}

      <div>
        <section>
          {/* <fieldset>
            <label htmlFor="flowType">Flow type:{" "}</label>
            <span value="embedded">Embedded</span>
            <p
            id="flowType"
            value={flowType}
            onChange={(e) => setFlowType(e.target.value)}
          >
            <p value="embedded">Embedded</p>
           
          </p>
          </fieldset> */}

          <fieldset>
            <label htmlFor="templateId">Template ID</label>
            <input
              id="templateId"
              value={options.templateId}
              onChange={(e) =>
                setOptions({ ...options, templateId: e.target.value })
              }
            />
          </fieldset>

          {/* <p>
            To pass your own template ID, add <code>csb.app</code>{" "}
            (CodeSandbox's domain) as an{" "}
            <a href="https://withpersona.com/dashboard/integration">
              Allowed Domain in your template configuration.
            </a>{" "}
            Make sure to remove it once done testing.
          </p> */}
        </section>

        {!options.templateId && <h2>Template ID is required</h2>}

        {flowType === "embedded" && !!options.templateId && (
          <section>
            <h2>Embedded client</h2>
            <button onClick={createClient}>Initialize client</button>
            <button
              onClick={() =>
                embeddedClientRef.current != null
                  ? embeddedClientRef.current.open()
                  : alert("Initialize client first")
              }
            >
              Reopen client
            </button>
            {/* <p>
              Close widget with <code>window.exit(force?: boolean)</code>.
            </p> */}
          </section>
        )}

        {flowType === "inline" && !!options.templateId && (
          <section>
            <h2>Inline client</h2>
            {/* <Persona.Inquiry
              {...options}
              environment="sandbox"
              onLoad={(error) => {
                if (error) {
                  console.error(
                    `Failed with code: ${error.code} and message ${error.message}`
                  );
                } else {
                  console.log("Loaded inline");
                }
              }}
              onStart={(inquiryId) => {
                console.log(`Started inline inquiry ${inquiryId}`);
              }}
              onComplete={(inquiryId) => {
                console.log(
                  `Sending finished inline inquiry ${inquiryId} to backend`
                );
                fetch(`/server-handler?inquiry-id=${inquiryId}`);
              }}
              onEvent={(name, meta) => {
                switch (name) {
                  case "start":
                    console.log(`Received inline event: start`);
                    break;
                  default:
                    console.log(
                      `Received inline event: ${name} with meta: ${JSON.stringify(
                        meta
                      )}`
                    );
                }
              }}
            /> */}
          </section>
        )}
      </div>
    </>
  );
}

export default StudioPersona;
