import React, { useEffect, useState } from "react";
import CusFAQ from "./CusFAQ";
import axios from "axios";
import {
  CreateStripeApi,
  CustomerAddressApi,
  CustomerDetailsApi,
  RoomBookingApi,
} from "../Apis";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import GetVerifiedCus from "./GetVerifiedCus";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import moment from "moment";
function CusSettings(props) {
  const [state, setState] = useState(0);
  const [cardno, setCardNo] = useState("");
  const [cardno1, setCardNo1] = useState("");
  const [name1, setName1] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [cvv, setCVV] = useState("");
  const [error, setError] = useState({});
  const [data, setData] = useState([]);
  const [CustomerId, setCustomerId] = useState("");
  const [Details, setDetails] = useState([]);
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [id, setID] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [state1, setState1] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [countryCode, setCountrycode] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const CustomerDetail = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      devicetype: "abc",
      devicetoken: "abc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (
          result.data.stripe_customer_id === "" ||
          result.data.stripe_customer_id === null
        ) {
          CreateStripeCustomer();
        } else {
          setCustomerId(result.data.stripe_customer_id);
          PaymentMethodApi1(result.data.stripe_customer_id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const CreateStripeCustomer = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CreateStripeApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        CustomerDetail();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const PaymentMethodApi1 = (id) => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `https://api.stripe.com/v1/payment_methods?customer=${id}&type=card`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
      },
    };

    axios(config)
      .then((result) => {
        //console.log("carddetails", result);

        // setData(result.data.data);
        // RoomBooking(id);
        // setIsLoading(false);
        let dd = result.data.data;
        let dd1 = [];
        if (dd !== dd1) {
          console.log(
            Object.values(
              result.data.data.reduce(
                (acc, cur) =>
                  Object.assign(acc, { [cur.card.fingerprint]: cur }),
                {}
              )
            )
          );
          setData(
            Object.values(
              result.data.data.reduce(
                (acc, cur) =>
                  Object.assign(acc, { [cur.card.fingerprint]: cur }),
                {}
              )
            )
          );
          //RoomBooking(id);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setData(result.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const DeleteCardApi = (id) => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.stripe.com/v1/payment_methods/${id}/detach`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        PaymentMethodApi1(CustomerId);
        // setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
    setIsLoading(false);
  };
  const toast = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete card!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(DeleteCardApi(id), "Your Card has been deleted.", "success");
      }
    });
  };
  const toast1 = (id) => {
    Swal.fire({
      title: "Payment",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Pay",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(RoomBooking(id), "success");
      }
    });
  };

  const PaymentTokenApi = () => {
    setIsLoading(true);
    const m = date.split("/");

    var qs = require("qs");
    var data = qs.stringify({
      "card[number]": cardno,
      "card[exp_month]": m[0],
      "card[exp_year]": m[1],
      "card[cvc]": cvv,
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((result) => {
        //console.log(JSON.stringify(result.data));

        PaymentMethodApi(result.data.id);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(error.response.data.error.message);
      });
    setIsLoading(false);
  };

  const PaymentMethodApi = () => {
    const m = date.split("/");
    var qs = require("qs");
    var data = qs.stringify({
      type: "card",
      "card[number]": cardno,
      "card[exp_month]": m[0],
      "card[exp_year]": m[1],
      "card[cvc]": parseFloat(cvv),
      "billing_details[name]": name,
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com/v1/payment_methods",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
      },
      data: data,
    };

    axios(config)
      .then((result) => {
        // console.log("PM" + JSON.stringify(result.data));

        setID(result.data.id);

        AttachPaymentApi(result.data.id);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire(error);
      });
  };

  const AttachPaymentApi = (id) => {
    setIsLoading(true);
    var qs = require("qs");
    var data = qs.stringify({
      customer: CustomerId,
    });

    var config = {
      method: "post",
      url: `https://api.stripe.com/v1/payment_methods/${id}/attach`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
      },
      data: data,
    };

    axios(config)
      .then((result) => {
        //console.log("Attach" + result.data);

        PaymentMethodApi1(CustomerId);
        addCusAddress();
      })
      .catch((error) => {
        alert(error);
        setIsLoading(false);
      });
  };
  const cardValidation = () => {
    let error = {};

    if (!cardno) {
      error = { ...error, cardno: "Enter card number" };
    }
    if (!name) {
      error = { ...error, name: "Enter Card Holder Name" };
    }
    if (!date) {
      error = { ...error, date: "Enter expired date" };
    }
    if (!cvv) {
      error = { ...error, cvv: "Enter cvv" };
    }
    if (!address) {
      error.address = "Address field is required.";
    }
    if (!street) {
      error.street = "Street field is required.";
    }
    if (!city) {
      error.city = "City field is required.";
    }
    if (!zipcode) {
      error.zipcode = "Zipcode field is required.";
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      PaymentTokenApi();
    }
    return error;
  };

  const Save = () => {
    setError(cardValidation());
  };
  const DeleteCard = (id) => {
    DeleteCardApi(id);
  };

  const onChange = (event) => {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );

    setDate(event.target.value);
  };

  const AddressValidation = () => {
    let error = {};
    if (!address) {
      error.address = "Address field is required.";
    }
    if (!street) {
      error.street = "Street field is required.";
    }
    if (!city) {
      error.city = "City field is required.";
    }
    if (!zipcode) {
      error.zipcode = "Zipcode field is required.";
    }
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      addCusAddress();
    }
    return error;
  };

  const addCusAddress = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      address1: address,
      address2: street,
      city: city,
      zipcode: zipcode,
      state: state1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerAddressApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          Swal.fire(result.message);
          setCardNo("");
          setCVV("");
          setName("");
          setDate("");
          setState1("");
          setCity("");
          setZipcode("");
          setAddress("");
          setStreet("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddAddress = () => {
    setError(AddressValidation());
  };

  const handleSelect = (address, name) => {
    //console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        //console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };
        setCity(addressObject.city);
        setState1(addressObject.state);
        setAddress(address);
        setZipcode(addressObject.zipcode);
        setStreet(addressObject.street);
        setCountrycode(addressObject.cn);
        getLatLng(results[0]).then((latLng) => {
          setLat(latLng.lat);
          setLng(latLng.lng);
          //console.log("Success", latLng);
        });
      })

      .catch((error) => console.error("Error", error));
  };
  const handleChange1 = (address) => {
    // console.log(address);
    setAddress(address);
    setError("");
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const RoomBooking = (id, id2) => {
    setIsLoading(true);
    //console.log("gdhs", props.addON);
    let local = localStorage.getItem("userid");
    //console.log("customer", local);
    let AId = props.addON.map((n) => n.id);
    //console.log("fffg", AId);
    let AId1 = AId.toString();
    let d = moment(props.date1).format("DD/MM/YYYY");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      addons: AId1 ? AId1 : "",
      customer_id: local,
      customer_note: "",
      date: d,
      end_time: String(props.End),
      no_of_hours: String(props.Hours),
      pageno: 0,
      payment_method: id,
      room_id: props.roomData.id,
      room_price: String(props.roomData.price_per_hour),
      service_fee: String(props.servicefee),
      start_time: String(props.Start),
      studio_id: props.roomData.studio_id,
      tax: String(props.tax),
      total_price: String(props.totalPrice),
      payment_status: "true",
      payment_type: "card",
    });
    //console.log("total ADDDDONN", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(RoomBookingApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          Swal.fire("Room booked successfully!");
          setIsLoading(false);
          //props.fun({ pending: 2, name: props.roomData.id });
          props.fun(
            navigate("/customer", {
              state: { pending: 2, name: props.roomData.id },
            }),

            window.location.reload()
          );
        } else {
          Swal.fire(result.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        setIsLoading(false);
      });
  };
  const pay = (id) => {
    RoomBooking(id);
  };
  useEffect(() => {
    // console.log("ghghgh", props.date1);
    CustomerDetail();
    //window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="mainsettingsectiontop">
        <div className="container">
          <div className="row">
            <div className="col-md-3 customwidthsetting">
              <div className="settinglinksbuttonleftbox">
                <ul className="settinglinksulbtnsetting">
                  {state === 0 ? (
                    <>
                      <li
                        className="settinglinkslibtnsetting active"
                        onClick={() => {
                          setState(0);
                          handleScrollToTop();
                        }}
                      >
                        {" "}
                        Payment Option
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="settinglinkslibtnsetting"
                        onClick={() => {
                          setState(0);
                          handleScrollToTop();
                        }}
                      >
                        {" "}
                        Payment Option
                      </li>
                    </>
                  )}
                  {state === 1 ? (
                    <>
                      <li
                        className="settinglinkslibtnsetting active"
                        onClick={() => {
                          setState(1);
                          handleScrollToTop();
                        }}
                      >
                        {" "}
                        FAQ
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="settinglinkslibtnsetting"
                        onClick={() => {
                          setState(1);
                          handleScrollToTop();
                        }}
                      >
                        {" "}
                        FAQ
                      </li>
                    </>
                  )}
                  {/* {state === 2 ? (
                    <>
                      <li
                        className="settinglinkslibtnsetting active"
                        onClick={() => {
                          setState(2);
                          handleScrollToTop();
                        }}
                      >
                        {" "}
                        Start Verification Process
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="settinglinkslibtnsetting"
                        onClick={() => {
                          setState(2);
                          handleScrollToTop();
                        }}
                      >
                        {" "}
                        Start Verification Process
                      </li>
                    </>
                  )} */}
                </ul>
              </div>
            </div>
            {state === 0 ? (
              <>
                <div className="col-md-9 customwidthsetting">
                  <div className="settingmainlinksbuttondetailboxright">
                    <div className="row">
                      <div className="col-md-12">
                        <h4 className="settingeditstudioprofileheading  custompastbookingheading stubiecustomerpaymentoption">
                          Payment Option
                        </h4>
                      </div>
                      {data.map((item) => (
                        <>
                          <div className="col-md-1">
                            <div className="paymentcardbox paymentcustomcardsetting">
                              {item.card.brand === "visa" ? (
                                <img src={"images/paymentvisacard.png"} />
                              ) : (
                                <img src={"images/paymentmastercard.png"} />
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="paymentcardnumber paymentcustomcardsetting">
                              <h5>**** **** **** {item.card.last4}</h5>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="paymentcardname paymentcustomcardsetting">
                              <h6>{item.billing_details.name}</h6>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="paymentcardexpirenumber paymentcustomcardsetting">
                              <h4>
                                {item.card.exp_month}/{item.card.exp_year}
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="paymentselectradiobtn paymentcustomcardsetting">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  defaultValue={false}
                                  onClick={() => {
                                    if (props.roomData.length === 0) {
                                      Swal.fire(
                                        "Please Select Room For Booking"
                                      );
                                    } else {
                                      toast1(item.id);
                                      //console.log("iteeee", item.id);
                                    }
                                  }}
                                />
                                <span
                                  className="delete-btn"
                                  onClick={() => toast(item.id)}
                                >
                                  X
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}

                      <div className="col-md-12">
                        <h4 className="settingeditstudioprofileheading  custompastbookingheading stubiecustomerpaymentoption">
                          Add card
                        </h4>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            Card Number
                          </label>
                          <input
                            type="text"
                            name="cardno"
                            maxlength="19"
                            value={cardno}
                            onChange={(e) => {
                              let formattedText = e.target.value;
                              if (formattedText.length > 0) {
                                formattedText = formattedText
                                  .replace(/\W/gi, "")
                                  .replace(/(.{4})/g, "$1 ");
                              }
                              setCardNo(formattedText);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder=" Card Number "
                          />
                          {error.cardno && (
                            <span className="customvalidationdesignlogin">
                              {error.cardno}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            Card Holder Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder=" Card Holder Name"
                          />
                          {error.name && (
                            <span className="customvalidationdesignlogin">
                              {error.name}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            Expiration Date
                          </label>
                          <input
                            type="text"
                            name="date"
                            value={date}
                            onChange={(e) => {
                              onChange(e);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder="Expiration Date"
                          />
                          {error.date && (
                            <span className="customvalidationdesignlogin">
                              {error.date}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            CVV
                          </label>
                          <input
                            type="text"
                            maxlength="4"
                            value={cvv}
                            onChange={(e) => {
                              setCVV(e.target.value);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder="Enter CVV"
                          />
                          {error.cvv && (
                            <span className="customvalidationdesignlogin">
                              {error.cvv}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <h4 className="settingeditstudioprofileheading  custompastbookingheading stubiecustomerpaymentoption">
                          Billing Detail
                        </h4>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            Address Line 1
                          </label>
                          <PlacesAutocomplete
                            value={address}
                            onChange={handleChange1}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  name="street"
                                  value={street}
                                  {...getInputProps({
                                    className:
                                      "form-control custominputtypeloginpagesetting",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {error.address && (
                            <span className="customvalidationdesignlogin">
                              {error.address}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            Address Line 2
                          </label>
                          <input
                            type="text"
                            name="street"
                            value={street}
                            onChange={(e) => {
                              setStreet(e.target.value);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder=""
                          />
                          {error.street && (
                            <span className="customvalidationdesignlogin">
                              {error.street}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder=""
                          />
                          {error.city && (
                            <span className="customvalidationdesignlogin">
                              {error.city}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label customlabelloginpagesetting"
                          >
                            Zipcode
                          </label>
                          <input
                            type="text"
                            name="zipcode"
                            value={zipcode}
                            onChange={(e) => {
                              setZipcode(e.target.value);
                              setError("");
                            }}
                            className="form-control custominputtypeloginpagesetting"
                            placeholder=""
                          />
                          {error.zipcode && (
                            <span className="customvalidationdesignlogin">
                              {error.zipcode}
                            </span>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div className="savecardpaymentbtnbottombox">
                            <h4 className="savecradmainheadingpaymentoption">
                              SaveCard
                            </h4>
                            <div className="form-check form-switch ">
                              <input
                                className="form-check-input "
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                defaultChecked
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="myprofileeditsavebtnbox">
                            <button
                              type="button"
                              onClick={() => {
                                Save();
                              }}
                              className="myprofileeditsavebtnsetting"
                            >
                              <img
                                src="images/savemyprofileedit.png"
                                className="myprofileeditsaveiconimg"
                              />
                              save
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-12">
                        <div className="myprofileeditsavebtnbox">
                          <button
                            type="button"
                            // onClick={() => {
                            //   PaymentMethodApi1();
                            // }}
                            className="myprofileeditsavebtnsetting"
                          >
                            <img
                              src="images/savemyprofileedit.png"
                              className="myprofileeditsaveiconimg"
                            />
                            Pay
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {state === 1 ? (
              <>
                <CusFAQ />
              </>
            ) : null}
            {/* {state === 2 ? (
              <>
                <GetVerifiedCus />
              </>
            ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
}
export default CusSettings;
