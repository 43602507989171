import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { AllBookingsApi, pastBookingApi } from "../Apis";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loader from "../Loader/Loader";

function PastBooking(props) {
  const [sdates, setSDates] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [pendDate, setPendDate] = useState([]);

  const handleChangeDtaes = (e, date) => {
    // console.log("ghgfgf", date, pendDate);
    setSDates(e);
    PastBooking(e);
    if (!pendDate.includes(date)) {
      setPendDate([...pendDate, date]);
    }
  };

  const PastBooking = (date) => {
    setLoading(true);
    let d = moment(date).format("DD/MM/YYYY");
    // console.log("dates", d);
    let local = localStorage.getItem("userid");

    var data = JSON.stringify({
      studio_id: props.id,
      userid: local,
      date: d,
    });

    var config = {
      method: "post",
      url: pastBookingApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setLoading(false);
        //setData(response.data.data);
        setViewData(response.data.data);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const AllBookings = (n) => {
    setLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      studio_id: props.id,
      pageno: 1,
      status: "3",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AllBookingsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        //console.log(result);
        if (result.error === "0") {
          if (n !== undefined) {
            let filData = result.data.filter((n, i) => i < 2);
            setViewData(filData);
            let fildate = result.data.map(
              (n) => new Date(moment(n.date, "DD/MM/YYYY").format("YYYY-MM-DD"))
            );
            let fildate1 = result.data.map((n) => n.date);
            // console.log("ddadadd", [...fildate]);
            // console.log("ddadadd44", [...fildate1]);
            setPendDate([...fildate]);
          } else {
            setViewData(result.data);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const View = () => {
    AllBookings();
  };

  useEffect(() => {
    setLoading(true);
    // PastBooking(new Date());
    AllBookings("filter");
    window.scrollTo(0, 0);
  }, [props.id]);
  return (
    <>
      <div className="mystudiotabmainsection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="myTabContent">
                <div className="col-md-12">
                  <div className="">
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="settingeditstudioprofileheading  custompastbookingheading">
                          Past Bookings
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <DatePicker
                          selected={null}
                          onChange={handleChangeDtaes}
                          inline
                          calendarClassName="date-picker-calendar"
                          highlightDates={pendDate}
                          multiDaySelect
                          maxDate={new Date()}
                        />
                        {/* <DatePicker
                          selected={sdates}
                          onChange={(e) => {
                            setSDates(e);
                            PastBooking(e);
                          }}
                          //minDate={new Date()}
                          maxDate={new Date()}
                        /> */}
                      </div>
                      <div className="text-right">
                        <button
                          className="savebtnsetting"
                          onClick={() => View()}
                        >
                          View All
                        </button>
                      </div>
                      {loading ? (
                        <>
                          <div className="col-md-6">
                            <p>Loading....</p>
                          </div>
                        </>
                      ) : (
                        <>
                          {viewData.length === 0 ? (
                            <div>
                              <p>No Past Bookings</p>
                            </div>
                          ) : (
                            <>
                              {viewData.map((n) => {
                                return (
                                  <div className="col-md-6">
                                    <div className="pastbookinginfobox">
                                      <h3 className="pastbookinginfotextheading">
                                        {n.customer_name}
                                      </h3>
                                      <h6 className="pastbookinginfotextstudioheading">
                                        {n.studio_name}
                                      </h6>
                                      <p className="pastbookinginfotextstudioabout">
                                        You have past booking for {n.room_name}
                                      </p>
                                      <div className="pastbookinginnertimebox">
                                        <h5 className="pastbookingtimeheadingtext">
                                          <img
                                            src="images/pastbookingstartimg.png"
                                            className="pastbookingstartendiconimg "
                                          />{" "}
                                          {moment(n?.start_time, [
                                            "HH.mm",
                                          ]).format("hh:mm a")}{" "}
                                          <span>
                                            {moment(
                                              n.date,
                                              "DD/MM/YYYY"
                                            ).format("MM-DD-YYYY")}
                                            {/* {n?.date} */}
                                          </span>
                                        </h5>
                                        <h5 className="pastbookingtimeheadingtext">
                                          <img
                                            src="images/pastbookingendimg.png"
                                            className="pastbookingstartendiconimg "
                                          />{" "}
                                          {moment(n?.end_time, [
                                            "HH.mm",
                                          ]).format("hh:mm a")}{" "}
                                          <span>
                                            {moment(
                                              n.date,
                                              "DD/MM/YYYY"
                                            ).format("MM-DD-YYYY")}
                                            {/* {n.date} */}
                                          </span>
                                        </h5>
                                        <Link
                                          to={`/bookingdetails`}
                                          state={{
                                            data: n,
                                            studioid: props.id,
                                            name: "pastbooking",
                                          }}
                                          className="pastbookinginnerseedetailbtn"
                                        >
                                          <img
                                            src="images/seestudioicon.png"
                                            className="pastbookingseedetailimg"
                                          />
                                          See Detail
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PastBooking;
