import React, { useEffect, useState, useRef } from "react";
import {
  CustomerDetailsApi,
  customerUploadImageApi,
  EditCustomerApi,
  EditCustomerImageApi,
} from "../Apis";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Modal } from "bootstrap";
import Loader from "../Loader/Loader";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import Persona from "persona";

function CustomerProfile(props) {
  const [cusDetails, setCusDetails] = useState([]);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [govtId, setGovtId] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [email, setEmail] = useState("");
  const [countryname, setCountry] = useState([]);
  const [error, setError] = useState({});
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image3, setImage3] = useState("");
  const [image2, setImage2] = useState("");
  const [imageChange1, setImageChange1] = useState(false);
  const [imageChange2, setImageChange2] = useState(false);
  const [imageChange3, setImageChange3] = useState(false);
  const [cropper, setCropper] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const [status, setStatus] = useState("");

  const [options, setOptions] = useState({
    templateId: "tmpl_JAZjHuAT738Q63BdgCuEJQre",
  });

  const [flowType, setFlowType] = useState("embedded");

  const embeddedClientRef = useRef(null);

  /**
   * Creates a new instance of the Persona client. Note that if this is called
   * when the popup is closed, an entirely new inquiry will be created (as
   * opposed to if you wre to call client.open()).
   */
  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: "sandbox",
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }
        client.open();
      },
      onStart: (inquiryId) => {
        console.log(JSON.stringify(inquiryId));
      },
      onComplete: (inquiryId) => {
        console.log(JSON.stringify(inquiryId));
        setInquiryId(inquiryId.inquiryId);
        setStatus(inquiryId.status);
        console.log("ghhhhh", inquiryId);
        console.log("tttttt", inquiryId.status);
        fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const customerValidation = () => {
    let error = {};
    if (!firstname) {
      error.firstname = "First Name is required.";
    }
    if (!lastname) {
      error.lastname = "Last Name is required.";
    }
    // if (!govtId) {
    //   error.govtId = "GovtId is required. ";
    // }
    if (!profilePic) {
      error.profilePic = "Profile pic is required.";
    }
    if (!phoneno) {
      error.phoneno = "Phone number is required.";
    }
    if (!email) {
      error.email = "Email is required.";
    }
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      var myModalEl = document.getElementById("editroommodal");
      var modal = Modal.getInstance(myModalEl);
      modal.hide();

      editCusProfile();
    }
    return error;
  };

  const CustomerDetail = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      devicetype: "abc",
      devicetoken: "abc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setCusDetails(result.data);
        setFirstName(result.data.firstname);
        setLastName(result.data.lastname);
        setPhoneNo(result.data.phoneno);
        setGovtId(result.data.government_id);
        setProfilePic(result.data.profile_pic);
        setEmail(result.data.email);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const editCusProfile = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customer_id: local,
      firstname: firstname,
      lastname: lastname,
      country_code: String(countryname.countryCode),
      phoneno: phoneno,
      government_id: govtId,
      persona_id: String(inquiryId),
      persona_status: status,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(EditCustomerApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          Swal.fire(result.message);
          //window.location.reload();
          CustomerDetail();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const editCustomerProfileImg = (newFile) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("customer_id", local);
    formdata.append("image", newFile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(EditCustomerImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setImage1("");

        setProfilePic(result.data.profile_pic);
        // console.log(
        //   "gdjgasd",
        //   "https://stubies.s3.us-east-2.amazonaws.com/" +
        //     result.data.profile_pic
        // );
        props.func(result.data.profile_pic);
        Swal.fire(result.message).then((result) => {
          if (result.isConfirmed) {
            CustomerDetail();
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleProfile = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      // console.log(reader.result);
      setImage1(reader.result);
      setImageChange1(true);
      //setFileType("ProfilePic");
    };

    reader.readAsDataURL(files[0]);
  };

  const handleGovt = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      // console.log(reader.result);
      setImage3(reader.result);
      setImageChange2(true);
      // setFileType("GovernmentID");
    };

    reader.readAsDataURL(files[0]);
  };
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      editCustomerProfileImg(newFile);
    }
  };

  const getCropData1 = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      EditGovtImage(newFile);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const EditGovtImage = (newFile) => {
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("file", newFile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(customerUploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        setImage3("");
        setGovtId(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const Save = () => {
    setError(customerValidation());
  };

  const downloadImage = () => {
    saveAs(`https://stubies.s3.us-east-2.amazonaws.com/` + govtId, "image.jpg");
  };

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setCountry(json);
      });
    CustomerDetail();
  }, []);

  return (
    <>
      <div className="stubiecustomerprofilemainsection">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="stubiecustomerprofileimageboxleft">
                <div className="myprofilestubieinnerimgboxleft">
                  <img
                    src={
                      `https://stubies.s3.us-east-2.amazonaws.com/` +
                      cusDetails.profile_pic
                    }
                  />
                </div>
                <h3>{cusDetails.firstname}</h3>
              </div>
            </div>
            <div className="col-md-8">
              <div className="stubiecustomerprofiletextboxright">
                <div className="row">
                  <div className="col-md-6">
                    <div className="myprofileinnertextbox">
                      <h4 className="myprofileemailleft">Email</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="myprofileinnertextboxright">
                      <h6>{cusDetails.email}</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="myprofileinnertextbox">
                      <h4 className="myprofileemailleft">Phone Number</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="myprofileinnertextboxright">
                      <h6>{cusDetails.phoneno}</h6>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="myprofiledownloadgovtidbox">
                      <div className="downloadgovermentidlinkbtn">
                        <a onClick={() => downloadImage()} href="#">
                          <img src="images/downloadidimg.png" /> Download
                          Goverment ID
                        </a>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-6 profileeditmainclass">
                    <div className="editroombocbtn">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#editroommodal"
                        href="#"
                        className="myprofilecustomerbtnright "
                      >
                        <img
                          src="images/editprofile.png"
                          className="myprofileeditbtn"
                        />
                        Edit Profile
                      </a>
                    </div>
                  </div>

                  <div
                    className="modal opacity-animate fade"
                    id="editroommodal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-addrooms">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Edit Profile
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div
                            className="tab-pane fade show active"
                            id="MyProfile"
                            role="tabpanel"
                            aria-labelledby="MyProfile-tab"
                          >
                            <div className="stubiecustomerprofilemainsection">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="stubiecustomerprofileimageboxleft  customeditprofilemainboxleft lft-profile-img">
                                      <div className="myprofilestubieinnerimgboxleft ">
                                        {profilePic !== "" ? (
                                          <>
                                            <img
                                              src={
                                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                                profilePic
                                              }
                                              // src="images/editprofilecustomer.png"
                                              className="editmyprofilecustomerleftimagesetting"
                                            />
                                          </>
                                        ) : (
                                          <img
                                            src="images/editprofilecustomer.png"
                                            className="editmyprofilecustomerleftimagesetting"
                                          />
                                        )}
                                        <div className="myprofilecustmoercamerauploadbox ">
                                          <input
                                            id="uploadimgprofile"
                                            type="file"
                                            name="profilePic"
                                            onChange={handleProfile}
                                            accept="image/*"
                                          />
                                          <label
                                            className="uplodiconimgleft"
                                            htmlFor="uploadimgprofile"
                                          >
                                            <img
                                              src="images/editprofilruploadccameraicon.png"
                                              className="myprofilecameraicon"
                                            />
                                          </label>
                                        </div>
                                      </div>
                                      <h3></h3>
                                    </div>
                                  </div>
                                  <div
                                    id="cropperModal"
                                    style={{
                                      display: image1 !== "" ? "block" : "none",
                                    }}
                                  >
                                    <div className="modal-content">
                                      <span
                                        onClick={() => setImage1("")}
                                        className="close"
                                      >
                                        &times;
                                      </span>
                                      {image1 !== "" ? (
                                        <Cropper
                                          style={{
                                            height: 400,
                                            width: "100%",
                                          }}
                                          zoomTo={0.5}
                                          initialAspectRatio={1}
                                          preview=".img-preview"
                                          src={image1}
                                          viewMode={1}
                                          minCropBoxHeight={10}
                                          minCropBoxWidth={10}
                                          background={false}
                                          responsive={true}
                                          autoCropArea={1}
                                          checkOrientation={false}
                                          onInitialized={(instance) => {
                                            setCropper(instance);
                                          }}
                                          guides={true}
                                        />
                                      ) : null}
                                      <button
                                        type="button"
                                        className="cropbtnbottom"
                                        style={{ float: "right" }}
                                        onClick={getCropData}
                                      >
                                        Upload Image
                                      </button>
                                    </div>
                                  </div>

                                  <div className="col-md-8">
                                    <div className="stubiecustomerprofiletextboxright mt-0">
                                      <div className="row">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label
                                              htmlFor="exampleFormControlInput1"
                                              className="form-label customlabelloginpagesetting"
                                            >
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              name="firstname"
                                              value={firstname}
                                              className="form-control custominputtypeloginpagesetting"
                                              placeholder="Christine Smith"
                                              onChange={(e) =>
                                                setFirstName(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              htmlFor="exampleFormControlInput1"
                                              className="form-label customlabelloginpagesetting"
                                            >
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              name="lastname"
                                              value={lastname}
                                              className="form-control custominputtypeloginpagesetting"
                                              placeholder=""
                                              onChange={(e) =>
                                                setLastName(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              htmlFor="exampleFormControlInput1"
                                              className="form-label customlabelloginpagesetting"
                                            >
                                              Email
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              value={email}
                                              className="form-control custominputtypeloginpagesetting"
                                              placeholder="Christine123@gmail.com"
                                              onChange={(e) =>
                                                setEmail(e.target.value)
                                              }
                                            />
                                          </div>

                                          {/* <div className="col-md-6">
                                            <label
                                              for="exampleFormControlInput1"
                                              className="form-label customlabelloginpagesetting"
                                            >
                                              Goverment ID
                                            </label>
                                            <input
                                              type="file"
                                              name="govtId"
                                              className="form-control custominputtypeloginpagesetting"
                                              //placeholder="Phone Number"
                                              onChange={handleGovt}
                                            />
                                          </div>
                                          <div>
                                            {govtId !== "" ? (
                                              <>
                                                <img
                                                  className="cropedimg"
                                                  alt="cropdimg"
                                                  src={
                                                    `https://stubies.s3.us-east-2.amazonaws.com/` +
                                                    govtId
                                                  }
                                                />
                                              </>
                                            ) : null}
                                          </div>
                                          <div
                                            id="cropperModal"
                                            style={{
                                              display:
                                                image3 !== ""
                                                  ? "block"
                                                  : "none" ,
                                            }}
                                          >
                                            <div className="modal-content">
                                              <span
                                                onClick={() => setImage3("")}
                                                className="close"
                                              >
                                                &times;
                                              </span>
                                              {image3 !== "" ? (
                                                <Cropper
                                                  style={{
                                                    height: 400,
                                                    width: "100%",
                                                  }}
                                                  zoomTo={0.5}
                                                  initialAspectRatio={1}
                                                  preview=".img-preview"
                                                  src={image3}
                                                  viewMode={1}
                                                  minCropBoxHeight={10}
                                                  minCropBoxWidth={10}
                                                  background={false}
                                                  responsive={true}
                                                  autoCropArea={1}
                                                  checkOrientation={false} 
                                                  onInitialized={(instance) => {
                                                    setCropper(instance);
                                                  }}
                                                  guides={true}
                                                />
                                              ) : null}
                                              <button
                                                type="button"
                                                className="cropbtnbottom"
                                                style={{ float: "right" }}
                                                onClick={getCropData1}
                                              >
                                                Upload Image
                                              </button>
                                            </div>
                                          </div> */}

                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-6">
                                                {flowType === "embedded" &&
                                                  !!options.templateId && (
                                                    <section className="text-start govtbtnsection">
                                                      <button
                                                        className="govtid mb-0"
                                                        onClick={createClient}
                                                      >
                                                        Upload Government ID
                                                      </button>
                                                      {/* <button
                  onClick={() => embeddedClientRef.current.open()}
                ></button> */}
                                                      {/* <p>
              Close widget with <code>window.exit(force?: boolean)</code>.
            </p> */}
                                                    </section>
                                                  )}
                                              </div>
                                              <div className="col-md-6">
                                                <div className="myprofileeditsavebtnbox">
                                                  <button
                                                    type="button"
                                                    onClick={() => Save()}
                                                    className="myprofileeditsavebtnsetting"
                                                  >
                                                    <img
                                                      src="images/savemyprofileedit.png"
                                                      className="myprofileeditsaveiconimg"
                                                    />
                                                    save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CustomerProfile;
