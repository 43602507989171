import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import moment from "moment";
import {
  aboutStudioApi,
  amenandequiApi,
  createroomApi,
  editAddOnApi,
  getProfileApi,
  reviewListApi,
  roomDeleteApi,
  roomListApi,
  studioDetailsApi,
  StudioReplyApi,
  UploadAddOnImagesApi,
  uploadRoomImagesApi,
} from "../Apis";
import { Modal } from "bootstrap";
import Loader from "../Loader/Loader";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import UploadMedia from "./UploadMedia";
import DatePicker from "react-multi-date-picker";
import EditStudioDetail from "./EditStudioDetail";

function StudioProfileRoom() {
  const [equipments, setEquipments] = useState([]);
  const [data, setData] = useState([]);
  const [roomimg, setRoomImg] = useState([]);
  const [addimg, setAddImg] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [file, setFile] = useState("");
  const [checked, setChecked] = useState([]);
  const [addonons, setAddOns] = useState([]);
  const [checked1, setChecked1] = useState([]);
  const [error, setError] = useState({});
  const [addonname, setAddOnName] = useState("");
  const [addonprice, setAddOnPrice] = useState("");
  const [addondescription, setAddOnDescription] = useState("");
  const [uploadimage, setUploadImage] = useState([]);
  const [visible, setVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [equipments1, setEquipments1] = useState("");
  const [equipmentserr, setEquipmentsErr] = useState("");
  const [amenities1, setAmenities1] = useState("");
  const [amenitieserr, setAmenitiesErr] = useState("");
  const [roomlist, setRoomList] = useState([]);
  const [addonnameerr, setAddOnNameErr] = useState(false);
  const [addonpriceerr, setAddOnPriceErr] = useState(false);
  const [addondescriptionerr, setAddOnDescriptionErr] = useState(false);
  const [addimgerr, setAddImgErr] = useState(false);
  const [imageerr, setImageErr] = useState(false);
  const [image, setImage] = useState("");
  const [filetype, setFileType] = useState("");
  const [cropper, setCropper] = useState();
  const [profiledata, setProfileData] = useState([]);
  const [otheramenities, setOtherAmenities] = useState("");
  const [otherequipments, setOtherEquipments] = useState("");
  const [otherquip, setOtherequip] = useState(false);
  const [otheramen, setOtheramen] = useState(false);
  const [reply, setReply] = useState(false);
  const [ratings, setRatings] = useState(5);
  const [reviewid, setReviewId] = useState("");
  const [formValues, setFormValues] = useState({
    room_name: "",
    equipments: "",
    capacity: "",
    price_per_hour: "",
    description: "",
    amenities: "",
    studio_id: "",
    userid: "",
  });
  const [aboutData, setAboutData] = useState("");
  const [workingHours, setWorkingHours] = useState([]);
  const [review, setReview] = useState([]);
  const [addservice, setAddService] = useState(false);
  const [descriptiondata, setDescriptionData] = useState("");
  const [loading, setLoading] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [BlackOut, setBlackout] = useState([]);
  const [calendarStatus, setCalendarStatus] = useState(false);
  const [msgText, setMsgText] = useState("");
  //const [isloading, setisloading] = useState(false);
  const [studioName, setStudioName] = useState("");
  const [studiotypes, setStudioTypes] = useState("");
  const [city1, setCity1] = useState("");
  const [state1, setState1] = useState("");
  const [street1, setStreet1] = useState("");
  const [zipcode1, setZipcode1] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [studioImage, setStudioImage] = useState("");
  const [address1, setAddress1] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [lat1, setLat1] = useState("");
  const [lng1, setLng1] = useState("");
  const [calendar, setCalendar] = useState([]);
  const [studioId, setStudioId] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [checkedState, setCheckedState] = useState(false);
  const [pending, setShowPending] = useState(0);
  const [checkedState1, setCheckedState1] = useState(false);
  const setLoading1 = (loading) => {
    setisloading(loading);
  };
  let location = useLocation();
  const validate = (formValues) => {
    const re = /^[0-9\b]+$/;
    let error = [];
    if (!formValues.room_name) {
      error.room_name = "Room Name is required.";
    }
    if (!formValues.capacity) {
      error.capacity = "Total Guests are required.";
    } else if (!re.test(formValues.capacity)) {
      error.capacity = "Only Numbers are required.";
    }
    if (!formValues.description) {
      error.description = "Description is required.";
    }
    if (!equipments1) {
      error.equipments1 = "Equipments is required.";
    }
    if (!amenities1) {
      error.amenities1 = "Amenities is required.";
    }
    if (!formValues.price_per_hour) {
      error.price_per_hour = "Price is required.";
    } else if (!re.test(formValues.price_per_hour)) {
      error.price_per_hour = "Only Numbers are required.";
    }

    if (!equipments1) {
      setEquipmentsErr(true);
    } else {
      setEquipmentsErr(false);
    }
    if (!amenities1) {
      setAmenitiesErr(true);
    } else {
      setAmenitiesErr(false);
    }

    if (roomimg.length === 0) {
      setImageErr(true);
      //error.roomimg = "Image is required.";
    } else {
      setImageErr(false);
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      if (roomimg.length !== 0) {
        var myModalEl = document.getElementById("add-studio");
        var modal = Modal.getInstance(myModalEl);
        modal.hide();

        addroomsApi();
      }
    }
    return error;
  };

  const addVelidation = () => {
    let error = {};
    if (!addonname) {
      setAddOnNameErr(true);
    } else {
      setAddOnNameErr(false);
    }
    if (!addonprice) {
      setAddOnPriceErr(true);
    } else {
      setAddOnPriceErr(false);
    }
    if (!addondescription) {
      setAddOnDescriptionErr(true);
    } else {
      setAddOnDescriptionErr(false);
    }
    // if (!addimg) {
    //   setAddImgErr(true);
    // } else {
    //   setAddImgErr(false);
    // }
    // console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      Add();
    }
    return error;
  };

  const AddOnsData = (e) => {
    setError(addVelidation());

    //e.preventDefault();
  };

  const Add = () => {
    let object = {
      addonimages: addimg.toString(),
      addonname: addonname,
      addonprice: addonprice,
      addondescription: addondescription,
    };
    let Array = [];
    if (
      (addonname !== "" && addimg.toString() !== "") ||
      (addimg.toString() === "" && addonprice !== "" && addondescription !== "")
    ) {
      Array.push(object);
      let addonArray = [...addonons, ...Array];
      setAddOns(addonArray);
      //console.log("addddd", addonArray);
      setAddService(!addservice);
    }
  };

  const addroomsApi = () => {
    setIsLoading(true);
    setVisible(false);
    let local = localStorage.getItem("userid");
    let studioId = location.state.studioid;
    //console.log("studioid", studioId);

    let equi = checked.map((item) => item.name);
    //alert(JSON.stringify(equi))
    let equi1 =
      otherequipments !== ""
        ? equi.toString() + "," + otherequipments
        : equi.toString();
    //console.log("equipments",equi1)
    let amen = checked1.map((item) => item.name);
    let amen1 = otheramenities
      ? amen.toString() + "," + otheramenities
      : amen.toString();
    // console.log("amenities", amen1);
    var data = JSON.stringify({
      addons: addonons,
      room_name: formValues.room_name,
      images: roomimg,
      equipments: equi1,
      capacity: formValues.capacity,
      price_per_hour: formValues.price_per_hour,
      description: formValues.description,
      amenities: amen1,
      studio_id: studioId,
      userid: local,
    });
    // console.log("createroom", data);
    var config = {
      method: "post",
      url: createroomApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          setIsLoading(false);
          // roomid(response.data.data);
          // console.log("roomid", response.data.data.id);
          //localStorage.setItem("roomid", response.data.data.id);
          setShowPending(0);
          RoomList();
          Swal.fire(response.data.message);
        } else {
          Swal.fire(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const ameandequi = () => {
    var config = {
      method: "get",
      url: amenandequiApi,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));

        setEquipments(response.data.equipments);
        setAmenities(response.data.amenities);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChecke = (e) => {
    setCheckedState(true);
    let filtered = [];
    if (e.target.checked) {
      //filtered = [...equipments, e.target.value];
      let filtered1 = equipments.filter((item) => item.name === e.target.value);
      filtered = [...checked, ...filtered1];
    } else {
      filtered.splice(equipments.indexOf(e.target.value), 1);
      //console.log(e.target.value);
    }

    setChecked(filtered);
    //console.log(filtered);
    setEquipments1(e.target.value);
    setEquipmentsErr("");
    //setCheckbox1(!checkbox1);
  };

  const handleChecke1 = (e) => {
    setCheckedState1(true);
    let filtereddata = [];
    if (e.target.checked) {
      //filtered = [...equipments, e.target.value];
      let filtered2 = amenities.filter((item) => item.name === e.target.value);
      filtereddata = [...checked1, ...filtered2];
    } else {
      filtereddata.splice(amenities.indexOf(e.target.value), 1);
      //console.log(e.target.value);
    }
    setChecked1(filtereddata);
    // console.log(filtereddata);
    setAmenities1(e.target.value);
    setAmenitiesErr("");
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const myData = (e) => {
    e.preventDefault();
    setError(validate(formValues));
  };

  const handleAddOnName = (e) => {
    setAddOnName(e.target.value);
    setAddOnNameErr("");
  };

  const handleAddOnPrice = (e) => {
    setAddOnPrice(e.target.value);
    setAddOnPriceErr("");
  };

  const handleAddOnDescription = (e) => {
    setAddOnDescription(e.target.value);
    setAddOnDescriptionErr("");
  };

  const studioDetail = () => {
    let studioId = location?.state?.studioid;
    //console.log("studioid", studioId);
    let local = localStorage.getItem("userid");
    var data = JSON.stringify({
      device_type: "I",
      device_token:
        "edsk5P9-aUebj5DQglpIXl:APA91bHXq3zJMCl796wRUBJ6dSHDyrY8Q6ta7KplQFZIJhUWMyQpNKL97X78KI9iCYJ98LK3zBfWIrQUXdRRu2hSWwemuefpDBoiYeKN6kHQTwz7mNjT7sUIayJs9XCyv8CHDOAvxFeS",
      userid: local,
      studio_id: studioId,
    });

    var config = {
      method: "post",
      url: studioDetailsApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        setData(response.data.data);
        setDescriptionData(response.data.data.description);

        setWorkingHours(response.data.productionHours);
        setAddress1(response.data.data.street);
        setZipcode1(response.data.data.zipcode);
        setCity1(response.data.data.city);
        setStudioName(response.data.data.studio_name);
        setPhoneNumber(response.data.data.phoneno);

        setState1(response.data.data.state);
        setStreet1(response.data.data.street);
        setStudioTypes(response.data.data.studio_type);
        setStudioImage(response.data.data.profile_pic);
        setStudioId(response.data.data.studio_id);
        setBlackout(response.data.blackoutdays);
        setTimeZone(response.data.data.timezone);
        if (response?.data?.productionHours.length !== 0) {
          setCalendar(response.data.productionHours);
        } else {
          let array = [
            {
              id: 5398,
              day: "Monday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:24.1620185",
            },
            {
              id: 5399,
              day: "Tuesday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:24.7160999",
            },
            {
              id: 5400,
              day: "Wednesday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:25.2704058",
            },
            {
              id: 5401,
              day: "Thursday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:25.8246444",
            },
            {
              id: 5402,
              day: "Friday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:26.3788448",
            },
            {
              id: 5403,
              day: "Saturday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:26.9329184",
            },
            {
              id: 5404,
              day: "Sunday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:27.487966",
            },
          ];
          setCalendar(array);
        }

        setLng1(response.data.data.lng);
        setLat1(response.data.data.lat);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRoom = async (e) => {
    let array = [];
    for (let i = 0; i <= e.target.files.length - 1; i++) {
      var file = e.target.files[i];
      let size = e.target.files[i].type.replace("/", ".");
      var blob = file.slice(0, file.size, e.target.files[i].type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: e.target.files[i].type });

      let data = await uploadRoomImages(newFile, i);
      array.push(data);
    }

    setRoomImg(array);
    //setError("");
    setImageErr("");
  };

  const DeleteImage = (e) => {
    const index = roomimg.findIndex((n) => n.id === e.id);
    let newPreview = roomimg;
    newPreview.splice(index, 1);
    setRoomImg([...newPreview]);
    // console.log("delete", newPreview);
  };

  const handleRoomAdd = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    uploadAddImages(newFile);
    setAddImgErr("");
  };

  const DeleteAddRoom = (e) => {
    const index = addonons.findIndex((n) => n.id === e.id);
    let deletedImage = addonons;
    deletedImage.splice(index, 1);
    setAddOns([...deletedImage]);
    //console.log("delete", deletedImage);
  };

  const uploadRoomImages = async (newFile, id) => {
    setLoading(true);
    var FormData = require("form-data");
    let studioId = location?.state?.studioid;
    //console.log("studioid", studioId);
    let local = localStorage.getItem("userid");
    var data = new FormData();
    data.append("userid", local);
    data.append("images", newFile);
    //data.append("folder", Folder);
    data.append("studio_id", studioId);

    const response = await axios.post(uploadRoomImagesApi, data);
    setLoading(false);
    // if (Folder === "Addons") {
    //   setAddImg(response.data.data);
    // } else if (Folder === "RoomImages") {
    //   let image = response.data.data;

    // }
    let image = response.data.data;

    let array = { id: id, url: image[0], status: "0 " };
    return array;
  };

  const uploadAddImages = async (newFile, id) => {
    setLoading(true);
    let studioId = location?.state?.studioid;
    let local = localStorage.getItem("userid");
    var FormData = require("form-data");

    var data = new FormData();
    data.append("userid", local);
    data.append("image", newFile);
    data.append("studio_id", studioId);
    const response = await axios.post(UploadAddOnImagesApi, data);
    setLoading(false);
    setAddImg(response.data.data);
  };

  const RoomList = () => {
    let studioId = location?.state?.studioid;
    let local = localStorage.getItem("userid");
    var data = JSON.stringify({
      userid: local,
      studio_id: studioId,
    });

    var config = {
      method: "post",
      url: roomListApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        setIsLoading(false);
        setRoomList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const toast = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete room!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(deleteRoom(id), "Your room has been deleted.", "success");
      }
    });
  };

  const deleteRoom = (id) => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    let studioId = location?.state?.studioid;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      studio_id: studioId,
      roomid: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(roomDeleteApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result)

        setIsLoading(false);
        RoomList();
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const getProfile = () => {
    let local = localStorage.getItem("userid");

    var data = JSON.stringify({
      userid: local,
    });

    var config = {
      method: "post",
      url: getProfileApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        setProfileData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const HandleAbout = (e) => {
    setDescriptionData(e.target.value);
  };
  const OnAboutStudio = () => {
    var myModalEl = document.getElementById("edit-profile");
    var modal = Modal.getInstance(myModalEl);
    modal.hide();
    AboutStudio();
  };

  const AboutStudio = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    let studioId = location?.state?.studioid;
    var data = JSON.stringify({
      userid: local,
      studio_id: studioId,
      description: descriptiondata,
    });

    var config = {
      method: "post",
      url: aboutStudioApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          studioDetail();
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const reviewList = () => {
    let local = localStorage.getItem("userid");
    let studioId = location?.state?.studioid;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      studio_id: studioId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(reviewListApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setReview(result.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const ReplyApi = () => {
    let local = localStorage.getItem("userid");
    let studioId = location?.state?.studioid;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      studio_id: studioId,
      reply: msgText,
      review_id: String(reviewid),
      time: moment().format("LT"),
    });
    //console.log("reviewwwww", String(reviewid));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(StudioReplyApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          Swal.fire(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // const pull_data = (data) => {
  //   studioDetail(data);
  // };

  const sendMsg = () => {
    ReplyApi();
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      sendMsg();
      setMsgText("");
      setReply(false);
    }
  };

  const setLoading2 = (loading) => {
    setisloading(loading);
  };
  const onpress = (data) => {
    if (data) {
      studioDetail();
    }
  };

  useEffect(() => {
    // console.log("studiooo idddd", location?.state?.studioid);
    setIsLoading(true);
    getProfile();
    ameandequi();
    studioDetail();
    RoomList();
    reviewList();
    window.scrollTo(0, 0);
  }, []);
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const checkboxRef1 = useRef(null);
  const checkboxRef2 = useRef(null);
  const handleModalClosed = () => {
    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
    if (inputRef1?.current?.value) {
      inputRef1.current.value = "";
    }
    if (inputRef2?.current?.value) {
      inputRef2.current.value = "";
    }

    if (inputRef3?.current?.value) {
      inputRef3.current.value = "";
    }
    if (inputRef4?.current?.value) {
      inputRef4.current.value = "";
    }
    if (inputRef5?.current?.value) {
      inputRef5.current.value = "";
    }
    if (inputRef6?.current?.value) {
      inputRef6.current.value = "";
    }
    if (checkboxRef1?.current?.checked) {
      checkboxRef1.current.checked = false;
    }
    if (checkboxRef2?.current?.checked) {
      checkboxRef2.current.checked = false;
    }
  };
  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header studioId={studioId} />

      <div className="studioprofilebanner">
        <a className="backlink" href="/myprofile">
          <img src="images/backbtn1.png" />
        </a>
        {data.profile_pic !== "" ? (
          <img
            src={
              `https://stubies.s3.us-east-2.amazonaws.com/` + data.profile_pic
            }
          />
        ) : (
          <img src="images/banneringstd.jpg" />
        )}
        {/* <img src="images/banneringstd.jpg" /> */}
      </div>
      <div className="studioprofileinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="studioprofileinfoimg">
                {profiledata.profile_pic !== "" ? (
                  <img
                    src={
                      `https://stubies.s3.us-east-2.amazonaws.com/` +
                      profiledata.profile_pic
                    }
                  />
                ) : (
                  <img src="images/studiohome.png" />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="studioprofileinfoimgingo">
                {data !== undefined > 0 ? (
                  <>
                    <h4>{data.studio_name}</h4>
                  </>
                ) : null}
                <div className="reviewstarstudio d-flex">
                  {data.length !== 0 ? (
                    <div className="inforeviestar">
                      {Array(5)
                        .fill(0)
                        .map((_, idx) => (
                          <label key={idx}>
                            <img
                              src={
                                idx < data.rating
                                  ? "images/fillstar.png"
                                  : "images/blankstar.png"
                              }
                              key={idx}
                            />
                          </label>
                        ))}
                    </div>
                  ) : null}
                  <button
                    className="btn readreviewbtn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#reviewsmodal"
                  >
                    <img src="images/readrevieimg.png" /> Read Reviews
                  </button>
                </div>
              </div>
              <p className="studiodescription">
                {data !== undefined > 0 ? <>{data.description}</> : null}
              </p>
            </div>
            <div className="col-md-4">
              <div className="profilebtnright">
                <div className="stubielinksbox">
                  <ul>
                    <li>
                      <a
                        className="customstubielinksforprofile"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-profile"
                      >
                        <img src="images/editprofile.png" />
                        About Studio
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handleModalClosed();
                          setCheckedState(false);
                          setCheckedState1(false);
                          // ameandequi();
                          setVisible(true);
                          setFormValues("");
                          setError("");
                          setChecked("");
                          setChecked1("");
                          setRoomImg([]);
                          setOtherEquipments("");
                          setOtherAmenities("");
                          setAddOns([]);
                          setAmenitiesErr("");
                          setEquipmentsErr("");
                          setImageErr("");
                          setAddService(false);
                        }}
                        className="customstubielinksforprofile"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#add-studio"
                      >
                        <img src="images/addstudio.png" />
                        Add Rooms
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mystudiotabmainsection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mystudiotabinnersection">
                <ul
                  className="nav nav-tabs customstudiotabbar"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        setShowPending(0);
                        //handleScrollToTop();
                      }}
                      className={`nav-link   customtabbtnsetting ${
                        pending === 0 && "active"
                      }`}
                      // className="nav-link   customtabbtnsetting active"
                      //id="mystudios-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#mystudios"
                      type="button"
                      role="tab"
                      aria-controls="mystudios"
                      aria-selected="true"
                    >
                      Rooms
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        setShowPending(1);
                        //handleScrollToTop();
                      }}
                      className={`nav-link   customtabbtnsetting ${
                        pending === 1 && "active"
                      }`}
                      //className="nav-link   customtabbtnsetting"
                      //id="bankinformation-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bankinformation"
                      type="button"
                      role="tab"
                      aria-controls="bankinformation"
                      aria-selected="true"
                    >
                      Media
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        setShowPending(2);
                        //handleScrollToTop();
                      }}
                      className={`nav-link   customtabbtnsetting ${
                        pending === 2 && "active"
                      }`}
                      // className="nav-link   customtabbtnsetting"
                      //id="changepassword-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#changepassword"
                      type="button"
                      role="tab"
                      aria-controls="changepassword"
                      aria-selected="true"
                    >
                      Details
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade show ${
                      pending === 0 && "active"
                    }`}
                    //className="tab-pane fade show active"
                    //id="mystudios"
                    role="tabpanel"
                    aria-labelledby="mystudios-tab"
                  >
                    {roomlist[0]?.id !== undefined ? (
                      <div className="roomsmediamain">
                        <div className="container">
                          <div className="row">
                            {roomlist.map((item, key) => (
                              <div key={key} className="col-md-6">
                                <div className="media roomsmedia d-flex">
                                  <Link
                                    to={`/roomprofile`}
                                    state={{
                                      roomid: item.id,
                                    }}
                                  >
                                    <div className="rooms-media-img">
                                      <img
                                        className="mr-3"
                                        src={
                                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                                          item.banner
                                        }
                                        // src="images/roomsimg.jpg"
                                        alt="Room Image"
                                      />
                                    </div>
                                    <div className="media-body">
                                      <h5 className="mt-0">{item.room_name}</h5>
                                      <p>{item.description}</p>
                                      <h6>
                                        <img
                                          className="pricetagim"
                                          src="images/pricetag.png"
                                          alt=""
                                        />
                                        ${item.price_per_hour}
                                      </h6>
                                    </div>
                                  </Link>
                                  <img
                                    onClick={() => toast(item.id)}
                                    //onClick={() => deleteRoom(item.id)}
                                    className="deleterooms"
                                    src="images/deleteiconimg.png"
                                    alt="Room Image"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h4 className="no-title">Upload room</h4>
                      </>
                    )}
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      pending === 1 && "active"
                    }`}
                    //className="tab-pane fade show"
                    //id="bankinformation"
                    role="tabpanel"
                    aria-labelledby="bankinformation-tab"
                  >
                    <UploadMedia
                      id={location?.state?.studioid}
                      userid={localStorage.getItem("userid")}
                      loading={setLoading1}
                    />
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      pending === 2 && "active"
                    }`}
                    //className="tab-pane fade show "
                    //id="changepassword"
                    role="tabpanel"
                    aria-labelledby="changepassword-tab"
                  >
                    <div className="studio-info-tab">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <h3 className="title-h">
                              Operational Hours
                              {/* ({timeZone}) */}
                            </h3>
                            <div className="editroombocbtn mb-0 text-start">
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#editroommodal"
                                href="#"
                              >
                                Edit
                              </a>
                            </div>
                          </div>
                          <div className="col-md-12"></div>
                          <div
                            className="modal opacity-animate fade"
                            id="editroommodal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-addrooms">
                              <div className="modal-content">
                                {isloading === false ? (
                                  <div className="modal-header">
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                ) : null}
                                <EditStudioDetail
                                  address1={address1}
                                  zipcode1={zipcode1}
                                  city1={city1}
                                  street1={street1}
                                  state1={state1}
                                  studioName={studioName}
                                  studioImage={studioImage}
                                  studiotypes={studiotypes}
                                  phonenumber={phonenumber}
                                  studioId={studioId}
                                  calendar={calendar}
                                  BlackOut={BlackOut}
                                  loading={setLoading2}
                                  onpress={onpress}
                                  descriptiondata={descriptiondata}
                                  lat1={lat1}
                                  lng1={lng1}
                                  timeZone={timeZone}
                                />
                              </div>
                            </div>
                          </div>

                          {workingHours.map((item, key) => (
                            <div className="col-md-6">
                              <div key={key} className="studiotimeinfo d-flex">
                                <h4 className="w-50">{item.day}</h4>
                                {item.check === "1" ? (
                                  <>
                                    <p className="w-50 text-end">
                                      {moment(item.available_from, [
                                        "HH.mm",
                                      ]).format("hh:mm a")}{" "}
                                      TO{" "}
                                      {moment(item.available_to, [
                                        "HH.mm",
                                      ]).format("hh:mm a")}{" "}
                                      {timeZone}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="w-50 text-end">Closed</p>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                          <div className="col-md-12 staddrss">
                            <h3 className="title-h">Studio Address</h3>
                            {data !== undefined ? (
                              <>
                                <p>
                                  {data.street},{data.city},{data.state}
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div
        className="modal fade opacity-animate"
        id="reviewsmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reviews</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {review.length !== 0 ? (
                <>
                  {review.map((n) => {
                    return (
                      <div className="col-md-12">
                        <div className="reviewcontainer">
                          <div className="media reviewbox d-flex">
                            <div className="reviewbox-user-img">
                              <img
                                className="mr-3"
                                src={
                                  `https://stubies.s3.us-east-2.amazonaws.com/` +
                                  n.customer_image
                                }
                                //src={n.customer_image}
                                alt="Room Image"
                              />
                            </div>
                            <div className="media-body">
                              <h5 className="mt-0">{n.customer_name}</h5>
                              <div className="inforeviestar d-flex reviewboxstar">
                                {Array(5)
                                  .fill(0)
                                  .map((_, idx) => (
                                    <label key={idx}>
                                      <img
                                        src={
                                          idx < n.rating
                                            ? "images/fillstar.png"
                                            : "images/blankstar.png"
                                        }
                                        key={idx}
                                      />
                                    </label>
                                  ))}
                              </div>
                              <p>{n.review}</p>
                              <h6
                                className="replybtn"
                                onClick={() => {
                                  setReply(true);
                                  setReviewId(n.id);
                                }}
                              >
                                Reply
                              </h6>
                            </div>
                            <h4 className="comtdate">
                              {/* {moment(n.created_on, ["HH.mm"]).format("hh:mm a")} */}
                              {moment(n.created_on).format("LL")}
                            </h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>No Reviews</p>
              )}
              {reply === true ? (
                <>
                  <div className="position-relative send-review-btn">
                    <input
                      type="text"
                      placeholder="Type Message"
                      className="form-control custominputtypeloginpagesetting customtextbarreview"
                      name="msgText"
                      value={msgText}
                      onKeyPress={msgText.length !== 0 ? onKeyPress : ""}
                      onChange={(e) => setMsgText(e.target.value)}
                    />
                    {msgText.length !== 0 ? (
                      <img
                        src="images/chatsendbtn.png"
                        className="customsendbtnsetting"
                        onClick={() => {
                          sendMsg();
                          setMsgText("");
                          setReply(false);
                        }}
                      />
                    ) : (
                      <img
                        src="images/chatsendbtn.png"
                        className="customsendbtnsetting"
                        // onClick={() => {
                        //   sendMsg();
                        //   setMsgText("");
                        //   setReply(false);
                        // }}
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade opacity-animate"
        id="edit-profile"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">About Studio</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating">
                <textarea
                  className="form-control custom-textarea"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                  name="descriptiondata"
                  value={descriptiondata}
                  onChange={(e) => HandleAbout(e)}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={OnAboutStudio}
                className="btn btn-primary btnsavechanges"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {visible !== false ? (
        <div
          className={"modal fade opacity-animate"}
          onTransitionEnd={handleModalClosed}
          id="add-studio"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-addrooms">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Rooms</h5>
                <button
                  type="button"
                  onClick={() => {
                    setCheckedState(false);
                    setCheckedState1(false);
                    ameandequi();
                    handleModalClosed();

                    setVisible(true);
                    setFormValues("");
                    setError("");
                    setChecked("");
                    setChecked1("");
                    setRoomImg([]);
                    setOtherEquipments("");
                    setOtherAmenities("");
                    setAddOns([]);
                    setAmenitiesErr("");
                    setEquipmentsErr("");
                    setImageErr("");
                    setAddService(false);
                  }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="eyegroup customeyegroupaddroommodal">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Room Name
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="stubiestudio"
                        name="room_name"
                        autoComplete="off"
                        ref={inputRef}
                        value={formValues.room_name}
                        onChange={handleData}
                      />
                      <div>
                        <span className="customvalidationdesignlogin">
                          {error.room_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="eyegroup customeyegroupaddroommodal">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Total Guest
                      </label>
                      <input
                        type="number"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Total Guest"
                        name="capacity"
                        ref={inputRef1}
                        autoComplete="off"
                        value={formValues.capacity}
                        onChange={handleData}
                      />
                      <div>
                        <span className="customvalidationdesignlogin">
                          {error.capacity}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="eyegroup customeyegroupaddroommodal">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        ref={inputRef2}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="price"
                        name="price_per_hour"
                        autoComplete="off"
                        onChange={handleData}
                        value={formValues.price_per_hour}
                      />
                      <div>
                        <span className="customvalidationdesignlogin">
                          {error.price_per_hour}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="eyegroup customeyegroupaddroommodal">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Description
                      </label>
                      <div className="form-floating">
                        <textarea
                          ref={inputRef3}
                          className="form-control custom-textarea"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                          name="description"
                          autoComplete="off"
                          value={formValues.description}
                          onChange={handleData}
                        ></textarea>
                        {/* <label htmlFor="floatingTextarea2">Description</label> */}
                        <div>
                          <span className="customvalidationdesignlogin">
                            {error.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="eyegroup customeyegroupaddroommodal">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Equipment Provided
                      </label>
                      <div className="form-checkcontainer f-l  ">
                        {equipments.map((item, key) => (
                          <>
                            <div key={key} className="form-check me-xl-3">
                              {checkedState !== true ? (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  ref={checkboxRef1}
                                  onChange={(e) => handleChecke(e)}
                                  id={`daw-${item.id}`}
                                  checked={checkedState}
                                />
                              ) : (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  onChange={(e) => handleChecke(e)}
                                  id={`daw-${item.id}`}
                                />
                              )}
                              <label
                                className="form-check-label"
                                htmlFor={`daw-${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </>
                        ))}
                      </div>
                      {equipmentserr && (
                        <span className="customvalidationdesignlogin">
                          Equipment is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className="btnaddservice-room mt-3"
                      onClick={() => {
                        setOtherequip(!otherquip);
                      }}
                    >
                      Other Equipment
                    </button>
                  </div>
                  {otherquip && (
                    <div className="col-md-12">
                      <div className="eyegroup customeyegroupaddroommodal">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Use (,) to add multiple equipment
                        </label>
                        <div className="form-floating">
                          <textarea
                            className="form-control custom-textarea"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            placeholder="Enter other equipments"
                            name="otherequipments"
                            ref={inputRef5}
                            autoComplete="off"
                            value={otherequipments}
                            onChange={(e) => {
                              setOtherEquipments(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 mt-3">
                    <div className="eyegroup customeyegroupaddroommodal">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Amenities
                      </label>
                      <div className="form-checkcontainer f-l  ">
                        {amenities.map((item, key) => (
                          <>
                            <div key={key} className="form-check me-xl-3">
                              {checkedState1 !== true ? (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  id={`smoke-${item.id}`}
                                  autoComplete="off"
                                  onChange={(e) => handleChecke1(e)}
                                  checked={checkedState1}
                                />
                              ) : (
                                <input
                                  className="form-check-input customcheckedboxsetting"
                                  type="checkbox"
                                  name={item.name}
                                  value={item.name}
                                  id={`smoke-${item.id}`}
                                  autoComplete="off"
                                  onChange={(e) => handleChecke1(e)}
                                />
                              )}
                              <label
                                className="form-check-label"
                                htmlFor={`smoke-${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </>
                        ))}
                      </div>
                      {amenitieserr && (
                        <span className="customvalidationdesignlogin">
                          Amenities is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className="btnaddservice-room mt-3"
                      onClick={() => {
                        setOtheramen(!otheramen);
                      }}
                    >
                      Other Amenities
                    </button>
                  </div>
                  {otheramen && (
                    <div className="col-md-12">
                      <div className="eyegroup customeyegroupaddroommodal">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Use (,) to add multiple Amenities
                        </label>
                        <div className="form-floating">
                          <textarea
                            className="form-control custom-textarea"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            placeholder="Enter other amenities"
                            name="otheramenities"
                            value={otheramenities}
                            onChange={(e) => {
                              setOtherAmenities(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <button
                      className="btnaddservice-room mt-3"
                      onClick={() => {
                        setAddService(!addservice);
                        setAddOnNameErr("");
                        setAddOnDescriptionErr("");
                        setAddOnPriceErr("");
                        setAddOnName("");
                        setAddOnPrice("");
                        setAddOnDescription("");
                        setAddImg("");
                      }}
                    >
                      Add Services
                    </button>
                  </div>
                  {addservice && (
                    <>
                      <div className="col-md-12">
                        <h5 className="addsertitle">Add Services</h5>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Enter Title"
                          ref={inputRef4}
                          name="addonname"
                          value={addonname}
                          autoComplete="off"
                          onChange={(e) => handleAddOnName(e)}
                        />
                        {addonnameerr && (
                          <span className="customvalidationdesignlogin">
                            name is required
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Enter Price"
                          name="addonprice"
                          ref={inputRef5}
                          value={addonprice}
                          autoComplete="off"
                          onChange={(e) => handleAddOnPrice(e)}
                        />
                        {addonpriceerr && (
                          <span className="customvalidationdesignlogin">
                            Price is required
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Upload Service Image (Optional)
                        </label>
                        <input
                          type="file"
                          className="form-control custominputtypeloginpagesetting"
                          onChange={handleRoomAdd}
                          //value={formValues.addonimages}
                          name="addonimages"
                          accept="image/*"
                        />
                        {/* {addimgerr && (
                          <span className="customvalidationdesignlogin">
                            Image is required
                          </span>
                        )} */}
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Description
                        </label>
                        <div className="form-floating">
                          <textarea
                            className="form-control custom-textarea"
                            placeholder="Leave a comment here"
                            id="floatingTextarea44"
                            style={{ height: "100px" }}
                            name="addondescription"
                            ref={inputRef6}
                            autoComplete="off"
                            value={addondescription}
                            onChange={(e) => handleAddOnDescription(e)}
                          ></textarea>
                          {/* <label htmlFor="floatingTextarea44">
                            Description
                          </label> */}
                        </div>
                        {addondescriptionerr && (
                          <span className="customvalidationdesignlogin">
                            Description is required
                          </span>
                        )}
                        <div>
                          <button
                            onClick={() => {
                              //setAddService(!addservice);
                              AddOnsData();
                              // setAddOnName("");
                              // setAddOnPrice("");
                              // setAddOnDescription("");
                              // setAddImg("");
                            }}
                            type="button"
                            className="btn btn-primary roomaddbuttonsave"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {addonons.map((item) => (
                    <>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="media roomsmedia d-flex addroomdox">
                              <div className="rooms-media-img">
                                {item.addonimages === "" ? null : (
                                  <>
                                    <img
                                      className="mr-3"
                                      src={
                                        `https://stubies.s3.us-east-2.amazonaws.com/` +
                                        item.addonimages
                                      }
                                      //src="images/roomsimg.jpg"
                                      alt="Room Image"
                                    />
                                    <span onClick={() => DeleteAddRoom(item)}>
                                      X
                                    </span>
                                  </>
                                )}
                              </div>
                              <div className="media-body">
                                <h5 className="mt-0">{item.addonname}</h5>
                                <p>{item.addondescription}</p>
                                <h6>
                                  <img
                                    className="pricetagim"
                                    src="images/pricetag.png"
                                    alt=""
                                  />
                                  ${item.addonprice}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                  <div className="col-md-4 mt-3">
                    <label
                      htmlFor=""
                      className="form-label customlabelloginpagesetting"
                    >
                      Upload Room Images
                    </label>
                    <input
                      type="file"
                      className="form-control custominputtypeloginpagesetting"
                      onChange={(e) => handleRoom(e)}
                      name="url"
                      multiple
                      accept="image/*"
                    />
                    {/* {error.roomimg && (
                      <span className="customvalidationdesignlogin">
                        {error.roomimg}
                      </span>
                    )} */}
                    {imageerr && (
                      <span className="customvalidationdesignlogin">
                        Image is required
                      </span>
                    )}
                  </div>
                  {loading ? (
                    <>
                      <div className="col-md-6">
                        <p>Loading....</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-12">
                        <div className="popaddroomimgcontainer">
                          {roomimg.length !== 0 ? (
                            <>
                              {roomimg.map((item) => {
                                return (
                                  <>
                                    <div className="popaddroomimg">
                                      <img
                                        className="mr-3"
                                        src={
                                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                                          item.url
                                        }
                                        alt="Room Image"
                                      />
                                      <span onClick={() => DeleteImage(item)}>
                                        X
                                      </span>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary roomaddbuttonsave"
                  onClick={(e) => myData(e)}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default StudioProfileRoom;
