import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
// Import Swiper styles
import "swiper/css";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";
import { AboutUsApi } from "../Apis";

function AboutUs() {
  const [about, setAbout] = useState("");

  const AboutUs = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(AboutUsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setAbout(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    AboutUs();
  }, []);

  return (
    <>
      <Header />
      <div className="stubieprofilebannersection">
        <div className="container-fluid">
          <h1 className="changepasswordmainheadingsetting">About Us</h1>
        </div>
      </div>
      <div className="youneedallhere">
        <div className="container">
          <div className="row">
            <div className="col-md-6 youneedallhere-left">
              <div className="everthingyouneedsection customaboutusmagicalleftbox ">
                <h6 className="whoweareaboutusheading">WHO WE ARE</h6>
                <h2>
                  Stubie now even
                  <br /> more amazing!{" "}
                </h2>
                <p>{about}</p>
                {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    <br /> elitIn interdum imperdiet ipsum. denounce with rieous
                    <br /> indiation and dislike men who stubie demor.{" "}
                  </p>
                  <ul>
                    <li className="aboutuscustomlisetting">
                      <img src="images/tickimg.png" />
                      Pre-Book Your Baggage Donating
                    </li>
                    <li className="aboutuscustomlisetting">
                      <img src="images/tickimg.png" /> Accusamus Iusto Odio
                      Dignissimos Ducimus
                    </li>
                    <li className="aboutuscustomlisetting">
                      <img src="images/tickimg.png" />
                      Dislike Men Who are so stubie
                    </li>
                  </ul> */}
                <div className="btnbanner">
                  <Link
                    to="/contactus"
                    //href="booknowbtn btn "
                    className="custombooknowsettingbtn"
                  >
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="youneedallhere-right">
                <img
                  src="images/aboutusbgimg.png"
                  className="customaboutustopimagesetting"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="testmonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12 testmonial-left">
              <div className="customersaymainsectionleft">
                <h2 className="clientsaytitle">
                  What <span>Stubie</span>
                  <br />
                  Customer Say
                </h2>

                <Swiper
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"

                  //navigation
                  //pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  <SwiperSlide>
                    <div className="testmonialslider">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="testmonialslider-left">
                            <p>
                              I love this Stubie, its director and the rest of
                              its staff! Absolutely amazing. Cannot say enough
                              good things about it. Go see for yourself. The
                              teachers and staff are terrific, courteous, and
                              helpful. The recitals at years end getting better
                              each time.
                            </p>
                            <h4>-idea A Wakefield</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="testmonial-right">
                            <img src="images/tesmonialslider.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testmonialslider">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="testmonialslider-left">
                            <p>
                              I love this Stubie, its director and the rest of
                              its staff! Absolutely amazing. Cannot say enough
                              good things about it. Go see for yourself. The
                              teachers and staff are terrific, courteous, and
                              helpful. The recitals at years end getting better
                              each time.
                            </p>
                            <h4>-idea A Wakefield</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="testmonial-right">
                            <img src="images/tesmonialslider1.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testmonialslider">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="testmonialslider-left">
                            <p>
                              I love this Stubie, its director and the rest of
                              its staff! Absolutely amazing. Cannot say enough
                              good things about it. Go see for yourself. The
                              teachers and staff are terrific, courteous, and
                              helpful. The recitals at years end getting better
                              each time.
                            </p>
                            <h4>-idea A Wakefield</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="testmonial-right">
                            <img src="images/tesmonialslider2.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div> */}

      <div className="preebooking custompreebookingsetting">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="preebooking-left">
                <img src="images/preebookingimg.png" />
              </div>
            </div>
            <div className="col-md-7 preebooking-right">
              <div className="customizestudiobookingright">
                {/* <h6>Customize studio booking system</h6> */}
                <h2>The Power and flexibility your studio needs</h2>
                <p>
                  Stubie gives you the flexibility to customize your business to
                  fit your lifestyle while being able to scale at a rapid pace.
                </p>
                <div className="btnbanner">
                  <Link to="/login" className="createcustombtnsetting">
                    CREATE YOUR FREE ACCOUNT
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default AboutUs;
