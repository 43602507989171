import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import "swiper/css";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";
import bootstrap from "bootstrap";

function Home() {
  const [role, setRole] = useState("");

  useEffect(() => {
    let role = localStorage.getItem("role");
    setRole(role);

    // Locals();

    //getProfile();
  }, []);

  return (
    <>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mainbannertextuppersection">
                <h1>
                  Booking your next <span>session</span> <br />
                  is now at your fingertips
                </h1>
                {/* <p>
                  Unlock your creative potential within extraordinary studio
                  spaces.Discover - Reserve - Create for all creatives such as 
                  musicians, photographers, dancers, filmmaker more. 
                  <br />
                  Elevate your artistry with state-of-the-art studios for
                  musicians, photographers, dancers, and filmmakers.
                </p> */}
                <p>
                  Stubie empowers a wide array of creatives to explore, book,
                  and bring their artistic visions to life
                </p>
                <div className="btnbanner">
                  {role === "Customer" ? (
                    <Link className="getstartdbtn" to="/customer">
                      GET STARTED
                    </Link>
                  ) : (
                    <Link className="getstartdbtn" to="/myprofile">
                      GET STARTED
                    </Link>
                  )}
                  <Link to="/contactus" className="contactusbtn" href="#">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bannerbottom">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="maincheckoutmoderntextleft">
                <h3>
                  Checkout <span>Modern</span>
                  Studio’s
                </h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elitsed.
                </p>
                <div className="btnbanner">
                  {role === "Customer" ? (
                    <Link className="viewallbtnbtn" to="/customer">
                      VIEW ALL
                    </Link>
                  ) : (
                    <Link to="/login" className="viewallbtnbtn">
                      VIEW ALL
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="homestudioslider">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1000: {
                      slidesPerView: 5,
                    },
                  }}
                  slidesPerView={5}
                  spaceBetween={20}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  //navigation={true}
                  modules={[Autoplay, Pagination, Navigation, FreeMode]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="rightbanner-bottom-box-slider">
                      <img src="images/studiohome.png" />
                      <p>The studioclassName</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="howitwork">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="howitworkmaintextheading">
                <h2>Key Features</h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="howitbox">
                <img src="images/iconhiw.png" />
                <h3>Seamless Booking</h3>
                <p>
                  Simplify the booking process for user convenience, from search
                  to reservation.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="howitbox">
                <img src="images/iconhiw.png" />
                <h3>Compelling Listings</h3>
                <p>
                  Create compelling studio listings with detailed descriptions
                  and visuals.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="howitbox">
                <img src="images/iconhiw.png" />
                <h3>Secure Payments</h3>
                <p>
                  Integrated secure payment choices and automated booking
                  confirmations.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="howitbox">
                <img src="images/iconhiw.png" />
                <h3>Responsible Support</h3>
                <p>
                  Responsive customer support and adapt based on user feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="preebooking">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="preebooking-left">
                <img src="images/preebookingimg.png" />
              </div>
            </div>
            <div className="col-md-7 preebooking-right">
              <div className="customizestudiobookingright">
                {/* <h6>Customize studio booking system</h6> */}
                <h2>The Power and flexibility your studio needs</h2>
                {/* <p>
                  I love this Stubie, its director and the rest of its staff!
                  Absolutely amazing. Cannot say enough good things about it. Go
                  see for yourself. The teachers and staff are terrific,
                  courteous, and helpful. The recitals at years end getting
                  better each time.
                </p> */}
                <p>
                  Stubie gives you the flexibility to customize your business to
                  fit your lifestyle while being able to scale at a rapid pace
                </p>
                <div className="btnbanner">
                  <Link to="/login" className="createcustombtnsetting">
                    CREATE YOUR FREE ACCOUNT
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="testmonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12 testmonial-left">
              <div className="customersaymainsectionleft">
                <h2 className="clientsaytitle">
                  What <span>Stubie</span>
                  <br />
                  Customer Say
                </h2>

                <Swiper
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="testmonialslider">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="testmonialslider-left">
                            <p>
                              I love this Stubie, its director and the rest of
                              its staff! Absolutely amazing. Cannot say enough
                              good things about it. Go see for yourself. The
                              teachers and staff are terrific, courteous, and
                              helpful. The recitals at years end getting better
                              each time.
                            </p>
                            <h4>-idea A Wakefield</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="testmonial-right">
                            <img src="images/tesmonialslider.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testmonialslider">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="testmonialslider-left">
                            <p>
                              I love this Stubie, its director and the rest of
                              its staff! Absolutely amazing. Cannot say enough
                              good things about it. Go see for yourself. The
                              teachers and staff are terrific, courteous, and
                              helpful. The recitals at years end getting better
                              each time.
                            </p>
                            <h4>-idea A Wakefield</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="testmonial-right">
                            <img src="images/tesmonialslider1.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testmonialslider">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="testmonialslider-left">
                            <p>
                              I love this Stubie, its director and the rest of
                              its staff! Absolutely amazing. Cannot say enough
                              good things about it. Go see for yourself. The
                              teachers and staff are terrific, courteous, and
                              helpful. The recitals at years end getting better
                              each time.
                            </p>
                            <h4>Mark Wood</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="testmonial-right">
                            <img src="images/tesmonialslider2.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div> */}
      <div className="youneedallhere">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 youneedallhere-left">
              <div className="everthingyouneedsection ">
                <h2>
                  Booking a studio shouldn’t
                  <br /> be difficult
                </h2>
                <p>Giving creatives more time to create by:</p>
                {/* <p>
                  I love this Stubie, its director and the rest of its staff!
                  Absolutely amazing.
                  <br /> Cannot say enough good things about it. Go see for
                  yourself. The teachers
                  <br /> and staff are terrific, courteous, and helpful. The
                  recitals at <br />
                  years end getting better each time.
                </p> */}
                <ul>
                  <li>
                    <img src="images/tickimg.png" /> Access to studios across
                    North America
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> 24/7 Customer Support
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> Budget and Time flexibility
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> Secure & Cashless 
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> User friendly interface
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> Fast booking
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> Intergrated scheduling
                  </li>
                  <li>
                    <img src="images/tickimg.png" /> Ease of Communication
                  </li>
                  {/* <li>
                    <img src="images/tickimg.png" /> Microphone Preamp
                  </li> */}
                </ul>
                <div className="btnbanner">
                  {role === "Customer" ? (
                    <Link
                      to="/customer"
                      //state={{ pending: 1 }}
                      //href="booknowbtn btn "
                      className="custombooknowsettingbtn"
                    >
                      Book Now
                    </Link>
                  ) : (
                    <Link
                      to="/signupcustomer"
                      //href="booknowbtn btn "
                      className="custombooknowsettingbtn"
                    >
                      Book Now
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="youneedallhere-right">
                <img src="images/rightimgalldevice.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Home;
