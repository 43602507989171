import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  CreateStripeApi,
  CustomerAddressApi,
  CustomerDetailsApi,
  RoomBookingApi,
} from "../Apis";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../Loader/Loader";

function CheckOut() {
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [countryCode, setCountrycode] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cardno, setCardNo] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCVV] = useState("");
  const [error, setError] = useState({});
  const [token, setToken] = useState("");
  const [date, setDate] = useState("");
  const [date1, setDate1] = useState("");
  const [name, setName] = useState("");
  const [saveCard, setSaveCard] = useState(false);
  const [cardtype, setCardType] = useState("");
  const [StripeId, setStripeId] = useState("");
  const [tax, setTax] = useState([]);
  const [servicefee, setServicefee] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [total, setTotal] = useState("");
  const [addON, setAddON] = useState([]);
  const [Start, setStart] = useState("");
  const [End, setEnd] = useState("");
  const [Hours, setHours] = useState("");
  const [id, setID] = useState("");
  const [Detail, setDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  let location = useLocation();

  const navigate = useNavigate();

  const CustomerDetail = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      devicetype: "abc",
      devicetoken: "abc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setDetails(result.data);
        setAddress(result.data.address1);
        setZipcode(result.data.zipcode);
        setCity(result.data.city);
        setState(result.data.state);
        setStreet(result.data.address2);
        if (
          result.data.stripe_customer_id === "" ||
          result.data.stripe_customer_id === null
        ) {
          CreateStripeCustomer();
        } else {
          setStripeId(result.data.stripe_customer_id);
        }
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };
  const CreateStripeCustomer = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CreateStripeApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        CustomerDetail();
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const PaymentTokenApi = () => {
    setIsLoading(true);
    const m = date.split("/");

    var qs = require("qs");
    var data = qs.stringify({
      "card[number]": cardno,
      "card[exp_month]": m[0],
      "card[exp_year]": m[1],
      "card[cvc]": cvv,
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((result) => {
        //console.log(JSON.stringify(result.data));
        PaymentMethodApi(result.data.id);
      })
      .catch(function (error) {
        //console.log(error);
        Swal.fire(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const PaymentMethodApi = () => {
    const m = date.split("/");
    var qs = require("qs");
    var data = qs.stringify({
      type: "card",
      "card[number]": cardno,
      "card[exp_month]": m[0],
      "card[exp_year]": m[1],
      "card[cvc]": parseFloat(cvv),
      "billing_details[name]": name,
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com/v1/payment_methods",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
      },
      data: data,
    };

    axios(config)
      .then((result) => {
        //console.log("PM" + JSON.stringify(result.data));
        setID(result.data.id);
        CardSaveFun(result.data.id, result.data.card.brand);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire(error);
      });
  };

  const CardSaveFun = (id, card) => {
    if (saveCard === true) {
      AttachPaymentApi(id);
    } else {
      RoomBooking(id, card);
    }
  };

  const AttachPaymentApi = (id) => {
    setIsLoading(true);
    var qs = require("qs");
    var data = qs.stringify({
      customer: StripeId,
    });

    var config = {
      method: "post",
      url: `https://api.stripe.com/v1/payment_methods/${id}/attach`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer sk_test_51Ha85gGjGUhHg0Me5XtfPObRyNNynK6hS7C6jsSPUBvgvuGxnl9xUEXfbjFBqZaLpPsGjqj3N2DeQfugSePsoFi600Eq8bcPqb",
      },
      data: data,
    };

    axios(config)
      .then((result) => {
        //console.log("Attach" + result.data);

        RoomBooking(result.data.id, result.data.card.brand);
      })
      .catch((error) => {
        alert(error);
        setIsLoading(false);
      });
  };
  const cardValidation = () => {
    let error = {};
    let regex = /^[a-zA-Z]*$/;
    if (!cardno) {
      error = { ...error, cardno: "Enter card number" };
    }

    if (!name) {
      error = { ...error, name: "Enter Card Holder Name" };
    } else if (!regex.test(name)) {
      error = { ...error, name: "Enter Card Holder Name" };
    }
    if (!date) {
      error = { ...error, date: "Enter expired date" };
    }
    if (!cvv) {
      error = { ...error, cvv: "Enter cvv" };
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      if (Detail.address1) {
        PaymentTokenApi();
      } else {
        Swal.fire("add address to continue payment");
      }
    }
    return error;
  };

  const AddressValidation = () => {
    let error = {};
    if (!address) {
      error.address = "Address field is required.";
    }
    // if (!street) {
    //   error.street = "Street field is required.";
    // }
    if (!city) {
      error.city = "City field is required.";
    }
    if (!state) {
      error.state = "City field is required.";
    }
    if (!zipcode) {
      error.zipcode = "Zipcode field is required.";
    }
    //console.log("hj", error);
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      addCusAddress();
    }
    return error;
  };

  const addCusAddress = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      address1: address,
      address2: address,
      city: city,
      zipcode: zipcode,
      state: state,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerAddressApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          CustomerDetail();
          Swal.fire(result.message);
        }
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const AddAddress = () => {
    setError(AddressValidation());
  };

  const handleSelect = (address, name) => {
    console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        //console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };
        setCity(addressObject.city);
        setState(addressObject.state);
        setAddress(address);
        setZipcode(addressObject.zipcode);
        setStreet(addressObject.street);
        setCountrycode(addressObject.cn);
        // setCityErr("");
        // setZipcodeErr("");
        // setStreetErr("");
        // setStateErr("");
        getLatLng(results[0]).then((latLng) => {
          setLat(latLng.lat);
          setLng(latLng.lng);
          //console.log("Success", latLng);
        });
      })

      .catch((error) => {
        //console.error("Error", error)
      });
  };
  const handleChange1 = (address) => {
    // console.log(address);
    setAddress(address);
    //   setCityErr("");
    //   setZipcodeErr("");
    //   setStreetErr("");
    //   setStateErr("");
  };

  const RoomBooking = (id, id2) => {
    setIsLoading(true);
    console.log("gdhs", addON);
    let local = localStorage.getItem("userid");
    console.log("customer", local);
    let AId = addON.map((n) => n.id);
    let AId1 = AId.toString();
    let d = moment(date1).format("DD/MM/YYYY");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      addons: AId1,
      customer_id: local,
      customer_note: "",
      date: d,
      end_time: String(End),
      no_of_hours: String(Hours),
      pageno: 0,
      payment_method: id,
      room_id: roomData.id,
      room_price: String(roomData.price_per_hour),
      service_fee: String(servicefee),
      start_time: String(Start),
      studio_id: roomData.studio_id,
      tax: String(tax),
      total_price: String(totalPrice),
      payment_status: "true",
      payment_type: "card",
    });
    console.log("total ADDDDONN", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(RoomBookingApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          Swal.fire("Room booked successfully!");
          setIsLoading(false);
          navigate("/customer", { state: { pending: 2, name: roomData.id } });
        } else {
          Swal.fire(result.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        setIsLoading(false);
      });
  };

  const Payment = () => {
    setError(cardValidation());
    // if(card.length!==0){

    // }
  };

  const onChange = (event) => {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
    //this.setState({expiry:event.target.value,error:""})
    setDate(event.target.value);
  };
  function lettersValidate(key) {
    var keycode = key.which ? key.which : key.keyCode;

    if ((keycode > 64 && keycode < 91) || (keycode > 96 && keycode < 123)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    let Detail = location?.state?.Result;
    let Detail1 = location?.state?.Dates;
    let Detail4 = location?.state?.AddOn;
    //console.log(Detail4);
    setAddON(Detail4);
    let roomdata = location?.state?.RoomDetails;
    let Detail2 = location?.state?.Start;
    let Detail3 = location?.state?.End;
    let hours = location?.state?.Hours;
    //let total = location.state.totalPrice;
    if (location?.state?.totalPrice) {
      setTotalPrice(location?.state?.totalPrice);
    }
    //console.log("totalprice", location?.state?.totalPrice);

    setStart(Detail2);
    setEnd(Detail3);
    setHours(hours);
    setRoomData(roomdata);
    setDate1(Detail1);
    //console.log("detail4", Detail1);
    // let service = Detail.serviceFee;
    // let serviceFee1 = Number(
    //   parseFloat(roomData.price_per_hour / 100) * service
    // );
    // console.log(serviceFee1);
    setServicefee(Detail.serviceFee);
    console.log("serrrr", Detail.serviceFee);
    // let serviceTax = Detail.tax;
    // let finalServiceTax = (serviceTax / 100) * roomPrice + serviceFee1;
    setTax(Detail.tax);
    //console.log("tax", Detail.tax);
    //console.log("serviceFee", Detail.serviceFee);
    CustomerDetail();
    window.scrollTo(0, 0);
  }, []);
  const paycard = () => {
    navigate("/customer", {
      state: {
        pending: 4,
        roomData: roomData,
        totalPrice: totalPrice,
        tax: tax,
        servicefee: servicefee,
        Start: Start,
        Hours: Hours,
        End: End,
        addON: addON,
        date1: date1,
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header />
      <div className="stubieprofilebannersection">
        <div className="container">
          {/* <Link className="stubiecustomerbookingbackbtn">
            <img
              src="images/stubiecustomerbackbtn.png"
              className="stubiecustomerbackbtnimgsetting"
            />
            Back
          </Link> */}
          <h1 className="changepasswordmainheadingsetting">Checkout Details</h1>
        </div>
      </div>

      <div className="stubiecustomercheckoutdetailmainsection">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="checkoutpayoptionleftbox">
                <ul className="checkoutcardulsetting">
                  <li className="checkoutcardullisetting">
                    <a className="paymentcardcustomlink">
                      <img
                        src="images/checkoutpaymentcardblack.png"
                        className="paymentcardiconimg"
                      />{" "}
                      Credit Card/Debit Card
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-8">
              <div className="checkoutformboxright">
                <form noValidate>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="usesavecard ">
                        <button
                          type="button"
                          onClick={() => paycard()}
                          className="bookingdeclinebtnsetting  stubiecustomerproceedbtnsetting savecardbtn"
                        >
                          <img
                            src="images/cardsave.png"
                            className="cardsaveimage"
                          />
                          Use Save Card
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Card Number
                      </label>
                      <input
                        type="text"
                        name="cardno"
                        maxlength="19"
                        value={cardno}
                        autoComplete="on"
                        onChange={(e) => {
                          let formattedText = e.target.value;
                          if (formattedText.length > 0) {
                            formattedText = formattedText
                              .replace(/\W/gi, "")
                              .replace(/(.{4})/g, "$1 ");
                          }

                          setCardNo(formattedText);

                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                      />
                      {error.cardno && (
                        <span className="customvalidationdesignlogin">
                          {error.cardno}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Card Holder Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        autoComplete="off"
                        pattern="[A-Za-z\\s]*"
                        onkeypress="return lettersValidate(event)"
                        onChange={(e) => {
                          setName(e.target.value);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder=""
                      />
                      {error.name && (
                        <span className="customvalidationdesignlogin">
                          {error.name}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Expiration Date
                      </label>
                      <input
                        type="text"
                        name="date"
                        value={date}
                        autoComplete="off"
                        onChange={(e) => {
                          onChange(e);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder=""
                      />{" "}
                      {error.date && (
                        <span className="customvalidationdesignlogin">
                          {error.date}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        CVV
                      </label>
                      <input
                        type="number"
                        name="cvv"
                        value={cvv}
                        autoComplete="off"
                        onChange={(e) => {
                          setCVV(e.target.value);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder=""
                      />
                      {error.cvv && (
                        <span className="customvalidationdesignlogin">
                          {error.cvv}
                        </span>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="savecardpaymentbtnbottombox">
                        <h4 className="savecradmainheadingpaymentoption">
                          SaveCard
                        </h4>
                        <div className="form-check form-switch ">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            onClick={() => setSaveCard(true)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="bookinginnerdetailsection stubiecustomerbillingaddressseting">
                        <h5>Billing Address</h5>
                      </div>
                    </div>
                    <div className="col-md-6  customcheckoutdetailwidth">
                      <div className="stubiestudiotextdetail">
                        <h6 className="bookingpricesetting stubiecustomerbookingaddressheading">
                          {Detail.zipcode && Detail.address1 ? (
                            <>
                              {Detail.address1},{Detail.city},{Detail.state}, (
                              {Detail.zipcode})
                            </>
                          ) : null}
                          {/* {Detail.address1},{Detail.city},{Detail.state}, (
                          {Detail.zipcode}) */}
                          <br />
                        </h6>
                      </div>
                    </div>
                    <div className="col-md-6 customcheckoutdetailwidth">
                      <div className="stubiecustomercheckouteditbtnright">
                        <a
                          href="#"
                          className="stubiecustomereditpopbtn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#examplestubiecustomereditbtn"
                        >
                          <img
                            src="images/checkouteditbtn.png"
                            className="stubiecustomercheckouteditimg"
                          />
                          Edit
                        </a>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="bookingstudiodetail d-flex stubiecustomertotalpricesetting">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting customtotalpricesetting">
                            Total Price
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice  ">
                          {totalPrice ? (
                            <>
                              <h6 className="customtotalmoneysetting">
                                ${/* {totalPrice} */}
                                {new Intl.NumberFormat("en-US").format(
                                  totalPrice
                                )}
                              </h6>
                            </>
                          ) : (
                            <h6>$ 0</h6>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bookingdeatailfinalbtnright">
                        <button
                          type="button"
                          onClick={() => Payment()}
                          className="bookingdeclinebtnsetting  stubiecustomerproceedbtnsetting"
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div
        className="modal fade opacity-animate"
        id="examplestubiecustomereditbtn"
        tabIndex="-1"
        aria-labelledby="examplestubiecustomereditbtn"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Billing Address</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    name="address"
                    autoComplete="off"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="form-control custominputtypeloginpagesetting"
                    placeholder=""
                  />
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    autoComplete="off"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control custominputtypeloginpagesetting"
                    placeholder=""
                  />
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    autoComplete="off"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="form-control custominputtypeloginpagesetting"
                    placeholder=""
                  />
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    Zipcode
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="zipcode"
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                    className="form-control custominputtypeloginpagesetting"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="fliterpopupbottomapplybtnbox">
                <ul>
                  <li>
                    <button
                      type="button"
                      className="btn btn-primary  customfliterclearbtnsetting"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => AddAddress()}
                      className="btn btn-primary customfliterapplybtnsetting"
                      data-bs-dismiss="modal"
                    >
                      Add
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CheckOut;
