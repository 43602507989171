import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { customerStudioListApi } from "../Apis";

function Studios(props) {
  const [country, setCountry] = useState([]);
  const [studioList, setStudioList] = useState([]);
  const [lat, setLat] = useState("");
  const [lan, setLan] = useState("");
  const [viewData, setViewData] = useState([]);
  let location = useLocation();
  const StudioList = (lat, lan) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      lat: String(lat),
      lng: String(lan),
      devicetoken:
        "drH2bPMPY0S6gBhjfmukdE:APA91bEQpixIBZWB9Vk6rJLQ-K8esKmLVuNxJ03tdOLeLXrOFXLLH4pVA-LEJd4Z3j7GuNKXXHHBokBH5eGM_udb996gcxuuHOvgjGlmstFayVk1rk8d65-eDXnYA6cglB2UkfOI5hiU",
      devicetype: "I",
      distance: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(customerStudioListApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          setStudioList(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setCountry(json);
        setLat(json.lat);
        setLan(json.lon);
        StudioList(json.lat, json.lon);
      });
    window.scrollTo(0, 0);
    setViewData(props.viewData);
    // console.log("viewwww", props.viewData);
    // if (location?.state?.lat && location?.state?.lng) {
    //   StudioList();
    // }
  }, []);

  return (
    <>
      <div className="studiocustomermainsection">
        <div className="container">
          <div className="row">
            {props.data.length === 0 ? (
              // && props.studioList1.length === 0
              <>
                {studioList.map((item) => (
                  <div className="col-md-4 stubiecustomercustomwidthsingle">
                    <div className="studiocustomerinnerboxsection">
                      <Link
                        to={`/cusstudiodetail`}
                        state={{
                          studioid: item.studio_id,
                          userId: item.userid,
                          lat: item.lat,
                          lan: item.lng,
                          viewData: props.viewData,
                        }}
                      >
                        <div className="studiocustomerimagebox">
                          {item.profile_pic.length !== 0 ? (
                            <>
                              <img
                                src={
                                  `https://stubies.s3.us-east-2.amazonaws.com/` +
                                  item.profile_pic
                                }
                              />
                            </>
                          ) : (
                            <>
                              <img src="images/profiledefault.jpg" />
                            </>
                          )}
                        </div>

                        <div className="studiocustomertextboxsection">
                          <h3>{item.studio_name}</h3>
                          <p>{item.description}</p>
                        </div>

                        <div className="studiocustomermilestarbox d-flex">
                          <h6>
                            <img src="images/locationmilestudibecustmoer.png" />
                            <span>{item.distance.toFixed(2)} miles</span>
                          </h6>
                          <div className="studiocustmoerstarboxrightbottom">
                            <ul>
                              {Array(5)
                                .fill(0)
                                .map((_, idx) => (
                                  <li key={idx}>
                                    <img
                                      src={
                                        idx < item.rating
                                          ? "images/fillstar.png"
                                          : "images/blankstar.png"
                                      }
                                      key={idx}
                                    />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {props.data.length !== 0 ? (
                  <>
                    {props.data.map((item) => (
                      <div className="col-md-4 stubiecustomercustomwidthsingle">
                        <div className="studiocustomerinnerboxsection">
                          <Link
                            to={`/cusstudiodetail`}
                            state={{
                              studioid: item.studio_id,
                              userId: item.userid,
                              data: props.data,
                              viewData: props.viewData,
                            }}
                          >
                            <div className="studiocustomerimagebox">
                              {item.profile_pic.length !== 0 ? (
                                <>
                                  <img
                                    src={
                                      `https://stubies.s3.us-east-2.amazonaws.com/` +
                                      item.profile_pic
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <img src="images/profiledefault.jpg" />
                                </>
                              )}
                            </div>

                            <div className="studiocustomertextboxsection">
                              <h3>{item.studio_name}</h3>
                              <p>{item.description}</p>
                            </div>

                            <div className="studiocustomermilestarbox d-flex">
                              <h6>
                                <img src="images/locationmilestudibecustmoer.png" />
                                <span>{item.distance.toFixed(2)} miles</span>
                              </h6>
                              <div className="studiocustmoerstarboxrightbottom">
                                <ul>
                                  {Array(5)
                                    .fill(0)
                                    .map((_, idx) => (
                                      <li key={idx}>
                                        <img
                                          src={
                                            idx < item.rating
                                              ? "images/fillstar.png"
                                              : "images/blankstar.png"
                                          }
                                          key={idx}
                                        />
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p>No Studio list available</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Studios;
