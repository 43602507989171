import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ChangeStudioAddressApi } from "../Apis";
import { Modal } from "bootstrap";
import Loader from "../Loader/Loader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function ChangeAddress(props) {
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [message, setMessage] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let error = {};

    if (!city) {
      error.city = "City is required.";
    }

    if (!state) {
      error.state = "State is required.";
    }

    //   if (address === "") {
    //     error.address = "Address is required.";
    //   }
    if (!zipcode) {
      error.zipcode = "Zipcode is required.";
    }
    // if (!street) {
    //   error.street = "Street is required.";
    // }
    if (!lng) {
      error.lng = "lng is required.";
    }
    if (!lat) {
      error.lat = "lat is required.";
    }

    // console.log(Object.keys(error).length);
    // console.log(error);

    if (Object.keys(error).length === 0) {
      ChangeAddressApi();
    }

    return error;
  };

  const Submit = () => {
    setError(validate());
  };

  const ChangeAddressApi = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: props.id,
      userid: props.userid,
      street: street,
      city: city,
      state: state,
      address: address,
      zipcode: zipcode,
      message: message ? message : "",
      lat: String(lat),
      lng: String(lng),
    });
    //console.log("address", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(ChangeStudioAddressApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.error === "0") {
          Swal.fire(result.message);
          setStreet("");
          setCity("");
          setMessage("");
          setState("");
          setZipcode("");
          setLat("");
          setLng("");
          setAddress("");
          props.loading(false);
          setIsLoading(false);
          window.scrollTo(0, 0);
        } else {
          Swal.fire(result.message);
          props.loading(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        props.loading(false);
        setIsLoading(false);
        //console.log("error", error);
      });
  };
  const handleChange1 = (address) => {
    // console.log(address);
    //setStreet(street);
    setAddress(address);
    setError("");
  };

  const handleSelect = (address, name) => {
    console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };
        setCity(addressObject.city);
        setState(addressObject.state);
        setAddress(address);
        setZipcode(addressObject.zipcode);
        setStreet(addressObject.street);
        //setCountrycode(addressObject.cn);
        getLatLng(results[0]).then((latLng) => {
          setLat(latLng.lat);
          setLng(latLng.lng);
          //console.log("Success", latLng);
        });
      })

      .catch((error) => {
        //console.error("Error", error)
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="modal-body">
        <div className="form-floating">
          <div className="col-md-12">
            <div className="eyegroup">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label customlabelloginpagesetting"
              >
                Street
              </label>
              <PlacesAutocomplete
                value={address}
                onChange={handleChange1}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      name="street"
                      value={street}
                      {...getInputProps({
                        placeholder: "Enter Street",
                        className:
                          "form-control custominputtypeloginpagesetting",
                      })}
                      autoComplete="route"
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {error && (
                <span className="customvalidationdesignlogin">
                  {error.street}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="eyegroup">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label customlabelloginpagesetting"
              >
                City
              </label>
              <input
                type="text"
                className="form-control custominputtypeloginpagesetting"
                placeholder="Enter City"
                name="city"
                value={city}
                autoComplete="administrative_area_level_2"
                onChange={(e) => {
                  setCity(e.target.value);
                  setError("");
                }}
              />
              {error && (
                <span className="customvalidationdesignlogin">
                  {error.city}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="eyegroup">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label customlabelloginpagesetting"
              >
                State
              </label>
              <input
                type="text"
                className="form-control custominputtypeloginpagesetting"
                placeholder="Enter State"
                name="state"
                value={state}
                autoComplete="administrative_area_level_1"
                onChange={(e) => {
                  setState(e.target.value);
                  setError("");
                }}
              />
              {error && (
                <span className="customvalidationdesignlogin">
                  {error.state}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="eyegroup">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label customlabelloginpagesetting"
              >
                Zipcode
              </label>
              <input
                type="text"
                className="form-control custominputtypeloginpagesetting"
                placeholder="Enter Zipcode"
                name="zipcode"
                value={zipcode}
                autoComplete="postal-code"
                onChange={(e) => {
                  setZipcode(e.target.value);
                  setError("");
                }}
              />
              {error && (
                <span className="customvalidationdesignlogin">
                  {error.zipcode}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="eyegroup">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label customlabelloginpagesetting"
              >
                Message
              </label>
              <textarea
                className="form-control custom-textarea"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
                style={{ height: "100px" }}
                name="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="savebtnsetting mt-3"
        type="button"
        onClick={() => Submit()}
      >
        Save
      </button>

      {/* <div className="modal-footer">
        <button
          type="button"
          onClick={() => Submit()}
          className="btn btn-primary btnsavechanges"
        >
          Save changes
        </button>
      </div> */}
    </>
  );
}
export default ChangeAddress;
