import React, { useEffect, useState, useRef, useCallback } from "react";
import { MediaDeleteApi, MediaListApi, uploadMediaApi } from "../Apis";
import axios from "axios";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import {
  generateVideoThumbnails,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";

function UploadMedia(props) {
  const [images, setImages] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState("");
  const [status, setStatus] = useState(false);
  const [video1, setVideo1] = useState("");
  const [image, setImage] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoThumb, setVideoThumb] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [type, setType] = useState("image");

  const refs = useRef({
    video: null,
  });

  const [files, setFiles] = useState("");

  const handleChangeMedia = async (e) => {
    let array = [];
    for (let i = 0; i <= e.target.files.length; i++) {
      var file = e.target.files[i];
      let size = e.target.files[i].type.replace("/", ".");
      var blob = file.slice(0, file.size, e.target.files[i].type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: e.target.files[i].type });

      let data = await UploadMedia(newFile, "Media", i);

      array.push(data);
      // console.log("arrrra", array.length);
    }

    if (e.target.files[0].type.includes("video") === true) {
      generateVideoThumbnails(e.target.files[0], 2).then((thumbs) => {
        var file = dataURLtoFile(thumbs[1], `hello+${new Date()}.png`);
        setFiles(file);
        // console.log(file);
        UploadMedia(e.target.files[0]);
        //UploadMedia(file);
        setThumbnails(thumbs);
        setType("video");
      });
    } else {
      UploadMedia(e.target.files[0]);
      setType("image");
    }
    setImages(array);
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const UploadMedia = async (newFile) => {
    //alert("called")
    props.loading(true);
    setIsLoading(true);
    var FormData = require("form-data");

    var data = new FormData();
    data.append("studio_id", props.id);
    data.append("media", newFile);
    //data.append("vedio", newFile);
    data.append("userid", props.userid);
    const response = await axios.post(uploadMediaApi, data);

    setImages(response.data.data);
    if (response.data.error === "0") {
      props.loading(false);
      UploadMediaList();
      setIsLoading(false);
    }
  };

  const UploadMediaList = () => {
    var data = JSON.stringify({
      userid: props.userid,
      studio_id: props.id,
    });

    var config = {
      method: "post",
      url: MediaListApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        //console.log("hjg", response.data.data.length);
        setMediaList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const toast = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(DeleteMedia(id), "Your media has been deleted.", "success");
      }
    });
  };
  const DeleteMedia = (id) => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: props.id,
      userid: props.userid,
      mediaid: [id],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(MediaDeleteApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          UploadMediaList();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    UploadMediaList();

    if (video) {
      importFileandPreview(video).then((res) => {
        setImages(res);
      });
      setVideoThumb("");
      setThumbnails([]);
    }
  }, [video]);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      {/* <div
        className="tab-pane fade show"
        id="bankinformation"
        role="tabpanel"
        aria-labelledby="bankinformation-tab"
      > */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-3" onClick={() => setStatus(true)}>
            <input
              id="uplaodmedial"
              type="file"
              className="form-control custominputtypeloginpagesetting"
              onChange={(e) => handleChangeMedia(e)}
              multiple
              accept="image/*,video/*"
            />
            <label className="uploadmediabtn" htmlFor="uplaodmedial">
              {" "}
              <img src="images/uploadmedia.png" />
            </label>
          </div>

          {mediaList[0]?.id !== undefined ? (
            <>
              {mediaList.map((item, key) => (
                <div key={key} className="col-md-2">
                  <div className="glryimg mediagalarybox" key={key}>
                    {item.url.includes("video") ? (
                      <video
                        className="glryimgimg"
                        poster={videoThumb}
                        //id="video"
                        ref={(el) => (refs.current.video = el)}
                        controls
                        src={
                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                          item.url
                        }
                      >
                        <source
                          src={
                            `https://stubies.s3.us-east-2.amazonaws.com/` +
                            item.url
                          }
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img
                        className="glryimgimg"
                        src={
                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                          item.url
                        }
                        alt=""
                      />
                    )}
                    <span
                      className="mediacloseicon"
                      onClick={() => toast(item.id)}
                    >
                      X
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h4 className="no-title">Media not available</h4>
          )}
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
export default UploadMedia;
