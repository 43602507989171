import React, { useEffect, useState, useRef } from "react";

import {
  getDatabase,
  ref,
  child,
  push,
  update,
  onValue,
  runTransaction,
} from "firebase/database";
import moment from "moment";
import { ChatImageUploadApi } from "../Apis";
function Message(props) {
  const [data, setData] = useState([]);
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [messages, setmessage] = useState([]);
  const [msgText, setMsgText] = useState("");
  const messageEl = useRef(null);
  const sendMessage = (msg, type) => {
    let local = localStorage.getItem("userid");
    var time = moment().utc();

    const format = time.format("MM-DD-YYYY,hh:mm A");

    props.func(true);
    const db = getDatabase();
    var result = time.valueOf();
    const postData = {
      b_remove: "1",
      c_remove: "1",
      is_seen: "1",
      message: msg,
      sender: "1",
      time: format,
      type: type,
    };
    const businessData = {
      id: id,
      image: image,
      last_update: format,
      message: msg,
      name: name,
      time_stamp: result,
    };
    const cusData = {
      id: local,
      image: props.image,
      last_update: format,
      message: msg,
      name: props.name,
      time_stamp: result,
    };
    let user = id + "_" + local;

    // Get a key for a new Post.
    const newPostKey = push(child(ref(db), "messages")).key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    updates["/businessTable/" + local + "/" + id] = businessData;
    updates["/customerTable/" + id + "/" + local] = cusData;
    updates["/messages/" + user + "/" + newPostKey] = postData;
    getTable("some");
    return update(ref(db), updates);
  };

  useEffect(() => {
    //console.log("ig",props.image)
    getTable();
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({
          top: target.scrollHeight,
          behavior: "smooth",
        });
      });
    }
  }, [props.id]);
  const getTable = (some) => {
    let local = localStorage.getItem("userid");

    if (local) {
      const db = getDatabase();
      const starCountRef = ref(db, `businessTable/${local}`);
      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          let msgs = [];
          snapshot.forEach((child) => {
            if (child.val().id) {
              msgs.push({
                id: child.val().id,
                image: child.val().image
                  ? child
                      .val()
                      .image.includes(
                        "https://stubies.s3.us-east-2.amazonaws.com/"
                      ) === true
                    ? child.val().image
                    : "https://stubies.s3.us-east-2.amazonaws.com/" +
                      child.val().image
                  : "",
                last_update: moment
                  .utc(child.val().last_update)
                  .local()
                  .format("MM-DD-YYYY,hh:mm A"),
                message: child.val().message,
                name: child.val().name,
                time_stamp: child.val().time_stamp,
              });
            }
          });

          let extra = {};
          if (some === undefined) {
            if (props?.location?.chatid) {
              var myDate = new Date();
              var result = myDate.getTime();

              extra = {
                id: props.location.chatid,
                image: props.location.chatImage,
                last_update: moment().format("MM-DD-YYYY,HH:MM A"),
                message: "Start Conversation",
                name: props.location.chatname,
                time_stamp: result,
              };

              let idArray = msgs.map((n) => n.id);
              if (idArray.includes(props?.location?.chatid) === false) {
                msgs.push(extra);
              } else {
                let m1 = msgs.find((n) => n.id === props?.location?.chatid);
                getChat(local, m1, "jjj");
              }
            } else {
              props.func(false);
            }
            let sortArray = msgs.sort(
              (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
            );
            setData(sortArray);
            if (props?.location?.chatid) {
              setId(props.location.chatid);
              setImage(props.location.chatImage);
              setName(props.location.chatname);
            } else {
              //console.log("msgs", msgs);
              getChat(local, sortArray[0], "jjjk");
            }
          } else {
            let sortArray = msgs.sort(
              (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
            );
            setData(sortArray);

            let datamsg = sortArray.find((n) => n.id === id);
            getChat(local, datamsg, "jjj");
          }
        } else {
          let msgs = [];
          let extra = {};
          if (some === undefined) {
            if (props?.location?.chatid) {
              var myDate = new Date();
              var result = myDate.getTime();
              extra = {
                id: props.location.chatid,
                image: props.location.chatImage,
                last_update: moment().format("MM-DD-YYYY,HH:MM A"),
                message: "Start Conversation",
                name: props.location.chatname,
                time_stamp: result,
              };

              msgs.push(extra);
              setId(props.location.chatid);
              setImage(props.location.chatImage);
              setName(props.location.chatname);
              setmessage([]);
            } else {
              props.func(false);
            }

            setData(msgs);
          }
        }
      });
    }
  };
  const getChat = (id, id2) => {
    const db = getDatabase();
    const starCountRef = ref(db, `messages/${id2.id + "_" + id}`);

    onValue(starCountRef, (snapshot) => {
      if (snapshot.exists()) {
        let msgs = [];
        snapshot.forEach((child) => {
          msgs.push({
            b_remove: child.val().b_remove,
            c_remove: child.val().c_remove,
            is_seen: child.val().is_seen,
            message: child.val().message,
            sender: child.val().sender,
            time: moment
              .utc(child.val().time)
              .local()
              .format("MM-DD-YYYY,hh:mm A"),
            date: moment
              .utc(child.val().time)
              .local()
              .format("MM-DD-YYYY,hh:mm A")
              .split(",")[0],
            type: child.val().type,
          });

          const starCountRef1 = ref(
            db,
            `messages/${id2.id + "_" + id + "/" + child.key}`
          );
          runTransaction(starCountRef1, (post) => {
            if (post) {
              if (post.sender === "0") {
                post.is_seen = "0";
              }
            }

            return post;
          });
        });
        let data2 = Object.values(
          msgs.reduce((acc, item) => {
            if (!acc[item.date])
              acc[item.date] = {
                title: item.date,
                data: [],
              };
            acc[item.date].data.push(item);
            return acc;
          }, {})
        );
        setmessage(data2);
        setId(id2.id);
        setImage(id2.image);
        setName(id2.name);
        setMsgText("");
      }
    });
  };

  const handleRoomAdd = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    ChatImage(newFile);
  };

  const ChatImage = (newFile) => {
    var formdata = new FormData();
    formdata.append("file", newFile);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(ChatImageUploadApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(
        //   "imagesss",
        //   "https://stubies.s3.us-east-2.amazonaws.com/" + result.data,
        //   "image"
        // );

        sendMessage(
          "https://stubies.s3.us-east-2.amazonaws.com/" + result.data,
          "image"
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      if (messageEl) {
        messageEl.current.addEventListener("DOMNodeInserted", (event) => {
          const { currentTarget: target } = event;
          target.scroll({
            top: target.scrollHeight,
            behavior: "smooth",
          });
        });
      }
      sendMessage(msgText, "text");
    }
  };

  const renderHeader = (section) => {
    const today = moment().format("MM-DD-YYYY");
    const yesterday = moment().subtract(1, "day").format("MM-DD-YYYY");

    if (section.title === today) {
      return (
        <div className="todaytitle">
          <div>Today</div>
        </div>
      );
    } else if (section.title === yesterday) {
      return (
        <div className="yesterdaytitle">
          <div>Yesterday</div>
        </div>
      );
    } else {
      return (
        <div className="todaytitle">
          <div> {section.title}</div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className="tab-pane fade show "
        id="messages"
        role="tabpanel"
        aria-labelledby="messages-tab"
      >
        <div className="mainmessagesection">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="messagechatsectionleft studiomessageleftbox">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="chatmainheadingsection">
                        <h5>Chats</h5>
                      </div>
                    </div>
                    <div className="chatleftmessagemainsectionfixheight">
                      <div className="chatleftmessagemainsectionfixheight2">
                        {data.lenth !== 0 ? (
                          <>
                            {data.map((n) => {
                              return (
                                <div
                                  onClick={() => {
                                    let local = localStorage.getItem("userid");
                                    getChat(local, n);
                                  }}
                                  className="col-md-12"
                                >
                                  <div className="personchatboxdetail d-flex ">
                                    <div className="personchatimg">
                                      {n.image ? (
                                        <img src={n.image} />
                                      ) : (
                                        <img src="images/loginimgocon.png" />
                                      )}
                                    </div>
                                    <div className="personchattextbox">
                                      <h6>{n.name}</h6>
                                      {/* <p>{n.message}</p> */}
                                      {n.type === "text" ? (
                                        <p>{n.message}</p>
                                      ) : (
                                        <>
                                          {n.message?.includes(
                                            "https://stubies.s3.us-east-2.amazonaws.com/"
                                          ) === true ? (
                                            <>
                                              {/* <img src={n.message} /> */}
                                              <img src="images/imageicon.png" />
                                              <p>PHOTO</p>
                                            </>
                                          ) : (
                                            <p>{n.message}</p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="personchattimebox custompersonchatactive">
                                      <h4 style={{ marginTop: 30 }}>
                                        {moment
                                          .utc(Number(n.time_stamp))
                                          .local()
                                          .startOf()
                                          .fromNow()}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="messagechatinnersectionright">
                  {messages.length !== 0 && data.length !== 0 && (
                    <div className="col-md-12">
                      <div className="chatinnerheadingbox d-flex">
                        <h3>{name}</h3>
                        <div className="chatinnerimgboxright">
                          {image !== "" ? (
                            <img src={image} />
                          ) : (
                            <img src="images/loginimgocon.png" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="messagetopheightcuetom">
                    <div className="messagetopheightcuetom2" ref={messageEl}>
                      {messages.length !== 0 && data.length !== 0 ? (
                        <>
                          {messages.map((item) => (
                            <>
                              {renderHeader(item)}
                              {item.data.map((m) => (
                                <div className="col-md-12">
                                  <div className="chatinnertextboxmessage">
                                    <div
                                      className={
                                        m.sender === "0"
                                          ? "innertypingchatbox"
                                          : "innertypingchatbox custominnertypingchatboxsetting"
                                      }
                                    >
                                      {m.type === "text" ? (
                                        <h6>{m.message}</h6>
                                      ) : (
                                        <>
                                          {m.message?.includes(
                                            "https://stubies.s3.us-east-2.amazonaws.com/"
                                          ) === true ? (
                                            <img src={m.message} />
                                          ) : (
                                            <img
                                              src={
                                                "https://stubies.s3.us-east-2.amazonaws.com/" +
                                                m.message
                                              }
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        m.sender === "0"
                                          ? "chatinnertypingboxtime"
                                          : "chatinnertypingboxtime customchattimesetting"
                                      }
                                    >
                                      <h5>
                                        {" "}
                                        {/* {m.time} */}
                                        {moment(m.time).format("LT")}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="chatboxtypingmessagelastfield">
                      {/* <form className="customformchatpage"> */}
                      <div className="chatboxbrowsefile">
                        <div className="col-md-12">
                          <input
                            id="uplaodmedial"
                            type="file"
                            className="form-control custominputtypeloginpagesetting"
                            onChange={(e) => handleRoomAdd(e)}
                            //name="images"
                            //multiple
                          />
                          <label
                            className="uploadmediabtn"
                            htmlFor="uplaodmedial"
                          >
                            {" "}
                            <img src="images/browsefile.png" />
                          </label>
                        </div>
                        {/* <a>
                            <img src="images/browsefile.png" />
                          </a> */}
                      </div>
                      <input
                        className="form-control custominputtypeloginpagesetting customtypeyourmessagechat"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type in your messages..."
                        name="msgText"
                        value={msgText}
                        onKeyPress={msgText.length !== 0 ? onKeyPress : ""}
                        onChange={(e) => setMsgText(e.target.value)}
                      />
                      <div className="chatboxsendbtn">
                        {msgText.length !== 0 ? (
                          <a
                            onClick={() => {
                              sendMessage(msgText, "text");

                              if (messageEl) {
                                messageEl.current.addEventListener(
                                  "DOMNodeInserted",
                                  (event) => {
                                    const { currentTarget: target } = event;
                                    target.scroll({
                                      top: target.scrollHeight,
                                      behavior: "smooth",
                                    });
                                  }
                                );
                              }
                            }}
                          >
                            <img src="images/chatsendbtn.png" />
                          </a>
                        ) : (
                          <a>
                            <img src="images/chatsendbtn.png" />
                          </a>
                        )}
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Message;
