import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
// Import Swiper styles
import "swiper/css";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";
import EditRoom from "./EditRoom";
import { RoomDetailApi } from "../Apis";

function RoomProfile() {
  const [roomData, setRoomData] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [images, setImages] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isloading, setisloading] = useState(false);

  let location = useLocation();

  const setLoading = (loading) => {
    setisloading(loading);
  };
  const RoomDetails = () => {
    let roomId = location.state.roomid;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: roomId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(RoomDetailApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setAllData(result);
        setRoomData(result.data);
        setAmenities(result.amenities);
        setEquipments(result.equipments);
        setAddOns(result.addons);
        setImages(result.images);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const onpress = (data) => {
    if (data) {
      RoomDetails();
    }
  };
  useEffect(() => {
    RoomDetails();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="stubiecustomerroomprofileslide ">
        <div className="container-fluid">
          {images.length === 1 || images.length === 2 ? (
            <div className="row">
              <div className="sliderone-profile-images">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1000: {
                      slidesPerView: 3,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  //centeredSlides={true}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {images.map((item) => (
                    <SwiperSlide className="sliderroomcenter">
                      <div className="col-md-12 customroomslider">
                        <div className="roomprofilesliderinnerbox">
                          <img
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              item.url
                            }
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="slidertwo-profile-images">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1000: {
                      slidesPerView: 3,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  //centeredSlides={true}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {images.map((item) => (
                    <SwiperSlide className="sliderroomcenter">
                      <div className="col-md-12 customroomslider">
                        <div className="roomprofilesliderinnerbox">
                          <img
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              item.url
                            }
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="roomprofilestubiestudiomainsection cusroomdetail-newcls">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="roomprofilestudioleftbox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox">
                      <div className="roomprofilestudiostarreviewbox d-flex">
                        <h3>{roomData.room_name}</h3>
                      </div>
                      <h5>Description</h5>
                      <p>{roomData.description}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox">
                      <h4>Facility</h4>
                      <div className="facilitydetailinfobox d-flex">
                        <div className="Capacityboxinnerleft">
                          <h5>Capacity</h5>
                        </div>
                        <div className="Capacityboxinnerright">
                          <h6>{roomData.capacity} Guest</h6>
                        </div>
                      </div>
                      <div className="facilitydetailinfobox d-flex">
                        <div className="Capacityboxinnerleft">
                          <h5>Price</h5>
                        </div>
                        <div className="Capacityboxinnerright">
                          <h6>${roomData.price_per_hour}/per hour</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox">
                      <h4>Equipment Provided</h4>
                      <ul>
                        {equipments.map((item) => (
                          <li>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox custombottomspace mb-3">
                      <h4>Amenities</h4>
                      <ul>
                        {amenities.map((item) => (
                          <li>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {addOns.length !== 0 ? (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="roomprofileinnerstudiobox custombottomspace mb-0">
                            <h4>Services</h4>
                          </div>
                        </div>
                        {addOns.map((item) => (
                          <>
                            <div className="col-md-6">
                              <div className="roomprofileinnerstudiobox custombottomspace mb-0">
                                <div className="rooms-services">
                                  <div className="rooms-media-img">
                                    {item.image === "" ? null : (
                                      <img
                                        className="mr-3 imgwidth-room-addon"
                                        src={
                                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                                          item.image
                                        }
                                        // src="images/roomsimg.jpg"
                                        alt="Room Image"
                                      />
                                    )}
                                  </div>
                                  <div className="media-body">
                                    <h5 className="mt-0">{item.name}</h5>
                                    <p>{item.description}</p>
                                    <h6>
                                      <img
                                        className="pricetagim"
                                        src="images/pricetag.png"
                                        alt=""
                                      />
                                      ${item.price}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}
                  <div className="col-md-12">
                    <div className="editroombocbtn">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#editroommodal"
                        href="#"
                      >
                        Edit Room
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div
        className="modal fade opacity-animate"
        id="stubiecustomerreviewsmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reviews</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="reviewcontainer">
                  <div className="media reviewbox d-flex">
                    <div className="reviewbox-user-img">
                      <img
                        className="mr-3"
                        src="images/review-user.jpg"
                        alt="Room Image"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">Peter Ethan</h5>
                      <div className="inforeviestar d-flex reviewboxstar">
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/blankstar.png" />
                      </div>
                      <p>Lorem Ipsum is simply dummy text of the printing.</p>
                      <h6 className="replybtn">Reply</h6>
                    </div>
                    <h4 className="comtdate">03/28/22</h4>
                  </div>
                  <div className="media reviewbox d-flex">
                    <div className="reviewbox-user-img">
                      <img
                        className="mr-3"
                        src="images/review-user.jpg"
                        alt="Room Image"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">Peter Ethan</h5>
                      <div className="inforeviestar d-flex reviewboxstar">
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/blankstar.png" />
                      </div>
                      <p>Lorem Ipsum is simply dummy text of the printing.</p>
                      <h6 className="replybtn">Reply</h6>
                    </div>
                    <h4 className="comtdate">03/28/22</h4>
                  </div>
                  <div className="media reviewbox d-flex">
                    <div className="reviewbox-user-img">
                      <img
                        className="mr-3"
                        src="images/review-user.jpg"
                        alt="Room Image"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">Peter Ethan</h5>
                      <div className="inforeviestar d-flex reviewboxstar">
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/fillstar.png" />
                        <img src="images/blankstar.png" />
                      </div>
                      <p>Lorem Ipsum is simply dummy text of the printing.</p>
                      <h6 className="replybtn">Reply</h6>
                    </div>
                    <h4 className="comtdate">03/28/22</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal opacity-animate fade"
        id="editroommodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-addrooms">
          <div className="modal-content">
            {isloading === false ? (
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Room
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            ) : null}
            {allData.length !== 0 ? (
              <EditRoom
                roomdata={allData}
                onpress={onpress}
                loading={setLoading}
              ></EditRoom>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default RoomProfile;
