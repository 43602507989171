import React, { useEffect, useState } from "react";
import {
  CityListApi,
  CustomerDetailsApi,
  customerStudioListApi,
  CustomerSummaryApi,
  GetCityListApi,
  getstudioType,
  SearchApi,
} from "../Apis";
import moment from "moment";
import CusSettings from "./CusSettings";
import axios from "axios";
import CustomerProfile from "./CustomerProfile";
import Footer from "./Footer";
import Header from "./Header";
import Studios from "./Studios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "../Loader/Loader";
import { Modal } from "bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CusCancelledBooking from "./CusCancelledBooking";
import CompletedBookings from "./CompletedBookings";
import MultiRangeSlider from "multi-range-slider-react";
import CusMessages from "./CusMessages";
import ReactSlider from "react-slider";
import Swal from "sweetalert2";

function Customer() {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [custProfile, setCustProfile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [street, setStreet] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country_code, setCountrycode] = useState("");
  const [lng, setLng] = useState("");
  const [miles, setMiles] = useState(0);
  const [UserId, setUserId] = useState("");
  const [studioType, setStudioType] = useState([]);
  const [studio_type1, setStudio_type] = useState("");
  const [drop, showDrop] = useState(false);
  const [error, setError] = useState({});
  const [pending, showPending] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [studioList, setStudioList] = useState([]);
  const [citySearch, setCitySearch] = useState("");
  const [minValue, set_minValue] = useState("");
  const [maxValue, set_maxValue] = useState("");
  const [showBooking, setShowBooking] = useState(1);
  const [StudioDetails, setStudioDetails] = useState("");
  const [studioName, setStudioName] = useState("");
  const [country, setCountry] = useState([]);
  //const [studioList, setStudioList] = useState([]);
  const [lat, setLat] = useState("");
  const [lan, setLan] = useState("");
  const [AddStudioList, setAddStudioList] = useState([]);
  const [value, setValue] = useState(100);
  const [msgState, setMessageState] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [roomData, setRoomData] = useState([]);
  const [addON, setAddON] = useState([]);
  const [Start, setStart] = useState("");
  const [End, setEnd] = useState("");
  const [Hours, setHours] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [tax, setTax] = useState([]);
  const [servicefee, setServicefee] = useState([]);
  const [date1, setDate1] = useState("");
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };
  //let navigate = useNavigate();
  let location = useLocation();
  const pull_data1 = (state) => {
    setMessageState(state);
  };
  const filterValidation = () => {
    let error = {};
    if (!studio_type1) {
      error.studio_type1 = "select studio type.";
    }
    // console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      var myModalEl = document.getElementById("fliter");
      var modal = Modal.getInstance(myModalEl);
      modal.hide();

      SearchStudio();
    }
    return error;
  };

  const customerDetail = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      devicetype: "abc",
      devicetoken: "abc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result?.data?.profile_pic !== "") {
          setCustProfile(result?.data?.profile_pic);
          localStorage.setItem("profilepic", result?.data?.profile_pic);
        }
        setFirstName(result.data.firstname);
        localStorage.setItem("profilename", result?.data?.firstname);
        setLastName(result.data.lastname);
        setPhoneNo(result.data.lastname);
        setUserId(result.data.userid);
        // if(location?.state?.pending){
        //   showPending(1)
        // }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleChange1 = (address) => {
    // console.log(address);
    setAddress(address);
  };

  const handleSelect = (address, name) => {
    // console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        // console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };
        setCity(addressObject.city);
        setState(addressObject.state);
        setAddress(address);
        setZipcode(addressObject.zipcode);
        setStreet(addressObject.street);
        setCountrycode(addressObject.cn);
        getLatLng(results[0]).then((latLng) => {
          setLat(latLng.lat);
          setLng(latLng.lng);
          // console.log("Success", latLng);

          SearchStudio(latLng.lat, latLng.lng, addressObject.city);
        });
        // console.log("adddd", address);
      })

      .catch((error) => console.error("Error", error));
  };

  const studioTypeApi = () => {
    var config = {
      method: "get",
      url: getstudioType,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        const items = response.data.studioType.map((item) => {
          return { ...item, selected: false };
        });

        setStudioType(items);
        // if (location?.state?.pending) {
        //   showPending(3);
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const pull_data = (data) => {
    setCustProfile(data);
  };
  const handleChange = (data) => {
    const index = studioType.findIndex((n) => n.id === data.id);
    studioType[index].selected = !studioType[index].selected;
    setStudioType([...studioType]);

    setStudio_type(
      studioType
        .filter((n) => n.selected === true)
        .map((n) => n.studio_type)
        .toString()
    );
    setError("");
  };

  const SearchStudio = (lat, lng, city) => {
    setIsLoading(true);
    //console.log(maxValue);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customerid: local,
      distance: Number(value),
      lat: String(lat),
      lng: String(lng),
      price_from: minValue !== 0 ? String(minValue) : "",
      price_to: maxValue !== 0 ? String(maxValue) : "",
      studiotype: studio_type1 ? studio_type1 : "",
      citysearch: city,
    });
    // console.log("ssstst", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SearchApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("ffggf", result);
        if (result.error === "0") {
          setData(result.data);
          setIsLoading(false);
          //Swal.fire(result.message)
        } else if (result.error === "1") {
          setIsLoading(false);
          // Swal.fire(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };
  const SearchStudio1 = () => {
    setIsLoading(true);
    //console.log(maxValue);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customerid: local,
      distance: Number(value),
      lat: String(lat),
      lng: String(lan),
      price_from: minValue !== 0 ? String(minValue) : "0",
      price_to: maxValue !== 0 ? String(maxValue) : "",
      studiotype: studio_type1,
      citysearch: city,
    });
    // console.log("ssstst", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SearchApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("ffggf", result);
        if (result.error === "0") {
          setData(result.data);
          setIsLoading(false);
          //Swal.fire(result.message)
        } else if (result.error === "1") {
          setIsLoading(false);
          // Swal.fire(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const ApplyFilter = () => {
    //setError(filterValidation());
    // SearchStudio();
    SearchStudio1();
    var myModalEl = document.getElementById("fliter");
    var modal = Modal.getInstance(myModalEl);
    modal.hide();
  };

  const BookingSummary = (n) => {
    setLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pageno: 1,
      status: "4",
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerSummaryApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          if (n !== undefined) {
            let filData = result.data.filter((n, i) => i < 2);
            setViewData(filData);
            setLoading(false);
          } else {
            setViewData(result.data);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const View = () => {
    BookingSummary();
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((json) => {
        setCountry(json);
        setLat(json.lat);
        setLan(json.lon);
        setLng(json.lon);
        //SearchStudio(json.lat, json.lon);
        //data(json.lat, json.lon);
      });

    if (location?.state?.pending === 3) {
      showPending(3);
    }

    if (location?.state?.data) {
      setData(location?.state?.data);
    }
    if (location?.state?.pending === 0) {
      showPending(0);
    }
    if (location?.state?.pending === 2) {
      showPending(2);
    }
    if (location?.state?.pending === 1) {
      showPending(1);
    }
    if (location?.state?.showBooking === 3) {
      setShowBooking(3);
    }
    if (location?.state?.pending === 4) {
      showPending(4);
    }
    if (location?.state?.roomData) {
      setRoomData(location?.state?.roomData);
    }
    let Detail = location?.state?.Result;
    let Detail1 = location?.state?.Dates;
    let Detail4 = location?.state?.AddOn;
    if (location?.state?.AddOn) {
      setAddON(location?.state?.AddOn);
    }
    //console.log(Detail);
    // setAddON(Detail4);
    let roomdata = location?.state?.RoomDetails;
    let Detail2 = location?.state?.Start;
    let Detail3 = location?.state?.End;
    let hours = location?.state?.Hours;
    //let total = location.state.totalPrice;
    if (location?.state?.totalPrice) {
      setTotalPrice(location?.state?.totalPrice);
    }
    //console.log("totalprice", location?.state?.totalPrice);

    setDate1(location?.state?.date1);
    // console.log("dadada", location?.state?.date1);

    setStart(Detail2);
    setEnd(Detail3);
    setHours(hours);
    setServicefee(location?.state?.servicefee);
    // console.log("gghgh", location?.state?.servicefee);
    setTax(location?.state?.tax);
    setIsLoading(true);
    BookingSummary("filter");
    customerDetail();
    studioTypeApi();
    window.scrollTo(0, 0);
    // GetCityList();
  }, []);
  const pull_data4 = () => {
    showPending(2);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header
        image={
          custProfile
            ? `https://stubies.s3.us-east-2.amazonaws.com/` + custProfile
            : "images/profiledefault.jpg"
        }
        //name={firstname}
      />

      <div className="stubieprofilebannersection stubiecustomerbannersectionsetting">
        <div className="container-fluid">
          {pending === 0 ? (
            <>
              <h1 className="changepasswordmainheadingsetting">
                Modern Studio’s
              </h1>
            </>
          ) : null}
          {pending === 1 ? (
            <>
              <h1 className="changepasswordmainheadingsetting">My Profile</h1>
            </>
          ) : null}
          {pending === 2 ? (
            <>
              <h1 className="changepasswordmainheadingsetting">Bookings</h1>
            </>
          ) : null}
          {pending === 3 ? (
            <>
              <h1 className="changepasswordmainheadingsetting">Messages</h1>
            </>
          ) : null}
          {pending === 4 ? (
            <>
              <h1 className="changepasswordmainheadingsetting">Settings</h1>
            </>
          ) : null}
          {pending === 0 ? (
            <div className="stubiecustomerformsetting">
              <img
                //onClick={() => SearchStudio()}
                src="images/stubiecustomerserachimg.png"
                className="stubiecustomerserachimgsetting"
              />
              <PlacesAutocomplete
                value={address}
                onChange={handleChange1}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      name="address"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          SearchStudio();
                        }
                      }}
                      //value={street1}
                      {...getInputProps({
                        placeholder: "Which city are you in?",
                        className: "form-control customserachbarstubiecustmoer",
                        list: "datalistOptions",
                        id: "exampleDataList",
                      })}
                    />

                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span onClick={() => SearchStudio()}>
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <a
                href="#"
                className="stubiecustomerfliterpopupsetting"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#fliter"
              >
                <img
                  onClick={() => setStudio_type("")}
                  src="images/stubiecustomerfliterimg.png"
                  className="stubiecustomerfliterimgsetting"
                />
              </a>
            </div>
          ) : null}
        </div>
      </div>

      <div className="mystudiotabmainsection">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="mystudiotabinnersection">
                <ul
                  className="nav nav-tabs customstudiotabbar customstudiocustmoertabssetting"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        showPending(0);
                        handleScrollToTop();
                      }}
                      // className="nav-link   customtabbtnsetting  active"
                      className={`nav-link   customtabbtnsetting ${
                        pending === 0 && "active"
                      }`}
                      //id="Studio’s-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Studio’s"
                      type="button"
                      role="tab"
                      aria-controls="Studio’s"
                      aria-selected="true"
                    >
                      Studio’s
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        showPending(2);
                        handleScrollToTop();
                      }}
                      //className="nav-link   customtabbtnsetting"
                      className={`nav-link   customtabbtnsetting ${
                        pending === 2 && "active"
                      }`}
                      //id="Bookings-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Bookings"
                      type="button"
                      role="tab"
                      aria-controls="Bookings"
                      aria-selected="true"
                    >
                      Bookings
                    </button>
                  </li>

                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        showPending(3);
                        handleScrollToTop();
                      }}
                      //className="nav-link   customtabbtnsetting"
                      className={`nav-link   customtabbtnsetting ${
                        pending === 3 && "active"
                      }`}
                      //id="messages-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#messages"
                      type="button"
                      role="tab"
                      aria-controls="messages"
                      aria-selected="true"
                    >
                      Messages
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        showPending(1);
                        handleScrollToTop();
                      }}
                      //className="nav-link   customtabbtnsetting"
                      className={`nav-link   customtabbtnsetting ${
                        pending === 1 && "active"
                      }`}
                      //id="MyProfile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#MyProfile"
                      type="button"
                      role="tab"
                      aria-controls=" MyProfile"
                      aria-selected="true"
                    >
                      My Profile
                    </button>
                  </li>
                  <li className="nav-item custommystudioli" role="presentation">
                    <button
                      onClick={() => {
                        showPending(4);
                        handleScrollToTop();
                      }}
                      //className="nav-link   customtabbtnsetting "
                      className={`nav-link   customtabbtnsetting ${
                        pending === 4 && "active"
                      }`}
                      //id="settings-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#settings"
                      type="button"
                      role="tab"
                      aria-controls=" settings"
                      aria-selected="true"
                    >
                      Settings
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    //className="tab-pane fade show active"
                    className={`tab-pane fade show ${
                      pending === 0 && "active"
                    }`}
                    //id="Studio’s"
                    role="tabpanel"
                    aria-labelledby="Studio’s-tab"
                  >
                    <Studios
                      data={data}
                      viewData={viewData}

                      // studioList1={studioList}
                    />
                  </div>
                  <div
                    //className="tab-pane fade show "
                    className={`tab-pane fade show ${
                      pending === 2 && "active"
                    }`}
                    //id="Bookings"
                    role="tabpanel"
                    aria-labelledby="Bookings-tab"
                  >
                    <div className="mainsettingsectiontop">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3 custommainbookinwidth">
                            <div className="settinglinksbuttonleftbox">
                              <ul className="settinglinksulbtnsetting">
                                {showBooking === 1 ? (
                                  <li
                                    className="settinglinkslibtnsetting active"
                                    onClick={() => setShowBooking(1)}
                                  >
                                    {" "}
                                    Upcoming Bookings
                                  </li>
                                ) : (
                                  <li
                                    className="settinglinkslibtnsetting"
                                    onClick={() => setShowBooking(1)}
                                  >
                                    {" "}
                                    Upcoming Bookings
                                  </li>
                                )}
                                {showBooking === 2 ? (
                                  <li
                                    className="settinglinkslibtnsetting active"
                                    onClick={() => setShowBooking(2)}
                                  >
                                    {" "}
                                    Completed Bookings
                                  </li>
                                ) : (
                                  <li
                                    className="settinglinkslibtnsetting"
                                    onClick={() => setShowBooking(2)}
                                  >
                                    {" "}
                                    Completed Bookings
                                  </li>
                                )}
                                {showBooking === 3 ? (
                                  <li
                                    className="settinglinkslibtnsetting active"
                                    onClick={() => setShowBooking(3)}
                                  >
                                    {" "}
                                    Cancelled Bookings
                                  </li>
                                ) : (
                                  <li
                                    className="settinglinkslibtnsetting"
                                    onClick={() => setShowBooking(3)}
                                  >
                                    {" "}
                                    Cancelled Bookings
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                          {showBooking === 1 ? (
                            <>
                              <div className="col-md-9 custommainbookinwidth">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <h4 className="settingeditstudioprofileheading  custompastbookingheading">
                                        Upcoming Bookings
                                      </h4>
                                    </div>
                                    <div className="text-right">
                                      <button
                                        className="savebtnsetting"
                                        onClick={() => View()}
                                      >
                                        View All
                                      </button>
                                    </div>
                                    {loading ? (
                                      <>
                                        <div class="col-md-6">
                                          <p>Loading....</p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {viewData.length !== 0 ? (
                                          <>
                                            {viewData.map((n) => (
                                              <>
                                                <div className="col-md-6 customwidthbooking">
                                                  <div className="pastbookinginfobox">
                                                    <h3 className="pastbookinginfotextheading">
                                                      {n.customer_name}
                                                    </h3>
                                                    <h6 className="pastbookinginfotextstudioheading">
                                                      {n.studio_name}
                                                    </h6>
                                                    <p className="pastbookinginfotextstudioabout">
                                                      You have a new booking
                                                      request for {n.room_name}
                                                    </p>
                                                    <div className="pastbookinginnertimebox">
                                                      <h5 className="pastbookingtimeheadingtext">
                                                        <img
                                                          src="images/pastbookingstartimg.png"
                                                          className="pastbookingstartendiconimg "
                                                        />{" "}
                                                        {moment(n.start_time, [
                                                          "HH.mm",
                                                        ]).format(
                                                          "hh:mm a"
                                                        )}{" "}
                                                        <span>
                                                          {moment(
                                                            n.date,
                                                            "DD/MM/YYYY"
                                                          ).format(
                                                            "MM-DD-YYYY"
                                                          )}
                                                          {/* {n.date} */}
                                                        </span>
                                                      </h5>
                                                      <h5 className="pastbookingtimeheadingtext">
                                                        <img
                                                          src="images/pastbookingendimg.png"
                                                          className="pastbookingstartendiconimg "
                                                        />
                                                        {moment(n.end_time, [
                                                          "HH.mm",
                                                        ]).format(
                                                          "hh:mm a"
                                                        )}{" "}
                                                        <span>
                                                          {moment(
                                                            n.date,
                                                            "DD/MM/YYYY"
                                                          ).format(
                                                            "MM-DD-YYYY"
                                                          )}
                                                        </span>
                                                      </h5>
                                                      <Link
                                                        to={`/cusbookingdetail`}
                                                        state={{
                                                          data: n,
                                                          name: "upcoming",
                                                        }}
                                                        className="pastbookinginnerseedetailbtn"
                                                      >
                                                        <img
                                                          src="images/seestudioicon.png"
                                                          className="pastbookingseedetailimg"
                                                        />{" "}
                                                        See Detail
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            <div>
                                              <p>No Bookings</p>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {showBooking === 3 ? (
                            <>
                              <CusCancelledBooking />
                            </>
                          ) : null}
                          {showBooking === 2 ? (
                            <>
                              <CompletedBookings />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    //className="tab-pane fade show "
                    className={`tab-pane fade show ${
                      pending === 3 && "active"
                    }`}
                    //id="messages"
                    role="tabpanel"
                    aria-labelledby="messages-tab"
                  >
                    <CusMessages
                      id={UserId}
                      name={firstName}
                      studioDetail={StudioDetails}
                      studio={studioName}
                      image={
                        custProfile
                          ? `https://stubies.s3.us-east-2.amazonaws.com/` +
                            custProfile
                          : "https://stubies.s3.us-east-2.amazonaws.com/b731c60c-c7de-46cd-87fc-a51e3baf3afd/StudioPic/1683117465693"
                      }
                      // location={location?.state}
                      location={msgState === false ? location?.state : ""}
                      func={pull_data1}
                    />
                  </div>

                  <div
                    //className="tab-pane fade show "
                    className={`tab-pane fade show ${
                      pending === 1 && "active"
                    }`}
                    //id="MyProfile"
                    role="tabpanel"
                    aria-labelledby="MyProfile-tab"
                  >
                    <CustomerProfile func={pull_data} />
                  </div>
                  <div
                    //className="tab-pane fade show  "
                    className={`tab-pane fade show ${
                      pending === 4 && "active"
                    }`}
                    //id="settings"
                    role="tabpanel"
                    aria-labelledby="settings-tab"
                  >
                    <CusSettings
                      roomData={roomData}
                      totalPrice={totalPrice}
                      tax={tax}
                      servicefee={servicefee}
                      Start={Start}
                      Hours={Hours}
                      End={End}
                      addON={addON}
                      date1={date1}
                      fun={pull_data4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div
        className="modal fade opacity-animate"
        id="fliter"
        tabIndex="-1"
        aria-labelledby="#fliter"
        aria-hidden="true"
      >
        <div className="modal-dialog filter-diolage">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title customfilterpopupheadingchanges">
                Filter
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="stubiecustomerfliterpopupheading">Studio Type</h4>
              <button
                className="form-select custominputtypeloginpagesetting"
                aria-label="Default select example"
                onClick={() => showDrop(true)}
                name="studio_type1"
                value={studio_type1}
                // multiple
              >
                {studio_type1 ? studio_type1 : "Select Studio Type"}
              </button>

              <div className="row">
                <div className="col-md-12 customstudiotypewidth">
                  <div className="col-md-12">
                    <div class="addbtnfilter-new">
                      {studioType !== undefined > 0 ? (
                        <>
                          {drop === true ? (
                            <>
                              {studioType.map((item, key) => (
                                <button
                                  onClick={() => handleChange(item)}
                                  key={key}
                                  value={item.studio_type}
                                  className="studiooption"
                                >
                                  {item.selected === true ? (
                                    <img
                                      style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: "contain",
                                      }}
                                      src="images/tickimg.png"
                                    />
                                  ) : null}

                                  <button>{item.studio_type}</button>
                                </button>
                              ))}
                              <div className="w-100 d-block">
                                <button
                                  className="btnsaveoption"
                                  onClick={() => showDrop(false)}
                                >
                                  Done
                                </button>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                    <span className="customvalidationdesignlogin">
                      {error.studio_type1}
                    </span>
                  </div>
                </div>
              </div>

              <div className="custommilesradiusbox">
                <h5 className="customflitermilesheadingsetting">
                  Miles radius (up to 100 miles)
                </h5>

                <ReactSlider
                  ariaLabelledby="slider-label"
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  onChange={(value, index) => {
                    setValue(value);
                  }}
                  value={value}
                  renderThumb={(props, state) => (
                    <div {...props}>{state.valueNow}</div>
                  )}
                />

                <ul className="custommileulsettung">
                  <li className="custommiletextprogressbar">{value}</li>
                </ul>
              </div>
              <div className="custompricerangebox">
                <h5 className="customflitermilesheadingsetting">Price Range</h5>
                <MultiRangeSlider
                  id="customRange2"
                  className="form-range"
                  min={0}
                  max={100}
                  step={5}
                  ruler={false}
                  minValue={minValue}
                  maxValue={maxValue}
                  stepOnly={true}
                  label={false}
                  //minCaption={minValue}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
                <ul className="custommileulsettung">
                  <li className="custommiletextprogressbar">
                    ${minValue}-${maxValue}
                  </li>
                </ul>
              </div>
            </div>

            <div className="modal-footer">
              <div className="fliterpopupbottomapplybtnbox">
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        let studio = studioType.map(
                          (item) => (item.selected = false)
                        );
                        setStudio_type(studio);
                        // setStudio_type("");
                        setMiles("");
                        set_minValue("");
                        set_maxValue("");
                        setValue("");
                      }}
                      type="button"
                      className="btn btn-primary  customfliterclearbtnsetting"
                    >
                      Clear
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => ApplyFilter()}
                      className="btn btn-primary customfliterapplybtnsetting"
                    >
                      Apply
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Customer;
