import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
// Import Swiper styles
import "swiper/css";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";
import { CustomerAvailabilityApi, CustomerRoomDetailApi } from "../Apis";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Calendar from "react-calendar";
import Swal from "sweetalert2";

function CusRoomDetail() {
  const [roomData, setRoomData] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [images, setImages] = useState([]);
  const [dates, setDates] = useState(new Date());
  const [Data, setData] = useState([]);
  const [slots, setSlots] = useState([]);
  const [tax, setTax] = useState([]);
  const [servicefee, setServicefee] = useState([]);
  const [status, setStatus] = useState(false);
  const [addservice, setAddService] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [result, setResult] = useState([]);
  const [blackOutDays, setBlackOutDays] = useState([]);
  const [addOnAdd, setAddOnAdd] = useState([]);
  const [StudioId, setStudioId] = useState("");
  const [studioDetails, setStudioDetails] = useState([]);
  const [Hours, setHours] = useState("");
  let location = useLocation();
  let navigate = useNavigate();

  const RoomDetails = () => {
    let roomId = location.state.roomid;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: roomId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerRoomDetailApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setRoomData(result.data);
        setEquipments(result.equipments);
        setAmenities(result.amenities);
        setAddOns(result.addons);
        setImages(result.images);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Availability = (date) => {
    let local = localStorage.getItem("userid");
    let roomId = location.state.roomid;
    let studioid = location?.state?.studioId;

    let d = moment(date).format("DD/MM/YYYY");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customer_id: local,
      date: d,
      room_id: roomId,
      studio_id: studioid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("rrrr", raw);

    fetch(CustomerAvailabilityApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("dddddddd", result);
        setResult(result);
        if (result.error === "0") {
          setData(result.data);
          let array = result.total_Slots;
          let array2 = array.filter((slots) => !result.data.includes(slots));
          //console.log("dddd", array2);
          let array3 = array2.map((n) => ({ state: false, value: n }));
          let array4 = [...array3];
          setSlots(array4);
          //console.log("gdgd", array4);
          setServicefee(result);
          setTax(result);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const checkout = () => {
    if (startTime && endTime) {
      navigate("/cusbooking", {
        state: {
          data: result,
          RoomDetail: roomData,
          dat: dates,
          start: startTime,
          end: endTime,
          addOn: addOnAdd,
          Hours: Hours,
          studioDetails: studioDetails,
          addonPrice: addOns.price,
        },
      });
    } else if (!startTime) {
      Swal.fire("Select Start Time");
    } else if (!endTime) {
      Swal.fire("Select End Time");
    }
  };

  useEffect(() => {
    if (location?.state?.studioDetail) {
      setStudioDetails(location?.state?.studioDetail);
    }
    // if (location?.state?.blackoutDays) {
    //   setBlackOutDays(location?.state?.blackoutDays);
    // }
    let BD = location?.state?.blackoutDays?.map(
      (i) => new Date(moment(i.day).format("YYYY-MM-DD"))
    );

    setBlackOutDays([...BD]);
    console.log("bbbb", [...BD]);
    RoomDetails();
    Availability(new Date());
    window.scrollTo(0, 0);
  }, []);

  const isDateDisabled = (date) => {
    return blackOutDays.some(
      (disabledDate) =>
        date.getDate() === disabledDate.getDate() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getFullYear() === disabledDate.getFullYear()
    );
  };

  return (
    <>
      <Header />
      <div className="stubiecustomerroomprofileslide">
        <Link
          className="backlink"
          to={`/cusstudiodetail`}
          state={{
            studioid: roomData.studio_id,
            userId: studioDetails.userid,
          }}
        >
          <img src="images/backbtn1.png" />
        </Link>
        <div className="container-fluid">
          {images.length === 1 || images.length === 2 ? (
            <div className="row">
              <div className="sliderone-profile-images">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1000: {
                      slidesPerView: 3,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {images.map((item) => (
                    <SwiperSlide>
                      <div className="col-md-12 customroomslider">
                        <div className="roomprofilesliderinnerbox">
                          <img
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              item.url
                            }
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="slidertwo-profile-images">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1000: {
                      slidesPerView: 3,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {images.map((item) => (
                    <SwiperSlide>
                      <div className="col-md-12 customroomslider">
                        <div className="roomprofilesliderinnerbox">
                          <img
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              item.url
                            }
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="roomprofilestubiestudiomainsection cusroomdetail-newcls ">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="roomprofilestudioleftbox cusroomdetail-newcls">
                <div className="row">
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox">
                      <div className="roomprofilestudiostarreviewbox d-flex">
                        <h3>{roomData.room_name}</h3>
                      </div>
                      <h5>Description</h5>
                      <p>{roomData.description}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox">
                      <h4>Facility</h4>
                      <div className="facilitydetailinfobox d-flex">
                        <div className="Capacityboxinnerleft">
                          <h5>Capacity</h5>
                        </div>
                        <div className="Capacityboxinnerright">
                          <h6>{roomData.capacity} Guest</h6>
                        </div>
                      </div>
                      <div className="facilitydetailinfobox d-flex">
                        <div className="Capacityboxinnerleft">
                          <h5>Price</h5>
                        </div>
                        <div className="Capacityboxinnerright">
                          <h6>
                            ${Number(roomData.price_per_hour).toFixed(2)}/per
                            hour
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox">
                      <h4>Equipment Provided</h4>
                      <ul>
                        {equipments.map((item) => (
                          <li>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="roomprofileinnerstudiobox custombottomspace mb-0">
                      <h4>Amenities</h4>
                      <ul>
                        {amenities.map((item) => (
                          <li>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {addOns.length !== 0 ? (
                    <>
                      <div className="col-md-12">
                        <div className="roomprofileinnerstudiobox custombottomspace mb-0">
                          <h4>Services</h4>
                          <div>
                            <button
                              onClick={() => setAddService(true)}
                              className="btnaddservice-room mt-3"
                            >
                              Add
                            </button>
                          </div>
                          {addservice ? (
                            <>
                              {addOns.map((item) => (
                                <>
                                  <div className="rooms-media-img">
                                    <img
                                      className="mr-3"
                                      src={
                                        `https://stubies.s3.us-east-2.amazonaws.com/` +
                                        item.image
                                      }
                                      // src="images/roomsimg.jpg"
                                      alt="Room Image"
                                    />
                                  </div>
                                  <div className="media-body">
                                    <h5 className="mt-0">{item.name}</h5>
                                    <p>{item.description}</p>
                                    <h6>
                                      <img
                                        className="pricetagim"
                                        src="images/pricetag.png"
                                        alt=""
                                      />
                                      ${item.price}
                                    </h6>
                                  </div>
                                  <button
                                    type="button"
                                    className="btnaddservice-room mt-3"
                                    onClick={() => {
                                      let AddId = addOns.map((n) => n);
                                      setAddOnAdd(AddId);
                                      //console.log("addd", AddId);
                                      Swal.fire("AddOn is Added");
                                    }}
                                  >
                                    Add
                                  </button>
                                  <button
                                    onClick={() => setAddService(false)}
                                    type="button"
                                    className="btnaddservice-room mt-3"
                                  >
                                    Remove
                                  </button>
                                </>
                              ))}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-5 stubiestudioroomprofilecustomwidth">
              <div className="roomprofilestudiorightbox roominfo-timeslot">
                <div className="row">
                  <div className="col-md-12">
                    <div className="customslotinputtypeboxsetting">
                      <Calendar
                        minDate={new Date()}
                        maxDate={
                          new Date(moment().add(12, "M").format("YYYY-MM-DD"))
                        }
                        onChange={(date) => {
                          Availability(date);
                          setDates(date);
                          setStatus(false);
                        }}
                        tileDisabled={({ date }) => isDateDisabled(date)}
                      />
                    </div>
                    <div className="roomprofileslotbtnright">
                      <ul>
                        <li>
                          {status === false ? (
                            <>
                              <a
                                onClick={() => setStatus(false)}
                                className="active"
                              >
                                Start Slot
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                onClick={() => setStatus(false)}
                                // className="active"
                              >
                                Start Slot
                              </a>
                            </>
                          )}
                        </li>
                        <li>
                          {status === true ? (
                            <>
                              <a className="active">End Slot</a>
                            </>
                          ) : (
                            <>
                              <a
                                //onClick={() => setStatus(false)}
                                //className="active"
                                onClick={() =>
                                  Swal.fire("Please select start time first")
                                }
                              >
                                End Slot
                              </a>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>

                    {Data.map((item, key) => (
                      <>
                        <button
                          key={key}
                          type="button"
                          className="studiooption"
                          disabled
                          style={{ color: "grey" }}
                        >
                          {moment(item, ["HH.mm"]).format("hh:mm a")}
                        </button>
                      </>
                    ))}
                    {slots.length !== 0 ? (
                      <>
                        {slots.map((item, key) => (
                          <>
                            <button
                              key={key}
                              onClick={(e) => {
                                setStatus(true);
                                let time = item.value.split(":")[0];

                                let i = slots.findIndex(
                                  (N) => N.value === item.value
                                );
                                let i2 = slots.findIndex(
                                  (N) => N.state === true
                                );
                                if (i2 !== -1) {
                                  let time2 = slots[i2].value.split(":")[0];
                                  let subtractTime =
                                    Number(time) - Number(time2);
                                  if (subtractTime >= 0) {
                                    slots.map((n) => (n.state = false));
                                    slots[i].state = !item.state;
                                    setHours(subtractTime);
                                    console.log(
                                      "total hours====>",
                                      subtractTime
                                    );

                                    slots[i2].state = true;
                                  } else {
                                    Swal.fire(
                                      "end time should be greater than start time"
                                    );
                                  }
                                } else {
                                  slots.map((n) => (n.state = false));
                                  slots[i].state = !item.state;
                                }

                                setSlots([...slots]);
                                console.log(slots);
                                let start = slots.filter(
                                  (n) => n.state === true
                                );

                                setStartTime(start[0].value);

                                console.log("start", start[0].value);
                                if (start.length === 2) {
                                  setEndTime(start[1].value);
                                  console.log("end", start[1].value);
                                }
                              }}
                              type="button"
                              className={
                                item.state === false
                                  ? "activetime btn-primary btntimeselect customnextroomprofilebtn studiooption btn-sm"
                                  : "activetime btn-primary btntimeselect customnextroomprofilebtn studiooption btn-sm btnaddservice-room mt-3"
                              }
                            >
                              {moment(item.value, ["HH.mm"]).format("hh:mm a")}
                            </button>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <p>Studio is closed</p>
                      </>
                    )}
                    {(slots.length !== 0 && Data.length === 0) ||
                    (Data.length !== 0 && endTime.length !== 0) ? (
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={() => checkout()}
                          className="btn btn-primary customsavebtnchangespopup  customnextroomprofilebtn"
                        >
                          Next
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div
        className="modal fade opacity-animate"
        id="stubiecustomerreviewsmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reviews</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>No Reviews</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CusRoomDetail;
