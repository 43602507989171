import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CustomerDetailsApi, SubscribeApi, studioDetailsApi } from "../Apis";
import Swal from "sweetalert2";
function Footer(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [detail, setDetail] = useState([]);
  const [status, setStatus] = useState("");
  const [status1, setStatus1] = useState("");
  const [role, setRole] = useState("");

  const validate = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!email) {
      error.email = "email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    }
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      Subscribe();
    }
    return error;
  };
  const Subscribe = () => {
    let local = localStorage.getItem("email");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(SubscribeApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          setDetail(result.data);
          setEmail("");
          CustomerDetail();
          StudioDetail();
          Swal.fire(result.message);
        } else {
          setEmail("");
          Swal.fire(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      Subscribed();
    }
  };
  const Subscribed = () => {
    setError(validate());
  };

  const StudioDetail = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      device_token:
        "c8M3KtwrQW6WTt0lYqRVmc:APA91bFxCJPMHtLw60SO_7vnbegVCiFhsLu1ZELKHensery-Ghckd9z9Zs7a6Vky2EyPMiGx1yF19oUEZn-mUaYlXlngiZm8i591FdkmE7vMcC1hrQdXZOhPk2vmvTo7vDHJZ9LlhhfV",
      device_type: "A",
      pageno: 0,
      studio_id: props.id,
      userid: local,
    });
    //console.log("ghgh", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(studioDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setStatus(result?.data?.sub_status);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const CustomerDetail = () => {
    let local1 = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local1,
      devicetype: "abc",
      devicetoken: "abc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setStatus1(result.status);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    let role = localStorage.getItem("role");
    setRole(role);
    if (role === "Customer") {
      CustomerDetail();
    } else {
      StudioDetail();
    }
  }, []);

  return (
    <>
      <footer>
        <div className="footertop">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="subscribetoournewslettersection">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="customsubscribeleftsection">
                        <h2>Subscribe to our newsletter</h2>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group custominputgroupfooter">
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError("");
                          }}
                          onKeyDown={handleKeyPress}
                          className="form-control custominputgroupsetting"
                          placeholder=" Enter Your Email"
                        />
                        {role === "Customer" ? (
                          <>
                            {status1 !== true ? (
                              <button
                                type="button"
                                onClick={() => Subscribed()}
                                className="btn btn-primary customsubscribebtnsettingfooter"
                              >
                                SUBSCRIBE
                              </button>
                            ) : (
                              <button
                                type="button"
                                //onClick={() => Subscribed()}
                                className="btn btn-primary customsubscribebtnsettingfooter"
                              >
                                SUBSCRIBED
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {status !== true ? (
                              <button
                                type="button"
                                onClick={() => Subscribed()}
                                className="btn btn-primary customsubscribebtnsettingfooter"
                              >
                                SUBSCRIBE
                              </button>
                            ) : (
                              <button
                                type="button"
                                //onClick={() => Subscribed()}
                                className="btn btn-primary customsubscribebtnsettingfooter"
                              >
                                SUBSCRIBED
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {error.email && (
                        <span className="customvalidationdesignlogin">
                          {error.email}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footertopmiddle">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                {/* <div className="footermiddleinnerleftsection">
                  <h4>Contact us</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <img src="images/footerlocationicon.png" /> 33 york ave.
                        city, mo 6451
                      </a>
                    </li>
                    <li>
                      <a href="tel:+123-422-3222">
                        <img src="images/footerphoneicon.png" /> +123-422-3222
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@stubie.com">
                        <img src="images/femail.png" /> info@stubie.com
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-md-4">
                <div className="footermiddleinnersection">
                  <img
                    src="images/footerlogo.png"
                    className="footerlogocustomsetting"
                  />
                  <p>The Ultimate Hub for Studio Seekers.</p>
                  <ul>
                    <li>
                      <a href="https://www.instagram.com/">
                        <img src="images/insta.png" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/">
                        <img src="images/facebook.png" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <img src="images/twitter.png" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footermiddleinnerrightsection righticonsstore">
                  <h4> Download now</h4>
                  <ul>
                    <li className="footerstoreicon">
                      <Link className="storeiconlinks" to="/">
                        <img
                          src="images/appstore.png"
                          className="footerimageiconsstore"
                        />
                        <span>APP STORE</span>
                      </Link>
                    </li>
                    <li className="footerstoreicon">
                      <Link className="storeiconlinks" to="/">
                        <img
                          src="images/playstore.png"
                          className="footerimageiconsstore"
                        />
                        <span>PLAY STORE</span>
                      </Link>
                    </li>
                    {/*<li>
                      <a>
                        <img src="images/studiocustmoerimg2.jpg" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="images/studiocustmoerimg3.jpg" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="images/studiocustmoerimg4.jpg" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="images/studiocustmoerimg5.jpg" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="images/studiocustmoerimg6.jpg" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="customcopyrightsetting">
          Copyright @2022 stubie all right reserved.
        </p>
      </footer>
    </>
  );
}
export default Footer;
