import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Persona from "persona";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper";
import Swal from "sweetalert2";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "../Loader/Loader";
import {
  addStudioApi,
  getstudioType,
  registerApi,
  uploadimageApi,
} from "../Apis";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import PhoneInput from "react-phone-number-input";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { set } from "firebase/database";

function SignUp() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    // profile: "",
    studio_name: "",
    // profile_image: "",
    description: "",
  });
  const [value, setValue] = useState();
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countryname, setCountry] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfile] = useState("");
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [imageChange1, setImageChange1] = useState(false);
  const [filetype, setFileType] = useState("");
  const [cropper, setCropper] = useState();
  const [file, setFile] = useState("");
  const [inquiryId, setInquiryId] = useState("");
  const [status, setStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [drop, showDrop] = useState(false);
  const [street, setStreet] = useState("");
  const [clicked, setClicked] = useState(false);
  const [country_code, setCountrycode] = useState("");
  const [lat, setLat] = useState("");
  const [focus, setOnFocus] = useState(false);
  const [lng, setLng] = useState("");
  const [studiotypeerr, setStudioTypeErr] = useState(false);
  const [imageerr, setImageErr] = useState(false);
  const [cityerr, setCityErr] = useState(false);
  const [zipcodeerr, setZipcodeErr] = useState(false);
  const [streeterr, setStreetErr] = useState(false);
  const [stateerr, setStateErr] = useState(false);
  const [checkboxerr, setCheckboxErr] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [studiotypes, setStudioTypes] = useState("");
  const [studio_type1, setStudio_type] = useState("");
  const [studioType, setStudioType] = useState([]);
  const [visible, setVisible] = useState(true);
  const [address1, setAddress1] = useState("");
  //const [file, setFile] = useState("");
  const [studiourl, setStudioUrl] = useState("");
  const [address, setAddress] = useState("");
  const [value1, setValue1] = useState("");
  const [userId, setUserId] = useState("");
  const [details, setDetails] = useState({});
  let navigate = useNavigate();
  let location = useLocation();
  const [options, setOptions] = useState({
    templateId: "tmpl_JAZjHuAT738Q63BdgCuEJQre",
  });

  const [flowType, setFlowType] = useState("embedded");

  const embeddedClientRef = useRef(null);

  /**
   * Creates a new instance of the Persona client. Note that if this is called
   * when the popup is closed, an entirely new inquiry will be created (as
   * opposed to if you wre to call client.open()).
   */
  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: "sandbox",
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }
        client.open();
      },
      onStart: (inquiryId) => {
        console.log(JSON.stringify(inquiryId));
      },
      onComplete: (inquiryId) => {
        console.log(JSON.stringify(inquiryId));
        setInquiryId(inquiryId.inquiryId);
        setStatus(inquiryId.status);
        console.log("ghhhhh", inquiryId);
        console.log("tttttt", inquiryId.status);
        fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const handleData1 = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError("");
  };
  const DeleteStudioImage = () => {
    setStudioUrl("");
  };
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye" ? setIcon("fa fa-eye-slash") : setIcon("fa fa-eye");
  };

  const myData1 = (e) => {
    setError(validate1(formValues, profile));
  };

  //form validations
  const validate1 = (formValues) => {
    let error = {};
    let reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexp = /^\S*$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!formValues.email) {
      error.email = "Email is required.";
    } else if (!reg.test(formValues.email)) {
      error.email = "Invalid email .";
    }
    if (!formValues.firstname) {
      error.firstname = "First Name  is required.";
    }
    if (!formValues.lastname) {
      error.lastname = "Last Name is required.";
    }
    if (!formValues.password) {
      error.password = "Password is required.";
    } else if (formValues.password.length < 6) {
      error.password = "Password is not valid.";
    } else if (!passwordRegex.test(formValues.password)) {
      error.password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }
    if (!value) {
      error.value = "Phone Number is required.";
    }
    // else if (value.length > 15) {
    //   error.value = "Phone Number  is not valid.";
    // }
    else if (value.length < 9) {
      error.value = "Phone Number  is not valid.";
    }
    if (!city) {
      error.city = "City is required.";
    }
    if (!state) {
      error.state = "State is required.";
    }
    if (address === "") {
      error.address = "Address is required.";
    }
    if (!zipcode) {
      error.zipcode = "Zipcode is required.";
    }
    // if (!street) {
    //   error.street = "Street is required.";
    // }
    if (!formValues.studio_name) {
      error.studio_name = "Studio Name is required.";
    }
    //  else if (!formValues.studio_name.replace(/\s/g, "")) {
    //    error.studio_name = "Studio Name is invalid.";
    //  }
    if (!studio_type1) {
      error.studio_type1 = "Studio Type is required.";
    }

    if (!lng) {
      error.lng = "lng is required.";
    }
    if (!lat) {
      error.lat = "lat is required.";
    }
    if (country_code === "") {
      error.country_code = "Country code is required.";
    }
    if (!value1) {
      error.value1 = "Phone Number is required.";
    }
    // else if (value1.length > 15) {
    //   error.value1 = "Phone Number  is not valid.";
    // }
    else if (value1.length < 9) {
      error.value1 = "Phone Number  is not valid.";
    }
    // if (studiourl === "") {
    //   error.file = "Profile Image is required.";
    // }
    if (checkbox === false) {
      error.checkbox = "Terms and Conditions are required";
    }

    // console.log(Object.keys(error).length);
    //console.log("erterte",error)
    if (Object.keys(error).length === 0) {
      CallApi();
    }
    return error;
  };

  const CallApi = () => {
    setIsLoading(true);
    var data = JSON.stringify({
      password: formValues.password,
      email: formValues.email,
      phoneno: value,
      country_code: String(countryname.countryCode),
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      // profile_pic: profile,
      persona_id: String(inquiryId),
      persona_status: status,
    });

    var config = {
      method: "post",
      url: registerApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          //console.log("iddddd", response.data.data);
          AddStudioApi(response.data.data);
          setShowModal(true);
          setIsLoading(false);
          setTimeout(() => {}, 3000);
          setMessage(response.data.message);
        } else {
          setShowModal(true);
          setIsLoading(false);
          setTimeout(() => {}, 3000);
          setMessage(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  const AddStudioApi = (id) => {
    setIsLoading(true);
    setVisible(false);
    setStatus(true);

    var data = JSON.stringify({
      city: city,
      state: state,
      address: address,
      zipcode: zipcode,
      userid: id,
      street: street,
      studio_name: formValues.studio_name,
      studio_type: studio_type1,
      profile_image: studiourl ? studiourl : "",
      lng: String(lng),
      lat: String(lat),
      country_code: String(countryname.countryCode),
      phoneno: value,
      description: formValues.description,
    });

    var config = {
      method: "post",
      url: addStudioApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          handlestudioid(response.data.data);
          navigate("/login");
          //getStudioList();
          // Swal.fire(response.data.message);
          localStorage.setItem("studioid", response.data.data.studio_id);
        }
        // else{
        //    //Swal.fire(response.data.message);
        // }
        setOnFocus(false);
        setIsLoading(false);
      })

      .catch(function (error) {
        console.log(error);
        setOnFocus(false);
        setIsLoading(false);
      });
  };
  const handlestudioid = (data) => {
    localStorage.setItem("studioid", data?.studio_id);
  };
  const studioTypeApi = () => {
    var config = {
      method: "get",
      url: getstudioType,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        const items = response.data.studioType.map((item) => {
          return { ...item, selected: false };
        });
        console.log("studiootypee", response);
        setStudioType(items);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError("");
  };

  const handleChange1 = (address) => {
    // console.log(address);
    setAddress(address);
    setError("");
    // setCityErr("");
    // setZipcodeErr("");
    // setStreetErr("");
    // setStateErr("");
  };

  const handleSelect = (address, name) => {
    // console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        // console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };
        setCity(addressObject.city);
        setState(addressObject.state);
        setAddress(address);
        setZipcode(addressObject.zipcode);
        setStreet(addressObject.street);
        setCountrycode(addressObject.cn);
        setCityErr("");
        setZipcodeErr("");
        setStreetErr("");
        setStateErr("");
        getLatLng(results[0]).then((latLng) => {
          setLat(latLng.lat);
          setLng(latLng.lng);
          // console.log("Success", latLng);
        });
      })

      .catch((error) => console.error("Error", error));
  };

  const handleChange = (data) => {
    const index = studioType.findIndex((n) => n.id === data.id);
    studioType[index].selected = !studioType[index].selected;
    setStudioType([...studioType]);

    setStudio_type(
      studioType
        .filter((n) => n.selected === true)
        .map((n) => n.studio_type)
        .toString()
    );
    setError("");
    //setStudioTypeErr("");
  };

  const handleChange2 = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      //console.log(reader.result);
      setImage(reader.result);
      setFileType("StudioProfile");
    };

    reader.readAsDataURL(files[0]);
    setImageErr("");
  };
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      console.log(newFile);
      uploadImagevvApi(newFile, filetype);
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const uploadImagevvApi = (newFile, Folder) => {
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("folder", Folder);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadimageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImage("");
        setImage1("");
        setImage2("");

        if (Folder === "StudioProfile") {
          setStudioUrl(result.data);
        }
      })
      .catch((error) => console.log("error", error));
    setFile(newFile);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData1();
    }
  };
  useEffect(() => {
    studioTypeApi();
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setCountry(json);
      });
    if (location?.state?.data) {
      // console.log("hkjhkjh", location?.state?.data);
      setFormValues(location?.state?.data);
    }
    if (location?.state?.images) {
      setStudioUrl(location?.state?.images);
    }
    if (location?.state?.studioType) {
      setStudio_type(location?.state?.studioType);
    }
    if (location?.state?.checkbox) {
      setCheckbox(location?.state?.checkbox);
    }
    if (location?.state?.phoneno) {
      setValue(location?.state?.phoneno);
      // console.log("sasdadfgh", location?.state?.phoneno);
    }
    if (location?.state?.phoneno1) {
      setValue1(location?.state?.phoneno1);
      // console.log(location?.state?.phoneno1);
    }
    if (location?.state?.street) {
      setStreet(location?.state?.street);
    }
    if (location?.state?.state) {
      setState(location?.state?.state);
    }
    if (location?.state?.zipcode) {
      setZipcode(location?.state?.zipcode);
    }
    if (location?.state?.city) {
      setCity(location?.state?.city);
      // console.log(location?.state?.studioType);
    }
    if (location?.state?.address) {
      setAddress(location?.state?.address);
      // console.log(location?.state?.studioType);
    }
    if (location?.state?.Country) {
      setCountry(location?.state?.Country);
    }
    if (location?.state?.lng) {
      setLng(location?.state?.lng);
    }
    if (location?.state?.lat) {
      setLat(location?.state?.lat);
    }
    if (location?.state?.country_code) {
      setCountrycode(location?.state?.country_code);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="loginmainsection ">
        <div className="row g-0 flex-direction-row">
          <div className="col-md-6 boxmainheight">
            <div className="loginleftsection">
              <div className="mainloginlogobox">
                <Link to="/">
                  <img src="images/mainlogo.png" />
                </Link>
              </div>
              <h2 className="customcreateaccountheadingsetting">
                Create Account
              </h2>
              <div
                className="tistpop"
                style={{
                  visibility: showModal === true ? "visible" : "hidden",
                }}
              >
                {message && (
                  <span className="customvalidationdesignlogin">{message}</span>
                )}
              </div>

              <div className="row">
                {/* {message && (
                    <span className="customvalidationdesignlogin">
                      {message}
                    </span>
                  )} */}
                <div className="col-md-6">
                  <div className="eyegroup">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label customlabelloginpagesetting"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="First name"
                      name="firstname"
                      value={formValues.firstname}
                      onChange={handleData1}
                      onKeyDown={handleKeyPress}
                    />
                    {error.firstname && (
                      <span className="customvalidationdesignlogin">
                        {error.firstname}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="eyegroup">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label customlabelloginpagesetting"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="Last name"
                      name="lastname"
                      value={formValues.lastname}
                      onChange={handleData1}
                      onKeyDown={handleKeyPress}
                    />
                    {error.lastname && (
                      <span className="customvalidationdesignlogin">
                        {error.lastname}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="eyegroup">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label customlabelloginpagesetting"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="stubie@example.com"
                      name="email"
                      value={formValues.email}
                      onChange={handleData1}
                      onKeyDown={handleKeyPress}
                    />
                    {error.email && (
                      <span className="customvalidationdesignlogin">
                        {error.email}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="eyegroup">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label customlabelloginpagesetting"
                    >
                      Password
                    </label>
                    <div className="iconbox2">
                      <i onClick={show} className={icon}></i>
                    </div>
                    <input
                      type={type}
                      className="form-control custominputtypeloginpagesetting"
                      placeholder="**********"
                      name="password"
                      value={formValues.password}
                      onChange={handleData1}
                      onKeyDown={handleKeyPress}
                    />

                    {error.password && (
                      <span className="customvalidationdesignlogin">
                        {error.password}
                      </span>
                    )}
                    <span>
                      * Password must have at least 1 uppercase letter, 1
                      lowercase letter, 1 special character and 1 number.
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="eyegroup">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label customlabelloginpagesetting"
                    >
                      Phone Number
                    </label>

                    <PhoneInput
                      placeholder="Enter phone number"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry={countryname.countryCode}
                      limitMaxLength={17}
                      name="value"
                      value={value}
                      onChange={(e) => {
                        setValue(e);
                        setError("");
                      }}
                      onKeyDown={handleKeyPress}
                    />

                    {error.value && (
                      <span className="customvalidationdesignlogin">
                        {error.value}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <h4 className="addstudiodetailpopmainhaeding">
                      Add studio detail
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4 className="addstudiodetailpopmainhaeding">Address</h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Studio Name
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="stubiestudio"
                        name="studio_name"
                        value={formValues.studio_name}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />
                      {error.studio_name && (
                        <span className="customvalidationdesignlogin">
                          {error.studio_name}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Street
                      </label>
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleChange1}
                        onSelect={handleSelect}
                        onKeyDown={handleKeyPress}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              name="street"
                              value={street}
                              {...getInputProps({
                                placeholder: "Enter Street",
                                className:
                                  "form-control custominputtypeloginpagesetting",
                              })}
                              autoComplete="route"
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {/* {error.street && (
                        <span className="customvalidationdesignlogin">
                          {error.street}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Studio Type
                      </label>
                      <button
                        className="form-select custominputtypeloginpagesetting"
                        aria-label="Default select example"
                        onClick={() => showDrop(true)}
                        name="studio_type1"
                        value={studio_type1}
                        onKeyDown={handleKeyPress}
                        // multiple
                      >
                        {studio_type1 ? studio_type1 : "Select Studio Type"}
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Enter City"
                        name="city"
                        value={city}
                        onKeyDown={handleKeyPress}
                        autoComplete="administrative_area_level_2"
                        onChange={(e) => {
                          setCity(e.target.value);
                          if (clicked === true) {
                            // setCityErr("");
                          }
                        }}
                      />
                      {error.city && (
                        <span className="customvalidationdesignlogin">
                          {error.city}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="">
                      {studioType !== undefined > 0 ? (
                        <>
                          {drop === true ? (
                            <>
                              {studioType.map((item, key) => (
                                <button
                                  type="button"
                                  onClick={() => handleChange(item)}
                                  key={key}
                                  value={item.studio_type}
                                  className="studiooption"
                                >
                                  {item.selected === true ? (
                                    <img
                                      style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: "contain",
                                      }}
                                      src="images/tickimg.png"
                                    />
                                  ) : null}

                                  <button>{item.studio_type}</button>
                                </button>
                              ))}
                              <div className="w-100 d-block">
                                <button
                                  className="btnsaveoption"
                                  onClick={() => showDrop(false)}
                                >
                                  Done
                                </button>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}

                      {error.studio_type1 && (
                        <span className="customvalidationdesignlogin">
                          {error.studio_type1}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Studio Phone Number
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={countryname.countryCode}
                        limitMaxLength={17}
                        name="value1"
                        value={value1}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          setValue1(e);
                          setError("");
                        }}
                      />

                      {error.value1 && (
                        <span className="customvalidationdesignlogin">
                          {error.value1}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Enter State"
                        name="state"
                        value={state}
                        onKeyDown={handleKeyPress}
                        autoComplete="administrative_area_level_1"
                        onChange={(e) => {
                          setState(e.target.value);
                          if (clicked === true) {
                            //setStateErr("");
                            setError("");
                          }
                        }}
                      />
                      {error.state && (
                        <span className="customvalidationdesignlogin">
                          {error.state}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Zipcode
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Enter Zipcode"
                        name="zipcode"
                        value={zipcode}
                        autoComplete="postal-code"
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          setZipcode(e.target.value);

                          //setZipcodeErr("");
                          setError("");
                        }}
                      />
                      {error.zipcode && (
                        <span className="customvalidationdesignlogin">
                          {error.zipcode}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Studio Profile Image
                      </label>
                      <input
                        type="file"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Phone Number"
                        name="profile_image"
                        onChange={handleChange2}
                        onKeyDown={handleKeyPress}
                        accept="image/*"
                      />

                      {/* {error.file && (
                        <span className="customvalidationdesignlogin">
                          {error.file}
                        </span>
                      )} */}
                    </div>
                    {studiourl !== "" ? (
                      <>
                        <div className="editprofileimgcrop">
                          <img
                            className="cropedimg"
                            alt="cropdimg"
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              studiourl
                            }
                          />
                          <span
                            className="imagespandel"
                            onClick={() => DeleteStudioImage()}
                          >
                            X
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div
                    id="cropperModal"
                    style={{
                      display: image !== "" ? "block" : "none",
                    }}
                  >
                    <div className="modal-content">
                      <span onClick={() => setImage("")} className="close">
                        &times;
                      </span>
                      {image !== "" ? (
                        <Cropper
                          style={{ height: 570, width: "100%" }}
                          zoomTo={0.5}
                          initialAspectRatio={1}
                          preview=".img-preview"
                          src={image}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          onInitialized={(instance) => {
                            setCropper(instance);
                          }}
                          guides={true}
                        />
                      ) : null}
                      <button
                        className="cropbtnbottom"
                        style={{ float: "right" }}
                        onClick={getCropData}
                      >
                        Upload Image
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="eyegroup">
                      <label
                        htmlFor=""
                        className="form-label customlabelloginpagesetting"
                      >
                        Description
                      </label>
                      <div className="form-floating">
                        <textarea
                          className="form-control custom-textarea"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                          name="description"
                          value={formValues.description}
                          onChange={handleData}
                          onKeyDown={
                            formValues.description ? handleKeyPress : ""
                          }
                        ></textarea>
                        {/* <label htmlFor="floatingTextarea2">Description</label> */}
                        <div>
                          <span className="customvalidationdesignlogin">
                            {error.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input customcheckedboxsetting"
                        type="checkbox"
                        id="flexCheckChecked"
                        name="checkbox"
                        value={checkbox}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          setCheckbox(e.target.checked);
                          setError("");
                        }}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Confirm you agree to the
                        <Link
                          to={`/termsandconditions`}
                          state={{
                            name: formValues,
                            checkbox: checkbox,
                            phoneno: value,
                            phoneno1: value1,
                            image: studiourl,
                            studioType: studio_type1,
                            city: city,
                            zipcode: zipcode,
                            street: street,
                            state: state,
                            address: address,
                            countryname: countryname,
                            lat: lat,
                            lng: lng,
                            country_code: country_code,
                          }}
                        >
                          Terms and Conditions.
                        </Link>
                      </label>
                      {error.checkbox && (
                        <span className="customvalidationdesignlogin">
                          {error.checkbox}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mainloginbutton">
                    <div className="loginbuttonlink">
                      <button type="button" onClick={() => myData1()}>
                        SIGN UP
                      </button>
                    </div>
                    <h5>
                      Already have an account?{" "}
                      <Link to="/login">
                        <span>Log In</span>
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="loginrighttsection">
              <div className="loginrightinnertextsectionupper">
                <Swiper
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1000: {
                      slidesPerView: 1,
                    },
                  }}
                  spaceBetween={30}
                  autoplay={{
                    delay: 2800,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="sliderwidthtext">
                      <h1>
                        Booking your next <span>session</span> <br />
                        is now at your fingertips
                      </h1>
                      <p>
                        Unlock your creative potential within extraordinary
                        studio spaces.Discover - Reserve - Create for all
                        creatives such as  musicians, photographers, dancers,
                        filmmaker more.  Elevate your artistry with
                        state-of-the-art studios for musicians, photographers,
                        dancers, and filmmakers.
                      </p>
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignUp;
