import React, { useState, useEffect } from "react";
import moment from "moment";
import { CustomerSummaryApi } from "../Apis";
import { Link, useNavigate } from "react-router-dom";

function CompletedBookings() {
  const [viewData, setViewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const BookingSummary = (n) => {
    setLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pageno: 1,
      status: "3",
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerSummaryApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          if (n !== undefined) {
            let filData = result.data.filter((n, i) => i < 2);
            setViewData(filData);
            setLoading(false);
          } else {
            setViewData(result.data);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);
      });
  };
  const View = () => {
    BookingSummary();
  };

  useEffect(() => {
    setLoading(true);
    BookingSummary("filter");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="col-md-9 custommainbookinwidth">
        <div className="settingmainlinksbuttondetailboxright">
          <div className="row">
            <div className="col-md-8">
              <h4 className="settingeditstudioprofileheading  custompastbookingheading">
                Completed Bookings
              </h4>
            </div>
            <div className="text-right">
              <button className="savebtnsetting" onClick={() => View()}>
                View All
              </button>
            </div>
            {loading ? (
              <>
                <div className="col-md-6">
                  <p>Loading....</p>
                </div>
              </>
            ) : (
              <>
                {viewData.length !== 0 ? (
                  <>
                    {viewData.map((n) => (
                      <>
                        <div className="col-md-6 customwidthbooking">
                          <div className="pastbookinginfobox">
                            <h3 className="pastbookinginfotextheading">
                              {n.customer_name}
                            </h3>
                            <h6 className="pastbookinginfotextstudioheading">
                              {n.studio_name}
                            </h6>
                            <p className="pastbookinginfotextstudioabout">
                              You have a completed booking request for{" "}
                              {n.room_name}
                            </p>
                            <div className="pastbookinginnertimebox">
                              <h5 className="pastbookingtimeheadingtext">
                                <img
                                  src="images/pastbookingstartimg.png"
                                  className="pastbookingstartendiconimg "
                                />{" "}
                                {moment(n.start_time, ["HH.mm"]).format(
                                  "hh:mm a"
                                )}{" "}
                                <span>
                                  {moment(n?.date, "DD/MM/YYYY").format(
                                    "MM-DD-YYYY"
                                  )}
                                  {/* {n.date} */}
                                </span>
                              </h5>
                              <h5 className="pastbookingtimeheadingtext">
                                <img
                                  src="images/pastbookingendimg.png"
                                  className="pastbookingstartendiconimg "
                                />
                                {moment(n.end_time, ["HH.mm"]).format(
                                  "hh:mm a"
                                )}{" "}
                                <span>
                                  {moment(n?.date, "DD/MM/YYYY").format(
                                    "MM-DD-YYYY"
                                  )}
                                  {/* {n.date} */}
                                </span>
                              </h5>
                              <Link
                                to={`/cusbookingdetail`}
                                state={{ data: n, name: "completed" }}
                                className="pastbookinginnerseedetailbtn"
                              >
                                <img
                                  src="images/seestudioicon.png"
                                  className="pastbookingseedetailimg"
                                />{" "}
                                See Detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div>
                      <p>No Bookings</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default CompletedBookings;
