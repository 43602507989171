import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  AcceptBookingApi,
  CustomerBookingDetailApi,
  CustomerCancelBookingApi,
  CustomerRatingApi,
  DeclineApi,
} from "../Apis";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { Modal } from "bootstrap";

function CusBookingDetail() {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [studioId, setStudioId] = useState("");
  const [namebooking, setNamebooking] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [rating, setRating] = useState("");
  const [hover, setHover] = useState(0);
  const [error, setError] = useState({});
  const [note, setNote] = useState("");
  const [ratingData, setRatingData] = useState("");
  const [newValue, setNewValue] = useState("");
  const [Result, setResult] = useState("");
  const [RoomDetails, setRoomDetails] = useState("");
  const [Dates, setDates] = useState("");
  const [Start, setStart] = useState("");
  const [End, setEnd] = useState("");
  const [AddOn, setAddOn] = useState("");
  const [AddOnData, setAddOnData] = useState([]);
  let location = useLocation();
  let navigate = useNavigate();

  const CusBookingDetail = (data) => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      bookingid: String(data.booking_id),
      studio_id: data.studio_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerBookingDetailApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDetail(result.data);
        setAddOnData(result.addons);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Rating = () => {
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: data.studio_id,
      customer_id: local,
      booking_id: String(data.booking_id),
      rating: String(rating),
      review: note ? note : "",
      time: moment().format("LT"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerRatingApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        Swal.fire(result.message);
        setRatingData(result.data.rating);
        var myModalEl = document.getElementById("reviewsmodal");
        var modal = Modal.getInstance(myModalEl);
        modal.hide();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const validation = () => {
    let error = {};
    if (rating === "") {
      error.rating = "please give rating";
    }
    console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      Rating();
    }
    return error;
  };

  const Rate = () => {
    setError(validation());
  };

  const StarRating = {
    size: 50,
    count: 5,
    color: "#ebeeee",
    activeColor: "#227575",
    a11y: true,
    emptyIcon: <i className="fa fa-star" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (rating) => {
      setRating(rating);
      console.log(`Example 2: new value is ${rating}`);
    },
  };

  const canceledBooking = () => {
    let local = localStorage.getItem("userid");
    console.log("booking", data.booking_id);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: local,
      booking_id: String(data.booking_id),
      reason_of_cancellation: "The reason_of_cancellation.",
    });
    console.log("rawww", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerCancelBookingApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.error === "0") {
          navigate("/customer", { state: { showBooking: 3 } });
          Swal.fire("Booking cancelled");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Cancel = () => {
    canceledBooking();
  };
  const Back = () => {
    navigate("/customer", {
      state: { pending: 2 },
    });
  };

  useEffect(() => {
    let Detail = location.state.data;
    setData(Detail);
    let name = location.state.name;
    setNamebooking(name);
    //console.log("daaattat", Detail);
    CusBookingDetail(Detail);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header />
      <div className="stubieprofilebannersection">
        <a className="backlink termbank" onClick={() => Back()}>
          <img src="images/backbtn1.png" />
        </a>
        <div className="container-fluid">
          <h1 className="changepasswordmainheadingsetting">Booking Detail</h1>
        </div>
      </div>
      <div className="bookingdeatailmainsection bookingdetails-studio pagenewclass">
        <div className="container">
          {detail !== undefined ? (
            <>
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Studio Detail</h5>
                      <div className="bookingstudiodetail">
                        <div className="stubiestudiodetailimagebox">
                          {detail.studio_image !== "" ? (
                            <img
                              src={
                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                detail.studio_image
                              }
                            />
                          ) : (
                            <img src="images/profiledefault.jpg" />
                          )}
                        </div>

                        <div className="stubiestudiotextdetail">
                          <h6>{detail.studio_name}</h6>
                          <h4>{detail.studio_location}</h4>
                        </div>
                      </div>
                      <div className="stubiebookingroomname">
                        <h2>Room Name</h2>
                        <p>{detail.room_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Customer Detail</h5>
                      <div className="stubiebookingroomname">
                        <h2>Customer Name</h2>
                        <p>{detail.customer_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Date and Time</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="stubiebookingroomname">
                            <h2>Start Date and Time</h2>
                            <p>
                              {moment(detail.start_time, ["HH.mm"]).format(
                                "hh:mm a"
                              )}{" "}
                              {moment(detail.date, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="stubiebookingroomname">
                            <h2>End Date and Time</h2>
                            <p>
                              {moment(detail.end_time, ["HH.mm"]).format(
                                "hh:mm a"
                              )}{" "}
                              {moment(detail.date, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Bill Details</h5>
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Booking Price</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          {detail?.room_price ? (
                            <h6>
                              $
                              {new Intl.NumberFormat("en-US").format(
                                Number(detail?.room_price)?.toFixed(2)
                              )}
                              {/* {Number(detail?.room_price)?.toFixed(2)} */}
                              Per Hour
                            </h6>
                          ) : (
                            <h6>$ 0</h6>
                          )}
                        </div>
                      </div>
                      {AddOnData.length !== 0 ? (
                        <div className="bookingstudiodetail d-flex">
                          <div className="stubiestudiotextdetail">
                            <h6 className="bookingpricesetting">
                              Addons Price
                            </h6>
                          </div>
                          <div className="stubiestudiotextdetailprice ">
                            {AddOnData.map((item) => (
                              <>
                                {item.price ? (
                                  <h6>
                                    $
                                    {new Intl.NumberFormat("en-US").format(
                                      Number(item.price)?.toFixed(2)
                                    )}
                                    {/* {Number(item.price)?.toFixed(2)} */}
                                  </h6>
                                ) : (
                                  <h6>$ 0</h6>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      ) : null}
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Service Fee</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          {detail?.service_fee ? (
                            <>
                              <h6>
                                $
                                {/* {Number(detail?.service_fee)?.toFixed(2)} */}
                                {new Intl.NumberFormat("en-US").format(
                                  Number(detail?.service_fee)?.toFixed(2)
                                )}
                              </h6>
                            </>
                          ) : (
                            <h6>$ 0</h6>
                          )}
                        </div>
                      </div>
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">
                            Number of Hours
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          <h6>{detail?.no_of_hours}</h6>
                          {/* {detail?.service_fee ? (
                            <>
                              <h6>
                                ${Number(detail?.service_fee)?.toFixed(2)}
                              </h6>
                            </>
                          ) : (
                            <h6>$ 0</h6>
                          )} */}
                        </div>
                      </div>
                      {detail?.tax === "0" ? null : (
                        <div className="bookingstudiodetail d-flex">
                          <div className="stubiestudiotextdetail">
                            <h6 className="bookingpricesetting">Tax</h6>
                          </div>
                          <div className="stubiestudiotextdetailprice">
                            {detail?.tax ? (
                              <h6>${Number(detail?.tax)?.toFixed(2)}</h6>
                            ) : (
                              <h6>$ 0</h6>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting customtotalpricesetting">
                            Total Price
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice  ">
                          {detail.total_price ? (
                            <h6 className="customtotalmoneysetting">
                              {/* ${Number(detail.total_price).toFixed(2)} */}${" "}
                              {new Intl.NumberFormat("en-US").format(
                                Number(detail.total_price).toFixed(2)
                              )}
                            </h6>
                          ) : (
                            <h6 className="customtotalmoneysetting">$ 0</h6>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bookingchatbtnleft">
                      <Link
                        to={`/customer`}
                        state={{ pending: 3 }}
                        className=" roomaddbuttonsave"
                      >
                        <img src="images/chatbookingpage.png" /> Have a
                        Question?
                      </Link>
                    </div>
                  </div>
                  {namebooking === "canceled" ? null : (
                    <>
                      {namebooking === "upcoming" ? (
                        <div className="col-md-6">
                          <div className="bookingdeatailfinalbtnright">
                            <ul>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => {
                                    Cancel(detail.booking_id);
                                    console.log("cancel", detail.booking_id);
                                  }}
                                  className="bookingdeclinebtnsetting"
                                >
                                  Cancel Booking
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                  {detail.review_status === true ? null : (
                    <>
                      {namebooking === "completed" ? (
                        <div className="col-md-6">
                          <div className="bookingdeatailfinalbtnright">
                            <ul>
                              <li>
                                <button
                                  className="bookingdeclinebtnsetting"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reviewsmodal"
                                  onClick={() => {
                                    setRating("");
                                    setNote("");
                                  }}
                                >
                                  Reviews
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </>
            </>
          ) : null}
        </div>
      </div>

      <Footer />

      <div
        className="modal fade opacity-animate"
        id="reviewsmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{detail.studio_name}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="reviewcontainer">
                  <div className="media reviewbox d-flex">
                    <div className="media-body">
                      <div className="inforeviestar d-flex reviewboxstar"></div>
                      <ReactStars {...StarRating} />
                      {error.rating && (
                        <span className="customvalidationdesignlogin">
                          {error.rating}
                        </span>
                      )}
                      <div className="col-12">
                        <div className="eyegroup">
                          <label htmlFor="floatingTextarea2">
                            Note(Optional):
                          </label>
                          <div className="form-floating">
                            <textarea
                              className="form-control custom-textarea"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              style={{ height: "100px" }}
                              name="note"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="bookingdeatailfinalbtnright float-none reviewbtnbox">
                        <div className="row d-flex">
                          <button
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            onClick={() => {
                              setRating("");
                              setNote("");
                            }}
                            className="bookingdeclinebtnsetting custombookingapprovebtn btnstyle-letter"
                          >
                            Later
                          </button>

                          <button
                            type="button"
                            onClick={() => Rate()}
                            className="bookingdeclinebtnsetting custombookingapprovebtn"
                          >
                            Rate Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CusBookingDetail;
