import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { contactUsApi, CusContactUsApi } from "../Apis";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
function ContactUs() {
  const [formValues, setFormValues] = useState({
    name: "",
    message: "",
    email: "",
    userid: "",
  });
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const myData = (e) => {
    //console.log("hello submit");
    e.preventDefault();
    //setIsLoading(true)
    setError(validate(formValues));
  };

  //form validations
  const validate = (formValues) => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!formValues.email) {
      error.email = "email is required.";
    } else if (!reg.test(formValues.email)) {
      error.email = "Invalid email .";
    }

    if (!formValues.name) {
      error.name = "Full Name is required.";
    }
    if (!formValues.message) {
      error.message = "Message is required.";
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      let role = localStorage.getItem("role");
      if (role === "Customer") {
        contactUs1();
      } else {
        contactUs();
      }
    }
    return error;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  const contactUs = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (local) {
      var raw = JSON.stringify({
        userid: local,
        email: formValues.email,
        name: formValues.name,
        message: formValues.message,
      });
    } else {
      var raw = JSON.stringify({
        email: formValues.email,
        name: formValues.name,
        message: formValues.message,
      });
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(contactUsApi, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setShowModal(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);

          //setMessage(result.message);
          Swal.fire("Thanks for the feedback", result.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setShowModal(true);
          setTimeout(() => {
            //window.location.reload();
          }, 3000);

          //setMessage(result.message);
          Swal.fire(result.message);
        }
      })

      .catch((error) => {
        //console.log("error", error);
        setIsLoading(false);
      });
  };

  const contactUs1 = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (local) {
      var raw = JSON.stringify({
        userid: local,
        email: formValues.email,
        name: formValues.name,
        message: formValues.message,
      });
    } else {
      var raw = JSON.stringify({
        email: formValues.email,
        name: formValues.name,
        message: formValues.message,
      });
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CusContactUsApi, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setShowModal(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);

          //setMessage(result.message);
          Swal.fire("Thanks for the feedback", result.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setShowModal(true);
          setTimeout(() => {
            //window.location.reload();
          }, 3000);

          //setMessage(result.message);
          Swal.fire(result.message);
        }
      })

      .catch((error) => {
        //console.log("error", error);
        setIsLoading(false);
      });
  };

  // const toast=()=>{
  //   Swal.fire("Thanks for the feedback");
  // }

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header />

      <div className="contactusmainbannerimage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* <div
                className="tistpop"
                style={{
                  visibility: showModal === true ? "visible" : "hidden",
                }}
              >
                {message && (
                  <span className="customvalidationdesignlogin">{message}</span>
                )}
              </div> */}
              <div className="contactusmaintextinnersection">
                <h2>
                  Get In <span>Touch</span>
                </h2>
                <p>
                  Want to get in touch? We’d love to hear from you. here’s
                  <br />
                  how you can reach us......
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contactushowcanihelpyousection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contactushowcanihelpinnersection">
                <h3>
                  How can i <span>help you? </span>
                </h3>
                <h5>Fill in the form or drop an email</h5>
              </div>
              <div className="contactusformgroupsection">
                <form onSubmit={myData}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Stubie"
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                          name="name"
                          value={formValues.name}
                        />
                        {error.name && (
                          <span className="customvalidationdesignlogin">
                            {error.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Stubie123@gmail.com"
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                          name="email"
                          value={formValues.email}
                        />
                        {error.email && (
                          <span className="customvalidationdesignlogin">
                            {error.email}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Message
                        </label>
                        <textarea
                          className="form-control custom-textarea custominputtypeloginpagesetting custommessagetextareasetting"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                          name="message"
                          value={formValues.message}
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                        ></textarea>

                        {error.message && (
                          <span className="customvalidationdesignlogin">
                            {error.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary customsavebtnchangespopup  customsavebuttonsettingbankinfotab   customsavebtnchangepasswordsetting  savecontatcusbtnsetting"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default ContactUs;
