import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PastBooking from "./PastBooking";
import CancelledBooking from "./CanceledBooking";
import ConfirmedBooking from "./ConfirmedBooking";
import PendingBooking from "./PendingBooking";
import { saveAs } from "file-saver";
import FileSaver from "file-saver";
import fileDownload from "js-file-download";
import moment from "moment";
import Swal from "sweetalert2";
import Calendar from "react-calendar";
//import DatePicker from "react-datepicker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  addStudioApi,
  bankDetailApi,
  editProfileApi,
  editStudioDetailsApi,
  getProfileApi,
  getStudioApi,
  getstudioType,
  studioDetailsApi,
  uploadimageApi,
  getBankDetailApi,
  editStudioImageApi,
  StudioDeleteApi,
} from "../Apis";
import Loader from "../Loader/Loader";
import { Modal } from "bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FAQ from "./FAQ";
import Earnings from "./Earnings";
import Message from "./Message";
import TimePicker from "rc-time-picker";
import Persona from "persona";
import GetVerified from "./GetVerified";
import DatePicker from "react-multi-date-picker";
import ChangeAddress from "./ChangeAddress";
function MyProfile() {
  let location = useLocation();
  let navigate = useNavigate();
  const [calendarStatus, setCalendarStatus] = useState(false);
  const [studioType, setStudioType] = useState([]);
  const [status, setStatus] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [value, setValue] = useState();
  const [countryname, setCountry] = useState([]);
  const [formValues, setFormValues] = useState({
    studio_name: "",
    // phoneno: "",
    profile_image: "",
    description: "",
  });
  const [user, setUserId] = useState("");
  const [active, setActive] = useState(1);
  const [bank, setBanK] = useState("");
  const [pending, showPending] = useState(0);
  const [route, setRoute] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [checkbox1, setCheckbox1] = useState(false);
  const [profile_id, setProfileId] = useState("");
  const [selectedtudio, setSelectedStudio] = useState({});
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [studioid, setStudioId] = useState("");
  const [phone, setPhone] = useState("");
  const [profile, setProfile] = useState("");
  const [govt, setGovt] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [drop, showDrop] = useState(false);
  const [street, setStreet] = useState("");
  const [clicked, setClicked] = useState(false);
  const [country_code, setCountrycode] = useState("");
  const [lat, setLat] = useState("");
  const [focus, setOnFocus] = useState(false);
  const [lng, setLng] = useState("");
  const [error, setError] = useState({});
  const [studio_type1, setStudio_type] = useState("");
  const [file, setFile] = useState("");
  const [studiourl, setStudioUrl] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [visible, setVisible] = useState(true);
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image3, setImage3] = useState("");
  const [image2, setImage2] = useState("");
  const [imageChange1, setImageChange1] = useState(false);
  const [imageChange2, setImageChange2] = useState(false);
  const [imageChange3, setImageChange3] = useState(false);
  const [filetype, setFileType] = useState("");
  const [cropper, setCropper] = useState();
  const [studiotypeerr, setStudioTypeErr] = useState(false);
  const [imageerr, setImageErr] = useState(false);
  const [cityerr, setCityErr] = useState(false);
  const [zipcodeerr, setZipcodeErr] = useState(false);
  const [streeterr, setStreetErr] = useState(false);
  const [addresserr, setAddressErr] = useState(false);
  const [stateerr, setStateErr] = useState(false);
  const [checkboxerr, setCheckboxErr] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const [studioName, setStudioName] = useState("");
  const [studiotypes, setStudioTypes] = useState("");
  const [city1, setCity1] = useState("");
  const [state1, setState1] = useState("");
  const [street1, setStreet1] = useState("");
  const [zipcode1, setZipcode1] = useState("");
  const [msgState, setMessageState] = useState(false);
  const [phonenumber, setPhoneNumber] = useState("");
  const [studioImage, setStudioImage] = useState("");
  const [address1, setAddress1] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [lat1, setLat1] = useState("");
  const [lng1, setLng1] = useState("");
  const [timeValue, setTimeValue] = useState();
  const [calendar, setCalendar] = useState([]);
  const [BlackOut, setBlackout] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const setLoading1 = (loading) => {
    setisloading(loading);
  };
  const [calendar1, setCalendar1] = useState([
    {
      id: 1,
      day: "Monday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
    {
      id: 2,
      day: "Tuesday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
    {
      id: 3,
      day: "Wednesday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
    {
      id: 4,
      day: "Thursday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
    {
      id: 5,
      day: "Friday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
    {
      id: 6,
      day: "Saturday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
    {
      id: 7,
      day: "Sunday",
      available_from: "09:00",
      available_to: "18:00",
      check: "1",
    },
  ]);
  const [description, setDescription] = useState("");
  const [inquiryId, setInquiryId] = useState("");
  const [status1, setStatus1] = useState("");

  const [options, setOptions] = useState({
    templateId: "tmpl_JAZjHuAT738Q63BdgCuEJQre",
  });

  const [flowType, setFlowType] = useState("embedded");

  const embeddedClientRef = useRef(null);

  /**
   * Creates a new instance of the Persona client. Note that if this is called
   * when the popup is closed, an entirely new inquiry will be created (as
   * opposed to if you wre to call client.open()).
   */
  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: "sandbox",
      onLoad: (error) => {
        if (error) {
          // console.error(
          //   `Failed with code: ${error.code} and message ${error.message}`
          // );
        }
        client.open();
      },
      onStart: (inquiryId) => {
        // console.log(JSON.stringify(inquiryId));
      },
      onComplete: (inquiryId) => {
        //console.log(JSON.stringify(inquiryId));
        setInquiryId(inquiryId.inquiryId);
        setStatus1(inquiryId.status);
        // console.log("ghhhhh", inquiryId);
        //console.log("tttttt", inquiryId.status);
        fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            //console.log(`Received event: start`);
            break;
          default:
          // console.log(
          //   `Received event: ${name} with meta: ${JSON.stringify(meta)}`
          // );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const handleChange2 = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      //console.log(reader.result);
      setImage(reader.result);
      setFileType("StudioProfile");
    };

    reader.readAsDataURL(files[0]);
    setImageErr("");
  };

  const handleProfile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      //console.log(reader.result);
      setImage1(reader.result);
      setImageChange1(true);
      setFileType("ProfilePic");
    };
    setError("");
    reader.readAsDataURL(files[0]);
  };

  const handleGovt = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      //console.log(reader.result);
      setImage2(reader.result);
      setImageChange2(true);
      setFileType("GovernmentID");
    };
    setError("");
    reader.readAsDataURL(files[0]);
  };
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      uploadImagevvApi(newFile, filetype);
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError("");
  };

  const myData = (e) => {
    setClicked(true);
    setError(validate(formValues));
  };
  const OnEditProfile = (e) => {
    setError(validateProfile());
  };

  const handleChange = (data) => {
    const index = studioType.findIndex((n) => n.id === data.id);
    studioType[index].selected = !studioType[index].selected;

    setStudioType([...studioType]);

    setStudio_type(
      studioType
        .filter((n) => n.selected === true)
        .map((n) => n.studio_type)
        .toString()
    );
    // console.log(
    //   studioType
    //     .filter((n) => n.selected === true)
    //     .map((n) => n.studio_type)
    //     .toString()
    // );
    //setError({ ...error, [name]: "" });
    setStudioTypeErr("");
  };

  const handleChangeType = (data) => {
    const index = studioType.findIndex((n) => n.id === data.id);
    studioType[index].selected = !studioType[index].selected;
    setStudioType([...studioType]);

    setStudioTypes(
      studioType
        .filter((n) => n.selected === true)
        .map((n) => n.studio_type)
        .toString()
    );
    // console.log(
    //   "hjjhhj",
    //   studioType
    //     .filter((n) => n.selected === true)
    //     .map((n) => n.studio_type)
    //     .toString()
    // );
    //setError({ ...error, [name]: "" });
    //setStudioTypeErr("");
  };

  //Profile validations

  const validateProfile = () => {
    const re = /^[0-9\b]+$/;
    let error = {};
    if (firstname === "") {
      error.firstname = "First Name is required.";
    }
    if (lastname === "") {
      error.lastname = "Last Name is required.";
    }
    if (email === "") {
      error.email = "Email is required.";
    }

    if (phone === "") {
      error.phone = "Phone is required.";
    }
    if (profile === "") {
      error.profile = "Profile Photo is required.";
    }
    // if (govt === "") {
    //   error.govt = "Government id is required.";
    // }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      var myModalEl = document.getElementById("exampleModal1");
      var modal = Modal.getInstance(myModalEl);
      modal.hide();

      EditProfileApi();
    }
    return error;
  };

  const EditProfileApi = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = {};
    if (imageChange1 === true && imageChange2 === true) {
      raw = JSON.stringify({
        userid: user,
        profile_id: profile_id,
        profile_pic: profile,
        government_id: govt,
        phoneno: phone,
        firstname: firstname,
        lastname: lastname,
        country_code: String(countryname.countryCode),
        persona_id: String(inquiryId),
        persona_status: status1,
      });
    } else if (imageChange1 === true) {
      raw = JSON.stringify({
        userid: user,
        profile_id: profile_id,
        profile_pic: profile,
        phoneno: phone,
        firstname: firstname,
        lastname: lastname,
        country_code: String(countryname.countryCode),
        persona_id: String(inquiryId),
        persona_status: status1,
      });
    } else if (imageChange2 === true) {
      raw = JSON.stringify({
        userid: user,
        profile_id: profile_id,
        government_id: govt,
        phoneno: phone,
        firstname: firstname,
        lastname: lastname,
        country_code: String(countryname.countryCode),
        persona_id: String(inquiryId),
        persona_status: status1,
      });
    } else {
      raw = JSON.stringify({
        userid: user,
        profile_id: profile_id,
        phoneno: phone,
        firstname: firstname,
        lastname: lastname,
        country_code: String(countryname.countryCode),
        persona_id: String(inquiryId),
        persona_status: status1,
      });
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(editProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImageChange2(false);
        setImageChange1(false);
        // console.log(result);
        localStorage.setItem("profilepic", profile);
        setIsLoading(false);
        getProfile(user, profile_id);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  //form validations
  const validate = (formValues, validate) => {
    let error = {};
    let reg = /^\S*$/;

    if (!city) {
      //error.city = "City is required.";
      setCityErr(true);
    } else {
      setCityErr(false);
    }

    if (!state) {
      //error.state = "State is required.";
      setStateErr(true);
    } else {
      setStateErr(false);
    }

    if (address === "") {
      error.address = "Address is required.";
    }
    if (!zipcode) {
      //error.zipcode = "Zipcode is required.";
      setZipcodeErr(true);
    } else {
      setZipcodeErr(false);
    }
    // if (!street) {
    //   // error.street = "Street is required.";
    //   setStreetErr(true);
    // } else {
    //   setStreetErr(false);
    // }
    if (!address) {
      // error.street = "Street is required.";
      setAddressErr(true);
    } else {
      setAddressErr(false);
    }
    if (formValues.studio_name === "") {
      error.studio_name = "Studio Name is required.";
    }
    if (!studio_type1) {
      //error.studio_type1 = "Studio Type is required.";
      setStudioTypeErr(true);
    } else {
      setStudioTypeErr(false);
    }
    if (!lng) {
      error.lng = "lng is required.";
    }
    if (!lat) {
      error.lat = "lat is required.";
    }
    if (country_code === "") {
      error.country_code = "Country code is required.";
    }
    if (!value) {
      error.value = "Phone Number is required.";
    } else if (value.length < 9) {
      error.value = "Phone Number  is not valid.";
    }
    // if (studiourl === "") {
    //   setImageErr(true);
    // } else {
    //   setImageErr(false);
    // }

    //console.log(Object.keys(error).length);
    //console.log(error);
    if (validate === undefined) {
      if (Object.keys(error).length === 0) {
        // if (studiourl !== "") {
        AddStudioApi();
        var myModalEl = document.getElementById("exampleModal2");
        var modal = Modal.getInstance(myModalEl);

        modal.hide();

        setClicked(false);
        //setCheckboxErr(false);
        // setImageErr(false);
        // }
      }
    }
    return error;
  };

  const uploadImagevvApi = (newFile, Folder) => {
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("folder", Folder);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadimageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImage("");
        setImage1("");
        setImage2("");

        if (Folder === "StudioProfile") {
          setStudioUrl(result.data);
        } else if (Folder === "GovernmentID") {
          setGovt(result.data);
        } else if (Folder === "ProfilePic") {
          setProfile(result.data);
        }
      })
      .catch((error) => console.log("error", error));
    setFile(newFile);
  };

  const AddStudioApi = () => {
    setIsLoading(true);
    setVisible(false);
    setStatus(true);
    let local = localStorage.getItem("userid");
    var data = JSON.stringify({
      city: city,
      state: state,
      address: address,
      zipcode: zipcode,
      userid: local,
      street: address,
      studio_name: formValues.studio_name,
      studio_type: studio_type1,
      profile_image: studiourl ? studiourl : "",
      lng: String(lng),
      lat: String(lat),
      country_code: String(country_code),
      phoneno: value,
      description: formValues.description ? formValues.description : "",
    });

    var config = {
      method: "post",
      url: addStudioApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          handlestudioid(response.data.data);
          SelectedStudioApi(response.data.data);
          // setSelectedStudio(response.data.data);
          ///getStudioList();
          //Swal.fire(response.data.message);
          //localStorage.setItem("studioid", response.data.data.studio_id);
        }
        // else{
        //    Swal.fire(response.data.message);
        // }
        setOnFocus(false);
        setIsLoading(false);
      })

      .catch(function (error) {
        console.log(error);
        setOnFocus(false);
        setIsLoading(false);
      });
  };
  const handlestudioid = (data) => {
    localStorage.setItem("studioid", data?.studio_id);
  };
  const studioTypeApi = (data) => {
    var config = {
      method: "get",
      url: getstudioType,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        const items = response.data.studioType.map((item) => {
          return {
            ...item,
            selected: data.includes(item.studio_type) ? true : false,
          };
        });

        setStudioType(items);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getStudioList = () => {
    let local = localStorage.getItem("userid");
    var data = JSON.stringify({
      userid: local,
    });

    var config = {
      method: "post",
      url: getStudioApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //alert(JSON.stringify(response.data));

        setData(response.data.data);
        if (response.data.data.length !== 0) {
          // console.log("fkkfkf", response.data.data[0]);
          SelectedStudioApi(response.data.data[0]);
        } else {
          setIsLoading(false);
          setSelectedStudio({});
          setStudioImage("");
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const OnAddBankInfo = (e) => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: user,
      user_name: bank,
      accountno: accountNo,
      routing: route,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(bankDetailApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setIsLoading(false);
          Swal.fire(result.message);
        } else {
          setIsLoading(false);
          Swal.fire(result.message);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        setIsLoading(false);
      });
  };

  //Bank info validations

  const bankInfoValidation = () => {
    let error = {};
    const regex1 = /^[0-9\b]+$/;
    let regex = /^[a-zA-Z]*$/;
    if (bank === "") {
      error.bank = "Bank Name is required.";
    } else if (!regex.test(bank)) {
      error = { ...error, bank: "Enter Bank Name" };
    }
    if (accountNo === "") {
      error.accountNo = "Account no. is required.";
    } else if (accountNo.length < 6) {
      error.accountNo = "Number is not valid.";
    } else if (accountNo.length > 17) {
      error.accountNo = "Number is not valid.";
    }
    if (route === "") {
      error.route = "Routing or SWIFT/BIC number is required.";
    } else if (!regex1.test(route)) {
      error.route = "Enter numbers only.";
    } else if (route.length > 9) {
      error.route = "Number is not valid.";
    }
    if (checkbox1 === false) {
      error.checkbox1 = "Terms and conditions are required.";
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      OnAddBankInfo();
    }
    return error;
  };

  const OnBankIfo = () => {
    setError(bankInfoValidation());
  };

  const SelectedStudioApi = (data1) => {
    setIsLoading(true);
    let studioId = data1.studio_id;

    if (location?.state?.pending) {
      setActive(5);
      // showPending(3);
      if (location?.state?.studioId) {
        studioId = location?.state?.studioId;
      }
    }
    if (location?.state?.chatid) {
      setActive(3);
      // showPending(3);
      if (location?.state?.studioId) {
        studioId = location?.state?.studioId;
      }
    }
    let userId = localStorage.getItem("userid");

    var data = JSON.stringify({
      device_type: "I",
      device_token:
        "edsk5P9-aUebj5DQglpIXl:APA91bHXq3zJMCl796wRUBJ6dSHDyrY8Q6ta7KplQFZIJhUWMyQpNKL97X78KI9iCYJ98LK3zBfWIrQUXdRRu2hSWwemuefpDBoiYeKN6kHQTwz7mNjT7sUIayJs9XCyv8CHDOAvxFeS",
      userid: userId,
      studio_id: studioId,
    });

    var config = {
      method: "post",
      url: studioDetailsApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setStudioId(studioId);
        setIsLoading(false);
        // console.log("studdiiiiiooo detail", response.data);
        setSelectedStudio(response.data.data);
        setAddress1(response.data.data.street);
        setZipcode1(response.data.data.zipcode);
        setCity1(response.data.data.city);
        setStudioName(response.data.data.studio_name);
        setPhoneNumber(response.data.data.phoneno);
        setState1(response.data.data.state);
        setStreet1(response.data.data.street);
        setStudioTypes(response.data.data.studio_type);
        studioTypeApi(response.data.data.studio_type);
        setStudioImage(response.data.data.profile_pic);
        setTimeZone(response.data.data.timezone);
        // setProductionHours(response.data.productionHours);
        //console.log("producthous", response.data.productionHours);

        if (response?.data?.productionHours?.length !== 0) {
          setCalendar(response?.data?.productionHours);
        } else {
          let array = [
            {
              id: 5398,
              day: "Monday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:24.1620185",
            },
            {
              id: 5399,
              day: "Tuesday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:24.7160999",
            },
            {
              id: 5400,
              day: "Wednesday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:25.2704058",
            },
            {
              id: 5401,
              day: "Thursday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:25.8246444",
            },
            {
              id: 5402,
              day: "Friday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:26.3788448",
            },
            {
              id: 5403,
              day: "Saturday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:26.9329184",
            },
            {
              id: 5404,
              day: "Sunday",
              available_from: "09:00",
              available_to: "18:00",
              check: "0",
              created_on: "2023-01-24T07:14:27.487966",
            },
          ];
          setCalendar(array);
        }

        setLng1(response.data.data.lng);
        setLat1(response.data.data.lat);
        if (location?.state !== null) {
          showPending(location?.state?.pending);
        }
        if (location.state !== null) {
          setVisible(location.state.visible);
        }
        if (location?.state?.active) {
          setActive(2);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  const handleChange1 = (address) => {
    // console.log(address);
    setAddress(address);
    setCityErr("");
    setZipcodeErr("");
    setStreetErr("");
    setStateErr("");
    setAddressErr("");
  };

  const handleChangeAddress = (address) => {
    // console.log(address);
    setAddress1(address);
  };

  const handleSelect = (address, name) => {
    // console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        // console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };
        setCity(addressObject.city);
        setState(addressObject.state);
        setAddress(address);
        setZipcode(addressObject.zipcode);
        setStreet(addressObject.street);
        setCountrycode(addressObject.cn);
        setCityErr("");
        setZipcodeErr("");
        setStreetErr("");
        setStateErr("");
        getLatLng(results[0]).then((latLng) => {
          setLat(latLng.lat);
          setLng(latLng.lng);
          // console.log("Success", addressObject.cn);
        });
      })

      .catch((error) => console.error("Error", error));
  };

  const handleSelect2 = (address, name) => {
    // console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        // console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };

        setCity1(addressObject.city);
        setState1(addressObject.state);
        setAddress1(address);
        setZipcode1(addressObject.zipcode ? addressObject.zipcode : " ");
        setStreet1(
          addressObject.street ? addressObject.street : addressObject.address
        );
        setCountryCode(addressObject.cn);
        setCityErr("");
        setZipcodeErr("");
        setStreetErr("");
        setStateErr("");
        getLatLng(results[0]).then((latLng) => {
          setLat1(latLng.lat);
          setLng1(latLng.lng);
          // console.log("Success", addressObject.cn);
        });
      })

      .catch((error) => console.error("Error", error));
  };

  const handleChangeDay = (id) => {
    let i = calendar.findIndex((N) => N.id === id);
    // console.log(i);
    let data1 = calendar;
    data1[i].check = data1[i].check === "1" ? "0" : "1";

    //console.log(data1);
    setCalendar([...data1]);
  };

  const handleChangeDay1 = (id) => {
    let i = calendar1.findIndex((N) => N.id === id);
    //console.log(i);
    let data1 = calendar1;
    data1[i].check = data1[i].check === "1" ? "0" : "1";
    // console.log(data1);
    setCalendar1([...data1]);
  };
  const tabsRef = useRef(null);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    startFun();
  }, []);

  const startFun = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    let email = localStorage.getItem("email");
    let phone = localStorage.getItem("phoneno");
    let profileId = localStorage.getItem("profileid");

    setUserId(local);
    setEmail(email);
    setPhone(phone);

    setProfileId(profileId);
    getProfile(local, profileId);
    // studioTypeApi();
    getStudioList();
    getBankInfo(local);
    setAddress(address);

    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setCountry(json);
      });
  };
  const getBankInfo = (local) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(getBankDetailApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error !== "1") {
          setAccountNo(result.data?.accountno_IBAN);
          setBanK(result.data?.user_name);
          setRoute(result.data?.routing_SWIFT_BICno);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getProfile = (id, profileId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: id,
      profile_id: profileId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setPhone(result.data.phoneno);
        if (result?.data?.government_id !== "") {
          setGovt(result?.data?.government_id);
        }
        if (result?.data?.profile_pic !== "") {
          setProfile(result?.data?.profile_pic);
          localStorage.setItem("profilepic", result?.data?.profile_pic);
        }
        setFirstName(result.data.firstname);
        localStorage.setItem("profilename", result?.data?.firstname);
        setLastName(result.data.lastname);
      })
      .catch((error) => console.log("error", error));
  };

  const handle = (name) => {
    let selected = data.find((n) => n.name === name);
    SelectedStudioApi(selected);
  };

  const OnEditStudioProfile = () => {
    setError(editStudioValidation());
    //editStudioDetails();
  };

  const editStudioValidation = () => {
    let error = {};
    const re = /^[0-9\b]+$/;
    if (studioName === "") {
      error.studioName = "Studio Name is required.";
    }
    if (studiotypes === "") {
      error.studiotypes = "Studio Type is required.";
    }
    if (phonenumber === "") {
      error.phonenumber = "Phone Number is required.";
    }

    // if (address1 === "") {
    //   error.address1 = "Addess is required.";
    // }
    // if (description === "") {
    //   error.description = "Description is required.";
    // }
    // if (city1 === "") {
    //   error.city1 = "City is required.";
    // }
    // if (zipcode1 === "") {
    //   error.zipcode1 = "Zipcode is required.";
    // }
    // if (street1 === "") {
    //   error.street1 = "Street is required.";
    // }
    // if (!lng1) {
    //   error.lng1 = "lng is required.";
    // }
    // if (!lat1) {
    //   error.lat1 = "lat is required.";
    // }

    // console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      editStudioDetails();
    }
    return error;
  };

  const editStudioDetails = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    let blackot = BlackOut.toString();
    //console.log("dkkd",blackot.split(","));
    let Black = blackot.split(",").map((n) => ({ day: n }));
    //console.log(Black);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: studioid,
      userid: local,
      studio_type: studiotypes,
      profile_pic: studioImage ? studioImage : "",
      studio_name: studioName,
      venue: address1,
      street: street1,
      city: city1,
      state: state1,
      address: address1,
      zipcode: zipcode1,
      description: description,
      country_code: String(countrycode),
      phoneno: phonenumber,
      lat: String(lat1),
      lng: String(lng1),
      calendar: calendar,
      Blackoutdays: BlackOut.length !== 0 ? Black : [],
    });
    // console.log("hsghsg", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(editStudioDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setIsLoading(false);
          // SelectedStudioApi(data[0]);
          //getStudioList();
          Swal.fire(result.message);
        }
        // else {
        //   setIsLoading(false);
        //   //Swal.fire(result.message);
        // }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const editStudioImage = (newFile) => {
    let local = localStorage.getItem("userid");
    var formdata = new FormData();

    formdata.append("studio_id", studioid);
    formdata.append("image", newFile);
    formdata.append("userid", local);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(editStudioImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setImage3("");
        setStudioImage(result.data.profile_pic);
      })
      .catch((error) => console.log("error", error));
  };

  const handleEditImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      // console.log(reader.result);
      setImage3(reader.result);
      setImageChange3(true);
      //setFileType("StudioProfile");
    };

    reader.readAsDataURL(files[0]);
  };

  const getCropData1 = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      editStudioImage(newFile);
    }
  };
  const DeleteImage = () => {
    setProfile("");
  };
  const DeleteGovt = () => {
    setGovt("");
  };

  const DeleteStudioImage = () => {
    setStudioUrl("");
  };
  const pull_data = (state) => {
    setMessageState(state);
  };
  const toast = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete studio!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(DeleteStudio(), "Your Studio has been deleted.", "success");
      }
    });
  };

  const DeleteStudio = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      studio_id: studioid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(StudioDeleteApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.error === "0") {
          setIsLoading(false);
          startFun();
          //  setSelectedStudio("")
          //getStudioList();
          // window.location.reload()
        } else {
          Swal.fire(result.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const setLoading2 = (loading) => {
    setisloading(loading);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <>
        <Header
          image={
            profile
              ? `https://stubies.s3.us-east-2.amazonaws.com/` + profile
              : "images/loginimgocon.png"
          }
          name={firstname}
        />
        <div className="stubieprofilebannersection">
          <div className="container-fluid"></div>
        </div>
        <div className="stubieprofileinnersection">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="stubieinnerboxsection">
                  <div className="stubieimgbox">
                    {studioImage !== "" ? (
                      <img
                        src={
                          `https://stubies.s3.us-east-2.amazonaws.com/` +
                          studioImage
                        }
                      />
                    ) : (
                      <img src="images/studiohome.png" />
                    )}
                    <div className="profileimgbatchbox">
                      <img src="images/profileimgbatch.png" />
                    </div>
                  </div>

                  <div className="stubietextbox">
                    <h5 className="stubietextsetting">{email}</h5>
                    <h6>{phonenumber}</h6>
                    <div className="stubielinksbox">
                      <ul>
                        <li>
                          <a
                            href="#"
                            className="customstubielinksforprofile"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                          >
                            <img src="images/editprofile.png" />
                            Edit Profile
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setVisible(true);
                              setFormValues("");
                              setZipcode("");
                              setCity("");
                              setStreet("");
                              setState("");
                              setValue("");
                              let studio = studioType.map(
                                (item) => (item.selected = false)
                              );
                              setStudio_type(studio);
                              // setStudio_type("");
                              setStudioUrl("");
                              setAddress("");
                              setError("");
                              setCityErr("");
                              setStateErr("");
                              setZipcodeErr("");
                              setAddressErr("");
                              setStreetErr("");
                              setImageErr("");
                              setStudioTypeErr("");
                            }}
                            href="#"
                            className="customstubielinksforprofile"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                          >
                            <img src="images/addstudio.png" />
                            Add Studios
                          </a>
                        </li>
                        {selectedtudio.studio_id !== undefined ? (
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle customselectstubielink"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedtudio.studio_name}
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              {data !== undefined > 0 ? (
                                <>
                                  {data.map((item, key) => (
                                    <li key={key}>
                                      <Link
                                        key={key}
                                        className="dropdown-item"
                                        onClick={() => handle(item.name)}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </>
                              ) : null}
                            </ul>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mystudiotabmainsection">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="mystudiotabinnersection">
                  <ul
                    className="nav nav-tabs customstudiotabbar"
                    id="myTab"
                    role="tablist"
                    //ref={tabsRef}
                  >
                    <li
                      className="nav-item custommystudioli"
                      role="presentation"
                    >
                      <button
                        onClick={() => {
                          setActive(1);
                          handleScrollToTop();
                        }}
                        className={`nav-link   customtabbtnsetting ${
                          active === 1 && "active"
                        }`}
                        data-bs-toggle="tab"
                        data-bs-target="#mystudios"
                        type="button"
                        role="tab"
                        aria-controls="mystudios"
                        aria-selected="true"
                      >
                        MY STUDIOS
                      </button>
                    </li>
                    {selectedtudio.studio_id !== undefined ? (
                      <>
                        <li
                          className="nav-item custommystudioli"
                          role="presentation"
                        >
                          <button
                            //className="nav-link   customtabbtnsetting"
                            onClick={() => {
                              setActive(2);
                              handleScrollToTop();
                            }}
                            className={`nav-link   customtabbtnsetting ${
                              active === 2 && "active"
                            }`}
                            data-bs-toggle="tab"
                            data-bs-target="#bankinformation"
                            type="button"
                            role="tab"
                            aria-controls="bankinformation"
                            aria-selected="true"
                          >
                            BANK INFORMATION
                          </button>
                        </li>

                        <li
                          className="nav-item custommystudioli"
                          role="presentation"
                        >
                          <button
                            onClick={() => {
                              setActive(3);
                              handleScrollToTop();
                            }}
                            className={`nav-link   customtabbtnsetting ${
                              active === 3 && "active"
                            }`}
                            data-bs-toggle="tab"
                            data-bs-target="#messages"
                            type="button"
                            role="tab"
                            aria-controls="messages"
                            aria-selected="true"
                          >
                            Messages
                          </button>
                        </li>
                        <li
                          className="nav-item custommystudioli"
                          role="presentation"
                        >
                          <button
                            onClick={() => {
                              setActive(4);
                              handleScrollToTop();
                            }}
                            className={`nav-link   customtabbtnsetting ${
                              active === 4 && "active"
                            }`}
                            data-bs-toggle="tab"
                            data-bs-target="#earnings"
                            type="button"
                            role="tab"
                            aria-controls=" earnings"
                            aria-selected="true"
                          >
                            Earnings
                          </button>
                        </li>
                        <li
                          className="nav-item custommystudioli"
                          role="presentation"
                        >
                          <button
                            onClick={() => {
                              setActive(5);
                              handleScrollToTop();
                            }}
                            className={`nav-link   customtabbtnsetting ${
                              active === 5 && "active"
                            }`}
                            data-bs-toggle="tab"
                            data-bs-target="#settings"
                            type="button"
                            role="tab"
                            aria-controls=" settings"
                            aria-selected="true"
                          >
                            Settings
                          </button>
                        </li>
                      </>
                    ) : null}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade show ${
                        active === 1 && "active"
                      }`}
                      role="tabpanel"
                      aria-labelledby="mystudios-tab"
                    >
                      <div className="studiotabboxprofiledetailmain">
                        <div className="container">
                          {selectedtudio.studio_id !== undefined ? (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="studioprofilemainheadingtopleft">
                                  <h3>Studio Profile</h3>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="studioprofilefulldetailinner">
                                  <h6>Studio Name</h6>
                                  <p>{selectedtudio.studio_name}</p>
                                  <h6>Studio Type</h6>
                                  <p>{selectedtudio.studio_type}</p>
                                  <h6>Phone Number</h6>
                                  <p>{selectedtudio.phoneno}</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="studioprofilefulldetailinner">
                                  <h6>Street</h6>
                                  <p>{selectedtudio.street}</p>
                                  {/* <h6>City</h6>
                                  <p>{selectedtudio.city}</p> */}
                                  <h6>State</h6>
                                  <p>{selectedtudio.state}</p>
                                  <h6>Zipcode</h6>
                                  <p>{selectedtudio.zipcode}</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="studioprofilefulldetailinner">
                                  <h6>City</h6>
                                  <p>{selectedtudio.city}</p>
                                  {/* <h6>Street</h6>
                                  <p>{selectedtudio.street}</p> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seedtudioprofilebtnright">
                                  <a
                                    onClick={() => toast()}
                                    className="mr2"
                                    //to={`/studioprofileroom`}
                                    // state={{
                                    //   studioid: selectedtudio.studio_id,
                                    // }}
                                  >
                                    <img src="images/delicon.png" /> delete
                                    studio
                                  </a>
                                  <Link
                                    to={`/studioprofileroom`}
                                    state={{
                                      studioid: selectedtudio.studio_id,
                                    }}
                                  >
                                    <img src="images/seestudioicon.png" /> See
                                    Studio Profile
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <h4 className="no-title">No Studio</h4>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade show ${
                        active === 2 && "active"
                      }`}
                      role="tabpanel"
                      aria-labelledby="bankinformation-tab"
                    >
                      <div className="bankdetailmainsectionbox">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="">
                                <h4 className="bankdetailmaintextheading">
                                  Bank Details
                                </h4>

                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="eyegroup">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label customlabelloginpagesetting"
                                      >
                                        Name on bank account
                                      </label>
                                      <input
                                        value={bank}
                                        onChange={(e) => {
                                          setBanK(e.target.value);
                                          setError("");
                                        }}
                                        pattern="[A-Za-z\\s]*"
                                        onkeypress="return lettersValidate(event)"
                                        type="text"
                                        className="form-control custominputtypeloginpagesetting"
                                        placeholder="Bank of America"
                                      />
                                      {error.bank && (
                                        <span className="customvalidationdesignlogin">
                                          {error.bank}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="eyegroup">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label customlabelloginpagesetting"
                                      >
                                        Account Number/IBAN
                                      </label>
                                      <input
                                        value={accountNo}
                                        onChange={(e) => {
                                          setAccountNo(e.target.value);
                                          setError("");
                                        }}
                                        maxLength={16}
                                        type="number"
                                        className="form-control custominputtypeloginpagesetting"
                                        placeholder="*********"
                                      />
                                      {error.accountNo && (
                                        <span className="customvalidationdesignlogin">
                                          {error.accountNo}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="eyegroup">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label customlabelloginpagesetting"
                                      >
                                        Routing or SWIFT/BIC number
                                      </label>
                                      <input
                                        value={route}
                                        onChange={(e) => {
                                          setRoute(e.target.value);
                                          setError("");
                                        }}
                                        type="number"
                                        id="inputID"
                                        min="1"
                                        max="9"
                                        className="form-control custominputtypeloginpagesetting"
                                        placeholder="**********"
                                      />
                                      {error.route && (
                                        <span className="customvalidationdesignlogin">
                                          {error.route}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input customcheckedboxsetting"
                                        type="checkbox"
                                        value={checkbox1}
                                        id="flexCheckChecked"
                                        name="checkbox1"
                                        onChange={() => {
                                          setCheckbox1();
                                          setError("");
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      />
                                      I have read term and conditions and agree
                                      to the general{" "}
                                      <Link to="/termsandcondbank">
                                        Terms and Conditions and Privacy Policy
                                      </Link>
                                      .
                                    </div>
                                    {error.checkbox1 && (
                                      <span className="customvalidationdesignlogin">
                                        {error.checkbox1}
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-md-4">
                                    <button
                                      onClick={() => OnBankIfo()}
                                      className="btn btn-primary customsavebtnchangespopup  customsavebuttonsettingbankinfotab"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade show ${
                        active === 3 && "active"
                      }`}
                      role="tabpanel"
                      aria-labelledby="messages-tab"
                    >
                      <Message
                        id={studioid}
                        image={
                          profile
                            ? `https://stubies.s3.us-east-2.amazonaws.com/` +
                              profile
                            : "https://stubies.s3.us-east-2.amazonaws.com/b731c60c-c7de-46cd-87fc-a51e3baf3afd/StudioPic/1683117465693"
                        }
                        name={firstname}
                        location={msgState === false ? location?.state : ""}
                        func={pull_data}
                      ></Message>
                    </div>
                    <div
                      className={`tab-pane fade show ${
                        active === 4 && "active"
                      }`}
                      id="earnings"
                      role="tabpanel"
                      aria-labelledby="earnings-tab"
                    >
                      <Earnings id={studioid} loading={setLoading1}></Earnings>
                    </div>
                    <div
                      className={`tab-pane fade show ${
                        active === 5 && "active"
                      }`}
                      role="tabpanel"
                      aria-labelledby="settings-tab"
                    >
                      <div className="mainsettingsectiontop">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="settinglinksbuttonleftbox">
                                <ul className="settinglinksulbtnsetting">
                                  {pending === 0 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(0);
                                      }}
                                    >
                                      Edit Studio Profile
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(0);
                                      }}
                                    >
                                      Edit Studio Profile
                                    </li>
                                  )}
                                  {pending === 1 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(1);
                                      }}
                                    >
                                      Past Bookings
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(1);
                                      }}
                                    >
                                      Past Bookings
                                    </li>
                                  )}
                                  {pending === 2 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(2);
                                      }}
                                    >
                                      Canceled Bookings
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(2);
                                      }}
                                    >
                                      Canceled Bookings
                                    </li>
                                  )}
                                  {pending === 4 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(4);
                                      }}
                                    >
                                      Pending Booking
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(4);
                                      }}
                                    >
                                      Pending Booking
                                    </li>
                                  )}
                                  {pending === 5 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(5);
                                      }}
                                    >
                                      FAQ
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(5);
                                      }}
                                    >
                                      FAQ
                                    </li>
                                  )}
                                  {/* {pending === 6 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(6);
                                      }}
                                    >
                                      Start Verification Process
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(6);
                                      }}
                                    >
                                      Start Verification Process
                                    </li>
                                  )} */}
                                  {pending === 7 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(7);
                                      }}
                                    >
                                      Change Address
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(7);
                                      }}
                                    >
                                      Change Address
                                    </li>
                                  )}
                                  {pending === 3 ? (
                                    <li
                                      className="settinglinkslibtnsetting active"
                                      onClick={() => {
                                        showPending(3);
                                      }}
                                    >
                                      Confirmed Bookings
                                    </li>
                                  ) : (
                                    <li
                                      className="settinglinkslibtnsetting"
                                      onClick={() => {
                                        showPending(3);
                                      }}
                                    >
                                      Confirmed Bookings
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            {pending === 0 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <h4 className="settingeditstudioprofileheading">
                                        Edit Studio Profile
                                      </h4>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="eyegroup">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label customlabelloginpagesetting"
                                          >
                                            Studio Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control custominputtypeloginpagesetting"
                                            placeholder="stubiestudio"
                                            name="studioName"
                                            value={studioName}
                                            onChange={(e) => {
                                              setStudioName(e.target.value);
                                              setError("");
                                            }}
                                          />
                                          {error.studioName && (
                                            <span className="customvalidationdesignlogin">
                                              {error.studioName}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="eyegroup">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label customlabelloginpagesetting"
                                          >
                                            Studio Type
                                          </label>
                                          <button
                                            className="form-select custominputtypeloginpagesetting mb-0"
                                            aria-label="Default select example"
                                            onClick={() => showDrop(true)}
                                            name="studiotypes"
                                            value={studiotypes}
                                            // multiple
                                          >
                                            {studiotypes
                                              ? studiotypes
                                              : "Select Studio Type"}
                                          </button>
                                          {error.studiotypes && (
                                            <span className="customvalidationdesignlogin">
                                              {error.studiotypes}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="">
                                          {studioType !== undefined > 0 ? (
                                            <>
                                              {drop === true ? (
                                                <>
                                                  {studioType.map(
                                                    (item, key) => (
                                                      <button
                                                        onClick={() =>
                                                          handleChangeType(item)
                                                        }
                                                        className="studiooption"
                                                        key={key}
                                                        value={item.studio_type}
                                                      >
                                                        {item.selected ===
                                                        true ? (
                                                          <img
                                                            style={{
                                                              width: 20,
                                                              height: 20,
                                                              resizeMode:
                                                                "contain",
                                                            }}
                                                            src="images/tickimg.png"
                                                          />
                                                        ) : null}
                                                        <button>
                                                          {item.studio_type}
                                                        </button>
                                                      </button>
                                                    )
                                                  )}
                                                  <div className="w-100 d-block">
                                                    <button
                                                      className="btnsaveoption"
                                                      onClick={() =>
                                                        showDrop(false)
                                                      }
                                                    >
                                                      Done
                                                    </button>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          ) : null}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label customlabelloginpagesetting"
                                        >
                                          Phone Number
                                        </label>
                                        <input
                                          //type="number"
                                          className="form-control custominputtypeloginpagesetting"
                                          placeholder="Enter Phone Number"
                                          //maxLength={15}
                                          name="phonenumber"
                                          value={phonenumber}
                                          onChange={(e) => {
                                            setPhoneNumber(e.target.value);
                                          }}
                                        />
                                        {error.phonenumber && (
                                          <span className="customvalidationdesignlogin">
                                            {error.phonenumber}
                                          </span>
                                        )}
                                      </div>

                                      <div className="col-md-4">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label customlabelloginpagesetting"
                                        >
                                          Studio image
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control custominputtypeloginpagesetting"
                                          //placeholder="Phone Number"
                                          name="studioImage"
                                          onChange={handleEditImage}
                                          accept="image/*"
                                        />
                                        {/* {error.studioImage && (
                                          <span className="customvalidationdesignlogin">
                                            {error.studioImage}
                                          </span>
                                        )} */}
                                      </div>
                                      {studioImage !== "" ? (
                                        <>
                                          <img
                                            className="cropedimg"
                                            alt="cropdimg"
                                            src={
                                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                                              studioImage
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                    <div
                                      id="cropperModal"
                                      style={{
                                        display:
                                          image3 !== "" ? "block" : "none",
                                      }}
                                    >
                                      <div className="modal-content">
                                        <span
                                          onClick={() => setImage3("")}
                                          className="close"
                                        >
                                          &times;
                                        </span>
                                        {image3 !== "" ? (
                                          <Cropper
                                            style={{
                                              height: 1055,
                                              width: "100%",
                                            }}
                                            zoomTo={0.5}
                                            initialAspectRatio={1}
                                            preview=".img-preview"
                                            src={image3}
                                            viewMode={1}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false}
                                            onInitialized={(instance) => {
                                              setCropper(instance);
                                            }}
                                            guides={true}
                                          />
                                        ) : null}
                                        <button
                                          type="button"
                                          className="cropbtnbottom"
                                          style={{ float: "right" }}
                                          onClick={getCropData1}
                                        >
                                          Upload Image
                                        </button>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-12">
                                        <h4 className="settingeditstudioprofileheading  customopertionmaintextheading">
                                          Operational Hours
                                          {/* ({timeZone}) */}
                                        </h4>
                                      </div>
                                      <div className="col-md-6">
                                        <h4 className="settingeditstudioprofileheading  customopertionmaintextheading backoutdaysstudio">
                                          For blackout day , select a date from
                                          here
                                        </h4>
                                      </div>
                                      <div className="col-md-6 datepicker-col-6 backoutdaysstudio ">
                                        <div className="datepickerstudioend">
                                          <DatePicker
                                            render={(value, openCalendar) => {
                                              return (
                                                <>
                                                  {value ? (
                                                    <div className="datepickereditstudio-col-6">
                                                      {value}
                                                    </div>
                                                  ) : (
                                                    <div>{value}</div>
                                                  )}

                                                  <img
                                                    onClick={openCalendar}
                                                    className="imgcalndr"
                                                    src="images/calendar.png"
                                                  />
                                                </>
                                              );
                                            }}
                                            value={BlackOut}
                                            onChange={(date) => {
                                              setBlackout(date);
                                              // console.log("ghgh", date);
                                            }}
                                            multipleRangeSeparator=","
                                            format="DD/MM/YYYY"
                                            multiple={true}
                                            minDate={new Date()}
                                          ></DatePicker>
                                        </div>
                                      </div>

                                      {calendar.map((item, key) => (
                                        <>
                                          <div className="col-md-6" key={key}>
                                            <div
                                              className="studiotimeinfo d-flex calendarstudio"
                                              key={key}
                                            >
                                              <h4 className="w-50 customlabelloginpagesetting">
                                                {item.day}
                                              </h4>
                                              <div className="form-check form-switch w-50 ">
                                                {item.check === "1" ? (
                                                  <input
                                                    className="form-check-input customtooglebtneditprofile"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={true}
                                                    onChange={() =>
                                                      handleChangeDay(item.id)
                                                    }
                                                  />
                                                ) : (
                                                  <input
                                                    className="form-check-input customtooglebtneditprofile"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={false}
                                                    onChange={() =>
                                                      handleChangeDay(item.id)
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                            {
                                              item.check === "1" ? (
                                                <div className="">
                                                  <div className="studiotimeinfo d-flex customtoggledropdowndetail">
                                                    <div className="col-md-4 customwidthsetting">
                                                      <h6 className="toggledropdowninnerinfotext">
                                                        Select Time
                                                      </h6>
                                                    </div>
                                                    <div className="col-md-4 custominputwidth">
                                                      <input
                                                        type="time"
                                                        className="form-control calendartimehours "
                                                        placeholder="Phone Number"
                                                        onChange={(e) => {
                                                          // console.log(
                                                          //   e.target.value
                                                          // );
                                                          let i =
                                                            calendar.findIndex(
                                                              (N) =>
                                                                N.id === item.id
                                                            );
                                                          // console.log(i);
                                                          let data1 = calendar;
                                                          data1[
                                                            i
                                                          ].available_from =
                                                            e.target.value;
                                                          // console.log(data1);
                                                          setCalendar([
                                                            ...data1,
                                                          ]);
                                                        }}
                                                        value={
                                                          item.available_from
                                                        }
                                                      />
                                                    </div>

                                                    <div className="col-md-4 custominputwidth">
                                                      <input
                                                        type="time"
                                                        className="form-control calendartimehoursend "
                                                        placeholder="Phone Number"
                                                        onChange={(e) => {
                                                          // console.log(
                                                          //   e.target.value
                                                          // );
                                                          let i =
                                                            calendar.findIndex(
                                                              (N) =>
                                                                N.id === item.id
                                                            );
                                                          // console.log(i);
                                                          let data1 = calendar;
                                                          let end =
                                                            e.target.value?.split(
                                                              ":"
                                                            );
                                                          let start =
                                                            data1[
                                                              i
                                                            ].available_from?.split(
                                                              ":"
                                                            );

                                                          if (
                                                            end[0] < start[0]
                                                          ) {
                                                            // alert(
                                                            //   "please select end time greater than start time"
                                                            // );
                                                            Swal.fire(
                                                              "please select end time greater than start time"
                                                            );
                                                          } else if (
                                                            end[0] === start[0]
                                                          ) {
                                                            if (
                                                              end[1] < start[1]
                                                            ) {
                                                              // alert(
                                                              //   "please select end time greater than start time"
                                                              // );
                                                              Swal.fire(
                                                                "please select end time greater than start time"
                                                              );
                                                            }
                                                          } else {
                                                            data1[
                                                              i
                                                            ].available_to =
                                                              e.target.value;
                                                            // console.log(data1);
                                                            setCalendar([
                                                              ...data1,
                                                            ]);
                                                          }
                                                        }}
                                                        value={
                                                          item.available_to
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null
                                              // <> <p>closed</p> </>
                                            }
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                  <button
                                    className="savebtnsetting mt-3"
                                    type="button"
                                    onClick={() => OnEditStudioProfile()}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {pending === 1 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <PastBooking id={studioid}></PastBooking>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {pending === 2 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <CancelledBooking
                                        id={studioid}
                                      ></CancelledBooking>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {pending === 3 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <ConfirmedBooking
                                        id={studioid}
                                      ></ConfirmedBooking>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {pending === 4 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <PendingBooking
                                        id={studioid}
                                      ></PendingBooking>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {pending === 5 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <FAQ></FAQ>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {/* {pending === 6 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <GetVerified />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null} */}
                            {pending === 7 ? (
                              <div className="col-md-9">
                                <div className="settingmainlinksbuttondetailboxright">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <ChangeAddress
                                        id={studioid}
                                        userid={user}
                                        loading={setLoading2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer id={studioid} userid={user} />
        <div
          className={"modal fade opacity-animate"}
          id="exampleModal3"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Studio Address</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <ChangeAddress id={studioid} userid={user} />
            </div>
          </div>
        </div>
        <div
          className="modal fade opacity-animate"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Profile</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        value={firstname}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="First Name"
                        name="firstname"
                      />

                      {error.firstname && (
                        <span className="customvalidationdesignlogin">
                          {error.firstname}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        value={lastname}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Last Name"
                        name="lastname"
                      />

                      {error.lastname && (
                        <span className="customvalidationdesignlogin">
                          {error.lastname}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        value={email}
                        name="email"
                        disabled={true}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Enter Email"
                      />
                      {error.email && (
                        <span className="customvalidationdesignlogin">
                          {error.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Phone Number
                      </label>
                      <input
                        //type="number"
                        // maxLength={17}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setError("");
                        }}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Phone Number"
                      />

                      {error.phone && (
                        <span className="customvalidationdesignlogin">
                          {error.phone}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Profile Image
                      </label>
                      <input
                        type="file"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Phone Number"
                        onChange={handleProfile}
                        accept="image/*"
                      />

                      {error.profile && (
                        <span className="customvalidationdesignlogin">
                          {error.profile}
                        </span>
                      )}
                    </div>

                    {profile !== "" ? (
                      <>
                        <div className="editprofileimgcrop">
                          <img
                            className="cropedimg"
                            alt="cropdimg"
                            src={
                              `https://stubies.s3.us-east-2.amazonaws.com/` +
                              profile
                            }
                          />
                          <span
                            className="imagespandel"
                            onClick={() => DeleteImage()}
                          >
                            X
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div
                    id="cropperModal"
                    style={{
                      display: image1 !== "" ? "block" : "none",
                    }}
                  >
                    <div className="modal-content">
                      <span onClick={() => setImage1("")} className="close">
                        &times;
                      </span>
                      {image1 !== "" ? (
                        <Cropper
                          style={{ height: 470, width: "100%" }}
                          zoomTo={0.5}
                          initialAspectRatio={1}
                          preview=".img-preview"
                          src={image1}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          onInitialized={(instance) => {
                            setCropper(instance);
                          }}
                          guides={true}
                        />
                      ) : null}
                      <button
                        type="button"
                        className="cropbtnbottom"
                        style={{ float: "right" }}
                        onClick={getCropData}
                      >
                        Upload Image
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup"></div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => OnEditProfile()}
                  // type="submit"
                  className="btn btn-primary customsavebtnchangespopup btn-ownwidth"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {visible !== false ? (
          <div
            className={"modal fade opacity-animate"}
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content customaddstudiomodalsetting">
                <div className="modal-header">
                  <div className="col-md-6">
                    <h4 className="addstudiodetailpopmainhaeding">
                      Add studio detail
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4 className="addstudiodetailpopmainhaeding">Address</h4>
                  </div>
                  <button
                    type="button"
                    className="btn-close customaddstudiopopupclosebuttonsetting "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Studio Name
                        </label>
                        <input
                          type="text"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="stubiestudio"
                          name="studio_name"
                          value={formValues.studio_name}
                          onChange={handleData}
                        />
                        {error.studio_name && (
                          <span className="customvalidationdesignlogin">
                            {error.studio_name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Street
                        </label>
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleChange1}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                name="street"
                                value={street}
                                {...getInputProps({
                                  placeholder: "Enter Street",
                                  className:
                                    "form-control custominputtypeloginpagesetting",
                                })}
                                autoComplete="street-address"
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Studio Type
                        </label>
                        <button
                          className="form-control custominputtypeloginpagesetting selectbuttontype"
                          aria-label="Default select example"
                          onClick={() => showDrop(true)}
                          name="studio_type1"
                          value={studio_type1}
                          placeholder="Studio Types"
                          // multiple
                        >
                          {studio_type1 ? studio_type1 : "Select Studio Type"}
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Enter City"
                          name="city"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            if (clicked === true) {
                              setCityErr("");
                            }
                          }}
                          autoComplete="address-level2"
                        />
                        {cityerr && (
                          <span className="customvalidationdesignlogin">
                            City is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="">
                        {studioType !== undefined > 0 ? (
                          <>
                            {drop === true ? (
                              <>
                                {studioType.map((item, key) => (
                                  <button
                                    type="button"
                                    onClick={() => handleChange(item)}
                                    key={key}
                                    value={item.studio_type}
                                    className="studiooption"
                                  >
                                    {item.selected === true ? (
                                      <img
                                        style={{
                                          width: 20,
                                          height: 20,
                                          resizeMode: "contain",
                                        }}
                                        src="images/tickimg.png"
                                      />
                                    ) : null}

                                    <button
                                    // type="button"
                                    // onClick={() => handleChange(item)}
                                    // key={key}
                                    // value={item.studio_type}
                                    >
                                      {item.studio_type}
                                    </button>
                                  </button>
                                ))}
                                <div className="w-100 d-block">
                                  <button
                                    className="btnsaveoption"
                                    onClick={() => showDrop(false)}
                                  >
                                    Done
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {studiotypeerr && (
                          <span className="customvalidationdesignlogin">
                            Studio Type is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Phone Number
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry={countryname.countryCode}
                          limitMaxLength={17}
                          name="value"
                          value={value}
                          onChange={(e) => {
                            setValue(e);
                            setError("");
                          }}
                        />

                        {error.value && (
                          <span className="customvalidationdesignlogin">
                            {error.value}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Enter State"
                          name="state"
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                            if (clicked === true) {
                              setStateErr("");
                            }
                          }}
                          autoComplete="address-level1"
                        />
                        {stateerr && (
                          <span className="customvalidationdesignlogin">
                            State is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Zipcode
                        </label>
                        <input
                          type="text"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Enter Zipcode"
                          name="zipcode"
                          value={zipcode}
                          autoComplete="postal-code"
                          onChange={(e) => {
                            setZipcode(e.target.value);
                            if (clicked === true) {
                              setZipcodeErr("");
                            }
                          }}
                        />
                        {zipcodeerr && (
                          <span className="customvalidationdesignlogin">
                            Zipcode is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Studio Profile Image
                        </label>
                        <input
                          type="file"
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="Phone Number"
                          name="profile_image"
                          onChange={handleChange2}
                          accept="image/*"
                        />

                        {/* {imageerr && (
                          <span className="customvalidationdesignlogin">
                            Image is required
                          </span>
                        )} */}
                      </div>
                      {studiourl !== "" ? (
                        <>
                          <div className="editprofileimgcrop">
                            <img
                              className="cropedimg"
                              alt="cropdimg"
                              src={
                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                studiourl
                              }
                            />
                            <span
                              className="imagespandel"
                              onClick={() => DeleteStudioImage()}
                            >
                              X
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div
                      id="cropperModal"
                      style={{
                        display: image !== "" ? "block" : "none",
                      }}
                    >
                      <div className="modal-content">
                        <span onClick={() => setImage("")} className="close">
                          &times;
                        </span>
                        {image !== "" ? (
                          <Cropper
                            style={{ height: 570, width: "100%" }}
                            zoomTo={0.5}
                            initialAspectRatio={16 / 9}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                        ) : null}
                        <button
                          className="cropbtnbottom"
                          style={{ float: "right" }}
                          onClick={getCropData}
                        >
                          Upload Image
                        </button>
                        {/* </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="eyegroup">
                        <label
                          htmlFor=""
                          className="form-label customlabelloginpagesetting"
                        >
                          Description
                        </label>
                        <div className="form-floating">
                          <textarea
                            className="form-control custom-textarea"
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            name="description"
                            value={formValues.description}
                            onChange={handleData}
                          ></textarea>
                          {/* <label htmlFor="floatingTextarea2">Description</label> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => myData()}
                    className="btn btn-primary customsavebtnchangespopup btn-ownwidth"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    </>
  );
}
export default MyProfile;
