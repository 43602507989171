import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { AcceptBookingApi, DeclineApi } from "../Apis";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import moment from "moment";
function BookingDetails() {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [studioId, setStudioId] = useState("");
  const [namebooking, setNamebooking] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();

  const DeclineBookig = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    let local = localStorage.getItem("userid");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      booking_id: String(data.booking_id),
      userid: local,
      studio_id: studioId,
      reason_of_cancellation: "Slots not available",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DeclineApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setIsLoading(false);
          setTimeout(() => {
            //window.location.reload();
            navigate("/myprofile", {
              state: { pending: 2, studioId: studioId },
            });
            // navigate(-1);
          }, 3000);
          setMessage(result.message);
        } else {
          setTimeout(() => {
            // window.location.reload();
          }, 3000);
          setIsLoading(false);
          setMessage(result.message);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setIsLoading(false);
      });
  };
  const chat = () => {
    // console.log(JSON.stringify(data.customer_pic));
    navigate("/myprofile", {
      state: {
        chatImage:
          data.customer_pic !== ""
            ? "https://stubies.s3.us-east-2.amazonaws.com/" + data.customer_pic
            : "https://stubies.s3.us-east-2.amazonaws.com/b731c60c-c7de-46cd-87fc-a51e3baf3afd/StudioPic/1683117465693",
        chatname: data.customer_name,
        chatid: data.customer_id,
        studioId: studioId,
      },
    });
  };
  const toast = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to decline booking!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Decline it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        Swal.fire(DeclineBookig(), "Booking Decline.", "success");
      }
    });
  };

  const AcceptBooking = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      booking_id: String(data.booking_id),
      userid: local,
      studio_id: studioId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AcceptBookingApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setIsLoading(false);
          setTimeout(() => {
            navigate("/myprofile", {
              state: { pending: 3, studioId: studioId },
            });
          }, 3000);
          setMessage(result.message);
        } else {
          setTimeout(() => {}, 3000);
          setIsLoading(false);
          setMessage(result.message);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        setIsLoading(false);
      });
  };

  const Accept = () => {
    AcceptBooking();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let Detail = location.state.data;
    console.log("nooooooo", Detail);
    let studioid = location.state.studioid;
    let name = location.state.name;
    setData(Detail);
    setStudioId(studioid);
    setNamebooking(name);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Header />
      <div className="stubieprofilebannersection">
        <div className="container-fluid">
          <h1 className="changepasswordmainheadingsetting">Booking Detail</h1>
        </div>
      </div>
      <div className="bookingdeatailmainsection bookingdetails-studio  pagenewclass">
        <div className="container">
          {data !== undefined ? (
            <>
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Studio Detail</h5>
                      <div className="bookingstudiodetail">
                        <div className="stubiestudiodetailimagebox">
                          {data.banner !== "" ? (
                            <img
                              src={
                                `https://stubies.s3.us-east-2.amazonaws.com/` +
                                data.banner
                              }
                            />
                          ) : (
                            <img src="images/profiledefault.jpg" />
                          )}
                        </div>

                        <div className="stubiestudiotextdetail">
                          <h6>{data.studio_name}</h6>
                          <h4>{data.location}</h4>
                        </div>
                      </div>
                      <div className="stubiebookingroomname">
                        <h2>Room Name</h2>
                        <p>{data.room_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Customer Detail</h5>
                      <div className="stubiebookingroomname">
                        <h2>Customer Name</h2>
                        <p>{data.customer_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Date and Time</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="stubiebookingroomname">
                            <h2>Start Date and Time</h2>
                            <p>
                              {moment(data.start_time, ["HH.mm"]).format(
                                "hh:mm a"
                              )}{" "}
                              {"  "}
                              {moment(data.date, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )}
                              {/* {data.date} */}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="stubiebookingroomname">
                            <h2>End Date and Time</h2>
                            <p>
                              {moment(data.end_time, ["HH.mm"]).format(
                                "hh:mm a"
                              )}{" "}
                              {"  "}
                              {moment(data.date, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )}
                              {/* {data.date} */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bookinginnerdetailsection">
                      <h5>Bill Details</h5>
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Booking Price</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          <h6>
                            $
                            {new Intl.NumberFormat("en-US").format(
                              Number(data?.stubie_fee)?.toFixed(2)
                            )}
                            {/* {Number(data?.stubie_fee)?.toFixed(2)} */}
                            Per Hour
                          </h6>
                        </div>
                      </div>
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Addons Price</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice ">
                          <h6>
                            ${/* {data.addonprice} */}
                            {new Intl.NumberFormat("en-US").format(
                              data.addonprice
                            )}
                          </h6>
                        </div>
                      </div>
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">Earning Price</h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          <h6>
                            ${/* {data.earnings} */}
                            {new Intl.NumberFormat("en-US").format(
                              data.earnings
                            )}
                          </h6>
                        </div>
                      </div>
                      {/* <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting">
                            Number of Hours
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice">
                          <h6>
                            {" "}
                            {moment(data.end_time, ["HH.mm"]).format("hh:mm a")}
                            -
                            {moment(data.start_time, ["HH.mm"]).format(
                              "hh:mm a"
                            )}
                          </h6>
                        </div>
                      </div> */}
                      <div className="bookingstudiodetail d-flex">
                        <div className="stubiestudiotextdetail">
                          <h6 className="bookingpricesetting customtotalpricesetting">
                            Total Price
                          </h6>
                        </div>
                        <div className="stubiestudiotextdetailprice  ">
                          <h6 className="customtotalmoneysetting">
                            ${/* {data.actual_price} */}
                            {new Intl.NumberFormat("en-US").format(
                              data.actual_price
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bookingchatbtnleft">
                      <button
                        onClick={() => chat()}
                        className=" roomaddbuttonsave chatbtn"
                      >
                        <img src="images/chatbookingpage.png" /> Chat
                      </button>
                    </div>
                  </div>
                  {namebooking === "canceled" ||
                  namebooking === "confirmed" ||
                  namebooking === "pastbooking" ? null : (
                    <div className="col-md-6">
                      <div className="bookingdeatailfinalbtnright">
                        <ul>
                          <li>
                            <button
                              type="button"
                              onClick={() => toast()}
                              className="bookingdeclinebtnsetting"
                            >
                              Decline
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() => Accept()}
                              className="bookingdeclinebtnsetting custombookingapprovebtn"
                            >
                              Approve
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </>
          ) : null}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default BookingDetails;
