import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import Swiper styles
import "swiper/css";

import { Autoplay, Pagination, Navigation } from "swiper";

// import required modules
// import { Navigation } from "swiper";
import bootstrap from "bootstrap";
import Loader from "../Loader/Loader";
import {
  CustomerRegistrationApi,
  customerUploadImageApi,
  registerApi,
  uploadimageApi,
} from "../Apis";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function SignUpCustomer() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    confirmpassword: "",
  });
  const [value, setValue] = useState("");
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countryname, setCountry] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfile] = useState("");
  const [govtId, setGovtId] = useState("");
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [imageChange1, setImageChange1] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [checkbox1, setCheckBox1] = useState(false);
  const [cropper, setCropper] = useState();
  const [file, setFile] = useState("");
  const [type1, setType1] = useState("password");
  const [icon1, setIcon1] = useState("fa fa-eye-slash");
  const [email, setEmail] = useState("");

  let navigate = useNavigate();
  let location = useLocation();
  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError("");
  };

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye" ? setIcon("fa fa-eye-slash") : setIcon("fa fa-eye");
  };
  const show1 = () => {
    type1 === "password" ? setType1("text") : setType1("password");
    icon1 === "fa fa-eye" ? setIcon1("fa fa-eye-slash") : setIcon1("fa fa-eye");
  };
  const myData = (e) => {
    //console.log("hello submit");
    e.preventDefault();
    //setIsLoading(true);
    setError(validate(formValues));
  };

  //form validations
  const validate = (formValues) => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const regexp = /^\S*$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    }
    if (!formValues.firstname) {
      error.firstname = "First Name  is required.";
    }
    if (!formValues.lastname) {
      error.lastname = "Last Name is required.";
    }
    if (!formValues.password) {
      error.password = "Password is required.";
    } else if (formValues.password.length < 6) {
      error.password = "Password is not valid.";
    } else if (!passwordRegex.test(formValues.password)) {
      error.password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }
    if (!formValues.confirmpassword) {
      error.confirmpassword = " Confirm Password is required.";
    } else if (formValues.confirmpassword.length < 6) {
      error.confirmpassword = "Confirm Password is not valid.";
    } else if (formValues.confirmpassword !== formValues.password) {
      error.confirmpassword = "Confirm Password is not same.";
    }

    if (!value) {
      error.value = "Phone Number is required.";
    }
    // else if (value.length > 15) {
    //   error.value = "Phone Number  is not valid.";
    // }
    else if (value.length < 9) {
      error.value = "Phone Number  is not valid.";
    }
    // if (!govtId) {
    //   error.govtId = "Government ID is required.";
    // }
    if (checkbox === false) {
      error.checkbox = "Terms and Conditions are required.";
    }
    if (checkbox1 === false) {
      error.checkbox1 = "Terms and Conditions are required.";
    }

    // if(!value){
    //  setValueErr(true)
    // }
    // else{
    //   setValueErr(false);
    // }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      CustomerReg();
    }
    return error;
  };

  const CustomerReg = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      email: email,
      password: formValues.password,
      country_code: countryname.countryCode,
      phoneno: value,
      //governmentid: govtId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CustomerRegistrationApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result.error === "0") {
          setShowModal(true);
          setIsLoading(false);

          setTimeout(() => {
            navigate("/login");
          }, 3000);

          setMessage(result.message);

          //alert(response.data.message);
        } else {
          setShowModal(true);
          setIsLoading(false);

          setTimeout(() => {
            //navigate("/login");
          }, 3000);

          setMessage(result.message);
          //alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const handleProfile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      //console.log(reader.result);
      setImage1(reader.result);
      setImageChange1(true);
      //setFileType("ProfilePic");
    };

    reader.readAsDataURL(files[0]);
  };
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      govtImage(newFile);
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const govtImage = (newFile) => {
    var formdata = new FormData();
    formdata.append("file", newFile);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(customerUploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImage("");
        setImage1("");
        setImage2("");
        setGovtId(result.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setCountry(json);
      });
    if (location?.state?.value) {
      setValue(location?.state?.value);
      //console.log("sasdadfgh", location?.state?.value);
    }
    if (location?.state?.data) {
      setFormValues(location?.state?.data);
    }
    if (location?.state?.checkbox) {
      setCheckBox(location?.state?.checkbox);
    }
    if (location?.state?.checkbox1) {
      setCheckBox1(location?.state?.checkbox1);
    }
    if (location?.state?.Country) {
      setCountry(location?.state?.Country);
    }
    if (location?.state?.email) {
      setEmail(location?.state?.email);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="loginmainsection ">
        <div className="row g-0 flex-direction-row">
          <div className="col-md-6 boxmainheight">
            <div className="loginleftsection">
              <div className="mainloginlogobox">
                <Link to="/">
                  <img src="images/mainlogo.png" />
                </Link>
              </div>
              <h2 className="customcreateaccountheadingsetting">
                Create Account
              </h2>
              <div
                className="tistpop"
                style={{
                  visibility: showModal === true ? "visible" : "hidden",
                }}
              >
                {message && (
                  <span className="customvalidationdesignlogin">{message}</span>
                )}
              </div>
              <form onSubmit={myData}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="First name"
                        name="firstname"
                        value={formValues.firstname}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />
                      {error.firstname && (
                        <span className="customvalidationdesignlogin">
                          {error.firstname}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="Last name"
                        name="lastname"
                        value={formValues.lastname}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />
                      {error.lastname && (
                        <span className="customvalidationdesignlogin">
                          {error.lastname}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Email
                      </label>

                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError("");
                        }}
                        onKeyDown={handleKeyPress}
                        value={email}
                        type="email"
                        name=""
                        // type="email"
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="stubie@example.com"
                        // name="email"
                        // value={formValues.email}
                        // onChange={handleData}
                      />
                      {error.email && (
                        <span className="customvalidationdesignlogin">
                          {error.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Password
                      </label>
                      <div className="iconbox2">
                        <i onClick={show} className={icon}></i>
                      </div>
                      <input
                        type={type}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="**********"
                        name="password"
                        value={formValues.password}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />

                      {error.password && (
                        <span className="customvalidationdesignlogin">
                          {error.password}
                        </span>
                      )}
                      <span>
                        * Password must have at least 1 uppercase letter, 1
                        lowercase letter, 1 special character and 1 number.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Confirm Password
                      </label>
                      <div className="iconbox2">
                        <i onClick={show1} className={icon1}></i>
                      </div>
                      <input
                        type={type1}
                        className="form-control custominputtypeloginpagesetting"
                        placeholder="**********"
                        name="confirmpassword"
                        value={formValues.confirmpassword}
                        onChange={handleData}
                        onKeyDown={handleKeyPress}
                      />

                      {error.confirmpassword && (
                        <span className="customvalidationdesignlogin">
                          {error.confirmpassword}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="eyegroup">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label customlabelloginpagesetting"
                      >
                        Phone Number
                      </label>

                      <PhoneInput
                        placeholder="Enter phone number"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={countryname.countryCode}
                        limitMaxLength={17}
                        name="value"
                        value={value}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          setValue(e);
                          setError("");
                        }}
                      />

                      {error.value && (
                        <span className="customvalidationdesignlogin">
                          {error.value}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input customcheckedboxsetting"
                        type="checkbox"
                        value={checkbox}
                        id="flexCheckChecked"
                        name="checkbox"
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          setCheckBox(e);
                          setError("");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      />
                      Confirm you agree to the{" "}
                      <Link
                        to={`/termscustomer`}
                        state={{
                          formValues: formValues,
                          value: value,
                          checkbox: checkbox,
                          checkbox1: checkbox1,
                          countryname: countryname,
                          email: email,
                        }}
                      >
                        Terms and Conditions.
                      </Link>
                    </div>
                    {error.checkbox && (
                      <span className="customvalidationdesignlogin">
                        {error.checkbox}
                      </span>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input customcheckedboxsetting"
                        type="checkbox"
                        value={checkbox1}
                        id="flexCheckChecked1"
                        name="checkbox1"
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          setCheckBox1(e);
                          setError("");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      />
                      Confirm the account holder is
                      <Link
                        to={`/termscustomer`}
                        state={{
                          formValues: formValues,
                          value: value,
                          checkbox: checkbox,
                          checkbox1: checkbox1,
                          countryname: countryname,
                          email: email,
                        }}
                      >
                        at least 18 years old.
                      </Link>
                    </div>
                    {error.checkbox1 && (
                      <span className="customvalidationdesignlogin">
                        {error.checkbox1}
                      </span>
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="mainloginbutton">
                      <div className="loginbuttonlink">
                        <button type="submit">SIGN UP</button>
                      </div>
                      <h5>
                        Already have an account?{" "}
                        <Link to="/login">
                          <span>Log In</span>
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="loginrighttsection">
              <div className="loginrightinnertextsectionupper">
                <Swiper
                  spaceBetween={30}
                  autoplay={{
                    delay: 2800,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  //navigation
                  //pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  <SwiperSlide>
                    <div className="sliderwidthtext">
                      <h1>
                        Booking your next <span>session</span> <br />
                        is now at your fingertips
                      </h1>
                      <p>
                        Unlock your creative potential within extraordinary
                        studio spaces.Discover - Reserve - Create for all
                        creatives such as  musicians, photographers, dancers,
                        filmmaker more.  Elevate your artistry with
                        state-of-the-art studios for musicians, photographers,
                        dancers, and filmmakers.
                      </p>
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1>
                      Modern <span>Interior</span> <br />
                      Design Studio
                    </h1>
                    <p>
                      Unleash creativity in extraordinary studio spaces.
                      Discover, book, and create in inspiring sanctuaries at
                      Stubie.
                      <br />
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignUpCustomer;
