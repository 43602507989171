import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { loginApi } from "../Apis";
import app from "../config";
import Loader from "../Loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { getMessaging, getToken } from "firebase/messaging";
import { Autoplay, Pagination, Navigation } from "swiper";
import bootstrap from "bootstrap";

function Login() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");

  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  let navigate = useNavigate();
  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye" ? setIcon("fa fa-eye-slash") : setIcon("fa fa-eye");
  };

  const myData = (e) => {
    //console.log("hello submit");
    //e.preventDefault();
    setError(validate(formValues));
  };

  //form validations
  const validate = (formValues) => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;

    if (!formValues.email) {
      error.email = "email is required.";
    } else if (!reg.test(formValues.email)) {
      error.email = "Invalid email .";
    }

    if (!formValues.password) {
      error.password = "password is required.";
    } else if (formValues.password.length < 6) {
      error.password = "password is not valid.";
    }
    // else if (!passwordRegex.test(formValues.password)) {
    //   error.password =
    //     "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    // }
    if (Object.keys(error).length === 0) {
      //console.log(Object.keys(error).length);
      CallApi();
    }
    return error;
  };
  useEffect(() => {
    // const messaging = getMessaging(app);
    // getToken(messaging, {
    //   vapidKey:
    //     "BDgmge-hCun8D-_nTBsgF8dxIzl9d6EylhRFm7jcXBrSreywnwpDWKB2dUDwsaodRgoS0agPs1SeSQCqDGssOpI",
    // })
    //   .then((currentToken) => {
    //     console.log("token", currentToken);
    //     if (currentToken) {
    //       // Send the token to your server and update the UI if necessary
    //       // ...
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       );
    //       // ...
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //     // ...
    //   });
  }, []);

  const CallApi = () => {
    setIsLoading(true);
    var data = JSON.stringify({
      email: formValues.email,
      password: formValues.password,
      devicetoken: "",
      devicetype: "",
    });

    var config = {
      method: "post",
      url: loginApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.error === "0" && response.data.role === "Studio") {
          navigate("/myprofile");
          //alert(response.data.message);
          localStorage.setItem("email", formValues.email);
          localStorage.setItem("role", response.data.role);
          handle(response.data.data);
          //console.log("ghjgjg", response.data.data.profile_pic);
          setMessage(response.data.message);
        } else if (
          response.data.error === "0" &&
          response.data.role === "Customer"
        ) {
          navigate("/customer");
          //alert(response.data.message);
          localStorage.setItem("email", formValues.email);
          localStorage.setItem("role", response.data.role);
          handle1(response.data.data);

          setMessage(response.data.message);
        } else if (response.data.error === "1") {
          setMessage(response.data.message);
        } else {
          setMessage(response.data.message);
        }
        setIsLoading(false);
        // else {
        //   //alert(response.data.message);
        //   setMessage(response.data.message);
        // }
      })
      .catch(function (error) {
        //console.log(error);
        setIsLoading(false);
      });
  };

  const rememberMe = (email, password) => {
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
  };

  const handle = (data) => {
    localStorage.setItem("email", data.email);
    localStorage.setItem("userid", data.userid);
    localStorage.setItem("profileid", data.profile_id);
    localStorage.setItem("phoneno", data.phoneno);
    localStorage.setItem("profilepic", data.profile_pic);
    localStorage.setItem("profilename", data.firstname);
  };
  const handle1 = (data) => {
    localStorage.setItem("email", data.email);
    localStorage.setItem("userid", data.userid);
    localStorage.setItem("profileid", data.profile_id);
    localStorage.setItem("phoneno", data.phoneno);
    localStorage.setItem("profilepic", data.profile_pic);
    localStorage.setItem("profilename", data.firstname);
    localStorage.setItem("govid", data.government_id);
    localStorage.setItem("customerId", data.stripe_customer_id);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="row g-0 flex-direction-row">
        <div className="col-md-6 loginleftheight">
          <div className="loginleftsection">
            <div className="mainloginlogobox">
              <Link to="/">
                <img src="images/mainlogo.png" />
              </Link>
            </div>
            <h2>Stubie Access</h2>
            <h6>Please fill your detail to access your account.</h6>

            <div className="row">
              {message && (
                <span className="customvalidationdesignlogin">{message}</span>
              )}
              <div className="col-md-12">
                <div className="eyegroup">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="stubie@example.com"
                    name="email"
                    value={formValues.email}
                    onChange={handleData}
                    onKeyDown={handleKeyPress}
                  />
                  {error.email && (
                    <span className="customvalidationdesignlogin">
                      {error.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="eyegroup">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label customlabelloginpagesetting"
                  >
                    Password
                  </label>
                  <div className="iconbox2">
                    <i onClick={show} className={icon}></i>
                  </div>
                  <input
                    type={type}
                    className="form-control custominputtypeloginpagesetting"
                    placeholder="**********"
                    name="password"
                    value={formValues.password}
                    onChange={handleData}
                    onKeyDown={handleKeyPress}
                  />

                  {error.password && (
                    <span className="customvalidationdesignlogin">
                      {error.password}
                    </span>
                  )}
                  {/* <span>
                    * Password must have at least 1 uppercase letter, 1
                    lowercase letter, 1 special character and 1 number.
                  </span> */}
                </div>
              </div>
              <div className="col-md-6 customrememberforgotwidth">
                <div className="form-check">
                  <input
                    className="form-check-input customcheckboxboxsetting"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    defaultChecked
                    onClick={rememberMe}
                  />
                  <label
                    className="form-check-label customcheckboxsetting"
                    htmlFor="flexCheckChecked"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <div className="col-md-6 customrememberforgotwidth">
                <div className="forgotpasswordbox">
                  <Link to="/forgot">Forgot Password?</Link>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mainloginbutton">
                  <div className="loginbuttonlink">
                    <button
                      type="button"
                      // onKeyPress={onKeyPress}
                      onClick={() => myData()}
                    >
                      Log in
                    </button>
                  </div>
                  <h5>Don’t have an account? Sign up</h5>
                </div>
              </div>
              <div className="col-md-12">
                <div className="studiocustomlinksbox">
                  <ul>
                    <li>
                      {status === false ? (
                        <Link
                          href="#"
                          to="/signup"
                          //className="active"
                          onClick={() => setStatus(false)}
                        >
                          Studio
                        </Link>
                      ) : (
                        <Link onClick={() => setStatus(false)}>Studio</Link>
                      )}

                      {/* <a href="#" className="active">
                            Studio
                          </a> */}
                    </li>

                    <li>
                      {status === false ? (
                        <Link
                          href="#"
                          to="/signupcustomer"
                          //className="active"
                          onClick={() => setStatus(false)}
                        >
                          Customer
                        </Link>
                      ) : (
                        <Link onClick={() => setStatus(false)}>Customer</Link>
                      )}
                    </li>

                    {/* <li>
                          <a href="#">Customer</a>
                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="loginrighttsection">
            <div className="loginrightinnertextsectionupper">
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  1000: {
                    slidesPerView: 1,
                  },
                }}
                spaceBetween={30}
                autoplay={{
                  delay: 2800,
                  disableOnInteraction: false,
                  speed: 5000,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                //navigation
                //pagination={{ clickable: true }}
                //scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide>
                  <div className="sliderwidthtext">
                    <h1>
                      Booking your next <span>session</span> <br />
                      is now at your fingertips
                    </h1>
                    <p className="text4">
                      Unlock your creative potential within extraordinary studio
                      spaces.Discover - Reserve - Create for all creatives such
                      as  musicians, photographers, dancers, filmmaker more. 
                      Elevate your artistry with state-of-the-art studios for
                      musicians, photographers, dancers, and filmmakers.
                    </p>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <h1>
                    Modern <span>Interior</span> <br />
                    Design Studio
                  </h1>
                  <p>
                    Unleash creativity in extraordinary studio spaces. Discover,
                    book, and create in inspiring sanctuaries at Stubie.
                    <br />
                    Elevate your artistry with state-of-the-art studios for
                    musicians, photographers, dancers, and filmmakers.
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <h1>
                    Modern <span>Interior</span> <br />
                    Design Studio
                  </h1>
                  <p>
                    Unleash creativity in extraordinary studio spaces. Discover,
                    book, and create in inspiring sanctuaries at Stubie.
                    <br />
                    Elevate your artistry with state-of-the-art studios for
                    musicians, photographers, dancers, and filmmakers.
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <h1>
                    Modern <span>Interior</span> <br />
                    Design Studio
                  </h1>
                  <p>
                    Unleash creativity in extraordinary studio spaces. Discover,
                    book, and create in inspiring sanctuaries at Stubie.
                    <br />
                    Elevate your artistry with state-of-the-art studios for
                    musicians, photographers, dancers, and filmmakers.
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <h1>
                    Modern <span>Interior</span> <br />
                    Design Studio
                  </h1>
                  <p>
                    Unleash creativity in extraordinary studio spaces. Discover,
                    book, and create in inspiring sanctuaries at Stubie.
                    <br />
                    Elevate your artistry with state-of-the-art studios for
                    musicians, photographers, dancers, and filmmakers.
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <h1>
                    Modern <span>Interior</span> <br />
                    Design Studio
                  </h1>
                  <p>
                    Unleash creativity in extraordinary studio spaces. Discover,
                    book, and create in inspiring sanctuaries at Stubie.
                    <br />
                    Elevate your artistry with state-of-the-art studios for
                    musicians, photographers, dancers, and filmmakers.
                  </p>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
