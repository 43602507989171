import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import bootstrap from "bootstrap";
import { logoutApi, logoutApiCus } from "../Apis";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";

function Header(props) {
  const [values, setValues] = useState({
    userid: "",
  });

  const [message, setMessage] = useState("");
  const [profile, setProfile] = useState("");
  const [data, setData] = useState([]);
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [item, setItems] = useState(null);

  const Locals = () => {
    let pic = localStorage.getItem("profilepic");
    setProfile(
      pic
        ? `https://stubies.s3.us-east-2.amazonaws.com/` + pic
        : "images/loginimgocon.png"
    );
    localStorage.getItem("email");
    let local = localStorage.getItem("userid");
    if (local) {
      setIsLoggedin(true);
    } else {
      setIsLoggedin(false);
    }
    //setItems(local);
    // CallApi(local);
    // console.log("iiiiidd",local);
  };

  const logOut = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    //console.log("ussss", local);
    var data = JSON.stringify({
      userid: local,
    });

    var config = {
      method: "post",
      url: logoutApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log("userddd", local);

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          setIsLoading(false);
          navigate("/");
          localStorage.clear();
          //alert(response.data.message);
          setMessage(response.data.message);
        } else {
          setIsLoading(false);
          localStorage.clear();
          navigate("/login");
          //alert(response.data.message);
          setMessage(response.data.message);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };
  const logOutCus = () => {
    setIsLoading(true);
    let local = localStorage.getItem("userid");
    //console.log("ussss", local);
    var data = JSON.stringify({
      userid: local,
    });

    var config = {
      method: "post",
      url: logoutApiCus,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log("userddd", local);

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          navigate("/");
          setIsLoading(false);
          localStorage.clear();
          //alert(response.data.message);
          setMessage(response.data.message);
        } else {
          setIsLoading(false);
          localStorage.clear();
          navigate("/login");
          // alert(response.data.message);
          setMessage(response.data.message);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  const toast = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(logOut(), "You are logout.", "successfully");
      }
    });
  };

  const getProfile = () => {
    let local = localStorage.getItem("userid");
    let profileId = localStorage.getItem("profileid");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: local,
      profile_id: profileId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "http://stubie.us-east-1.elasticbeanstalk.com/api/Studio/getprofile",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    let role = localStorage.getItem("role");
    setRole(role);

    Locals();

    //getProfile();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="headermainfollowustopbar">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="headerfollowusinner">
                <h6>Follow us:</h6>
                <ul className="followusicontopbar">
                  <li>
                    <a href="https://www.instagram.com/">
                      <img src="images/insticon.png" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/">
                      <img src="images/facebookicon.png" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/">
                      <img src="images/twittericon.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="headerfollowusinner-right">
                <ul>
                  <li>
                    <a href="mailto:info@stubie.com">
                      <img src="images/message.png" /> info@studio.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+123-422-3222">
                      <img src="images/phoneicon.png" /> +123-422-3222
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headermaintopbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="container-fluid">
                  <Link to="/" className="navbar-brand">
                    <img
                      src="images/mainlogo.png"
                      className="navbarlogoimagesetting"
                    />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse customnavbarmiddle"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav  customnavbarmiddle">
                      <li className="nav-item">
                        {role === "Customer" ? (
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to={`/customer`}
                            state={{ pending: 0 }}
                          >
                            Home
                          </Link>
                        ) : (
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/"
                          >
                            Home
                          </Link>
                        )}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/aboutus">
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contactus" className="nav-link">
                          Contact Us
                        </Link>
                      </li>
                      {!isLoggedin ? (
                        <li className="nav-item">
                          <Link
                            className="nav-link customloginnavbtnsetting "
                            to="/login"
                          >
                            <img
                              src="images/loginimgocon.png"
                              className="customloginlogoimgsetting"
                            />
                            Log In
                          </Link>
                        </li>
                      ) : (
                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle customloginnavbtnsetting"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={props.image ? props.image : profile}
                              //src="images/loginimgocon.png"
                              className="customloginlogoimgsetting"
                            />
                            {props.name
                              ? props.name
                              : localStorage.getItem("profilename")
                              ? localStorage.getItem("profilename")
                              : "Stubie"}
                          </a>
                          <ul
                            className="dropdown-menu customlogindropdownsetting"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              {role === "Customer" ? (
                                <>
                                  <Link
                                    className="dropdown-item  customlogindropdownlietting"
                                    //onClick={()=>{navigate("/customer",{state:{pending:1}})}}
                                    to={"/customer"}
                                    state={{ pending: 1 }}
                                  >
                                    My Profile
                                    <img
                                      src="images/logindropdownimg.png"
                                      className="logindropdownliarrow"
                                    />
                                  </Link>
                                </>
                              ) : (
                                <>
                                  <Link
                                    className="dropdown-item  customlogindropdownlietting"
                                    to="/myprofile"
                                  >
                                    My Profile
                                    <img
                                      src="images/logindropdownimg.png"
                                      className="logindropdownliarrow"
                                    />
                                  </Link>
                                </>
                              )}
                            </li>
                            <li>
                              <Link
                                className="dropdown-item customlogindropdownlietting"
                                to="/changepassword"
                              >
                                Change Password
                                <img
                                  src="images/logindropdownimg.png"
                                  className="logindropdownliarrow"
                                />
                              </Link>
                            </li>

                            <li>
                              {role === "Customer" ? (
                                <Link
                                  className="dropdown-item customlogindropdownlietting"
                                  //onClick={toast}
                                  onClick={logOutCus}
                                >
                                  Log Out
                                  <img
                                    src="images/logindropdownimg.png"
                                    className="logindropdownliarrow"
                                  />
                                </Link>
                              ) : (
                                <Link
                                  className="dropdown-item customlogindropdownlietting"
                                  //onClick={toast}
                                  onClick={logOut}
                                >
                                  Log Out
                                  <img
                                    src="images/logindropdownimg.png"
                                    className="logindropdownliarrow"
                                  />
                                </Link>
                              )}
                            </li>
                          </ul>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
