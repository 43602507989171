import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { earningApi } from "../Apis";
import Loader from "../Loader/Loader";

function Earnings(props) {
  const [sdates, setSDates] = useState(new Date());
  const [today_Earning, setTodayEarning] = useState([]);
  const [month_Earning, setMonthEarning] = useState([]);
  const [year_Earnings, setYearEarning] = useState([]);
  const [roomBooked, setRoomBooked] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentBooking, setRecentBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  const earningDetail = (date) => {
    setIsLoading(true);
    setLoading(true);
    props.loading(true);
    var myHeaders = new Headers();
    let d = moment(new Date()).format("DD/MM/YYYY");
    let local = localStorage.getItem("userid");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      date: d,
      pageno: 0,
      studio_id: props.id,
      userid: local,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(earningApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setTodayEarning(result);
        setMonthEarning(result);
        setYearEarning(result);
        setRoomBooked(result);
        setRecentBookings(result.last_Recent_Bookings);
        setLoading(false);
        setIsLoading(false);
        props.loading(false);

        // setIsLoading(false);
        // setLoading(false);
        // props.loading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        setLoading(false);
        props.loading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    earningDetail(new Date());
    window.scrollTo(0, 0);
  }, [props.id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="tab-pane fade show "
          id="earnings"
          role="tabpanel"
          aria-labelledby="earnings-tab"
        >
          <div className="bankdetailmainsectionbox earning-page">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <DatePicker
                    selected={sdates}
                    onChange={(e) => {
                      setSDates(e);
                      earningDetail(e);
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <div className="earningmainheading">
                    <h2>Earnings</h2>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="earningpricebox">
                    <h6>Total Earnings Today</h6>
                    {today_Earning.today_Earning ? (
                      <h3>${Number(today_Earning.today_Earning).toFixed(2)}</h3>
                    ) : (
                      <h3>$ 0</h3>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="earningpricebox">
                    <h6>Total Earnings Month</h6>
                    {month_Earning.month_Earning ? (
                      <h3>${Number(month_Earning.month_Earning).toFixed(2)}</h3>
                    ) : (
                      <h3>$ 0</h3>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="earningpricebox">
                    <h6>Total Earnings Year</h6>
                    {year_Earnings.year_Earnings ? (
                      <h3>${Number(year_Earnings.year_Earnings).toFixed(2)}</h3>
                    ) : (
                      <h3>$ 0</h3>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="earningpricebox">
                    <h6>Total Rooms Booked</h6>
                    {roomBooked.total_Room_Booked ? (
                      <h3>{roomBooked.total_Room_Booked}</h3>
                    ) : (
                      <h3> 0</h3>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="earningcompletedsession">
                    <h4>Completed Sessions</h4>
                  </div>
                </div>
                {loading ? (
                  <>
                    <div className="col-md-6">
                      <p>Loading....</p>
                    </div>
                  </>
                ) : (
                  <>
                    {recentBooking === undefined ? (
                      <>
                        <div>No Earnings</div>
                      </>
                    ) : (
                      <>
                        {recentBooking.map((item) => (
                          <>
                            <div className="col-md-6">
                              <div className="completedinnersectionbox">
                                <div className="completedinnerheadingleft">
                                  <h5>Name</h5>
                                  <h5>Studio Name</h5>
                                  <h5>Job Price</h5>
                                </div>
                                <div className="completedinnerdeatailright">
                                  <p>{item.customer_name}</p>
                                  <p>{item.studio_name}</p>
                                  <p>${item.total_price}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Earnings;
