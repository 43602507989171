import React, { useEffect, useState, useRef } from "react";

import Swal from "sweetalert2";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { editStudioDetailsApi, editStudioImageApi } from "../Apis";
import moment from "moment";
import Loader from "../Loader/Loader";
import { Modal } from "bootstrap";
import "cropperjs/dist/cropper.css";
import "react-phone-number-input/style.css";
import DatePicker from "react-multi-date-picker";

function EditStudioDetail(props) {
  const [studioName, setStudioName] = useState("");
  const [studiotypes, setStudioTypes] = useState("");
  const [city1, setCity1] = useState("");
  const [state1, setState1] = useState("");
  const [street1, setStreet1] = useState("");
  const [zipcode1, setZipcode1] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [studioImage, setStudioImage] = useState("");
  const [address1, setAddress1] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [lat1, setLat1] = useState("");
  const [lng1, setLng1] = useState("");
  const [calendar, setCalendar] = useState([]);
  const [drop, showDrop] = useState(false);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [calendarStatus, setCalendarStatus] = useState(false);
  const [studioType, setStudioType] = useState([]);
  const [visible, setVisible] = useState(true);
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image3, setImage3] = useState("");
  const [image2, setImage2] = useState("");
  const [imageChange1, setImageChange1] = useState(false);
  const [imageChange2, setImageChange2] = useState(false);
  const [imageChange3, setImageChange3] = useState(false);
  const [filetype, setFileType] = useState("");
  const [cropper, setCropper] = useState();
  const [description, setDescription] = useState("");
  const [BlackOut, setBlackout] = useState([]);
  const [studiotypeerr, setStudioTypeErr] = useState(false);
  const [imageerr, setImageErr] = useState(false);
  const [cityerr, setCityErr] = useState(false);
  const [zipcodeerr, setZipcodeErr] = useState(false);
  const [streeterr, setStreetErr] = useState(false);
  const [stateerr, setStateErr] = useState(false);
  const [studioId, setStudioId] = useState("");
  const [timeZone, setTimeZone] = useState("");

  const OnEditStudioProfile = () => {
    //setError(editStudioValidation);
    editStudioDetails();
  };

  const editStudioValidation = () => {
    let error = {};
    const re = /^[0-9\b]+$/;
    if (studioName === "") {
      error.studioName = "Studio Name is required.";
    }
    if (studiotypes === "") {
      error.studiotypes = "Studio Type is required.";
    }
    if (phonenumber === "") {
      error.phonenumber = "Phone Number is required.";
    }

    if (address1 === "") {
      error.address1 = "Addess is required.";
    }
    if (description === "") {
      error.description = "Description is required.";
    }
    if (city1 === "") {
      error.city1 = "City is required.";
    }
    if (zipcode1 === "") {
      error.zipcode1 = "Zipcode is required.";
    }
    // if (street1 === "") {
    //   error.street1 = "Street is required.";
    // }
    if (!lng1) {
      error.lng1 = "lng is required.";
    }
    if (!lat1) {
      error.lat1 = "lat is required.";
    }

    // console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      editStudioDetails();
    }
    return error;
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const editStudioDetails = () => {
    setIsLoading(true);
    props.loading(true);
    let local = localStorage.getItem("userid");
    let blackot = BlackOut.toString();
    //console.log("dkkd",blackot.split(","));
    let Black = blackot.split(",").map((n) => ({ day: n }));
    //console.log(Black);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      studio_id: studioId,
      userid: local,
      studio_type: studiotypes,
      profile_pic: studioImage,
      studio_name: studioName,
      venue: address1,
      street: street1,
      city: city1,
      state: state1,
      address: address1,
      zipcode: zipcode1,
      description: description,
      country_code: String(countrycode),
      phoneno: phonenumber,
      lat: String(lat1),
      lng: String(lng1),
      calendar: calendar,
      Blackoutdays: BlackOut.length !== 0 ? Black : [],
    });
    // console.log("hsghsg", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(editStudioDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        var myModalEl = document.getElementById("editroommodal");
        var modal = Modal.getInstance(myModalEl);
        modal.hide();
        setIsLoading(false);
        props.loading(false);
        props.onpress(result);
        Swal.fire(result.message);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        props.loading(false);
      });
  };

  const editStudioImage = (newFile) => {
    let local = localStorage.getItem("userid");
    var formdata = new FormData();

    formdata.append("studio_id", studioId);
    formdata.append("image", newFile);
    formdata.append("userid", local);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(editStudioImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImage3("");
        setStudioImage(result.data.profile_pic);
      })
      .catch((error) => console.log("error", error));
  };

  const handleEditImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      // console.log(reader.result);
      setImage3(reader.result);
      setImageChange3(true);
    };

    reader.readAsDataURL(files[0]);
  };

  const getCropData1 = () => {
    if (typeof cropper !== "undefined") {
      const newName = new Date().getTime();
      const newFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        newName
      );
      editStudioImage(newFile);
    }
  };
  const handleSelect2 = (address, name) => {
    // console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        // console.log(results[0].address_components);
        let addressObject = {
          city: results[0].address_components.find(
            (n) =>
              n.types.includes("locality") ||
              n.types.includes("administrative_area_level_2")
          )?.long_name,
          state: results[0].address_components.find((n) =>
            n.types.includes("administrative_area_level_1")
          )?.long_name,
          address: address,
          zipcode: results[0].address_components.find(
            (n) =>
              n.types.includes("postal_code") || n.types.includes("postal_town")
          )?.long_name,
          street: results[0].address_components.find(
            (n) => n.types.includes("route") || n.types.includes("sublocality")
          )?.long_name,

          cn: results[0].address_components.find((n) =>
            n.types.includes("country")
          )?.short_name,
        };

        setCity1(addressObject.city);
        setState1(addressObject.state);
        setAddress1(address);
        setZipcode1(addressObject.zipcode ? addressObject.zipcode : " ");
        setStreet1(
          addressObject.street ? addressObject.street : addressObject.address
        );
        setCountryCode(addressObject.cn);
        setCityErr("");
        setZipcodeErr("");
        setStreetErr("");
        setStateErr("");
        getLatLng(results[0]).then((latLng) => {
          setLat1(latLng.lat);
          setLng1(latLng.lng);
          // console.log("Success", latLng);
        });
      })

      .catch((error) => console.error("Error", error));
  };

  const handleChangeDay = (id) => {
    let i = calendar.findIndex((N) => N.id === id);
    //console.log(i);
    let data1 = calendar;
    data1[i].check = data1[i].check === "1" ? "0" : "1";

    // console.log(data1);
    setCalendar([...data1]);
  };
  const handleChangeAddress = (address) => {
    // console.log(address);
    setAddress1(address);
  };
  const handleChangeType = (data) => {
    const index = studioType.findIndex((n) => n.id === data.id);
    studioType[index].selected = !studioType[index].selected;
    setStudioType([...studioType]);

    setStudioTypes(
      studioType
        .filter((n) => n.selected === true)
        .map((n) => n.studio_type)
        .toString()
    );
    //setError({ ...error, [name]: "" });
    //setStudioTypeErr("");
  };
  useEffect(() => {
    setAddress1(props.address1);
    setZipcode1(props.zipcode1);
    setCity1(props.city1);
    setStudioName(props.studioName);
    setPhoneNumber(props.phonenumber);
    setState1(props.state1);
    setStreet1(props.street1);
    setStudioTypes(props.studiotypes);
    setStudioImage(props.studioImage);
    setStudioId(props.studioId);
    setCalendar(props.calendar);
    setTimeZone(props.timeZone);

    if (props.BlackOut.length !== 0) {
      let array = props.BlackOut;
      array = array.map((n) => moment(n.day).format("DD/MM/YYYY"));
      // console.log("fkfkddf", array);
      // setBlackout(array);
    }
    setDescription(props.descriptiondata);
    setLat1(props.lat1);
    setLng1(props.lng1);
  }, [props]);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="col-md-12">
            <div className="settingmainlinksbuttondetailboxright">
              <div className="row">
                <div className="row"></div>
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="settingeditstudioprofileheading  customopertionmaintextheading">
                      Operational Hours
                      {/* ({timeZone}) */}
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4 className="settingeditstudioprofileheading  customopertionmaintextheading">
                      For blackout day , select a date from here
                    </h4>
                  </div>
                  <div className="col-md-6 datepicker-col-6">
                    <DatePicker
                      render={(value, openCalendar) => {
                        return (
                          <>
                            {value ? (
                              <div className="datepickereditstudio-col-6">
                                {value}
                              </div>
                            ) : (
                              <div>{value}</div>
                            )}

                            <img
                              onClick={openCalendar}
                              className="imgcalndr"
                              src="images/calendar.png"
                            />
                          </>
                        );
                      }}
                      value={BlackOut}
                      onChange={(date) => {
                        setBlackout(date);
                        //console.log("ghgh", date);
                      }}
                      multipleRangeSeparator=","
                      format="DD/MM/YYYY"
                      multiple={true}
                      minDate={new Date()}
                    />
                  </div>

                  {calendar.map((item, key) => (
                    <>
                      <div className="col-md-6" key={key}>
                        <div className="studiotimeinfo d-flex">
                          <h4 className="w-50 customlabelloginpagesetting">
                            {item.day}
                          </h4>
                          <div className="form-check form-switch w-50 ">
                            {item.check === "1" ? (
                              <input
                                className="form-check-input customtooglebtneditprofile"
                                type="checkbox"
                                role="switch"
                                checked={true}
                                onChange={() => handleChangeDay(item.id)}
                              />
                            ) : (
                              <input
                                className="form-check-input customtooglebtneditprofile"
                                type="checkbox"
                                role="switch"
                                checked={false}
                                onChange={() => handleChangeDay(item.id)}
                              />
                            )}
                          </div>
                        </div>
                        {
                          item.check === "1" ? (
                            <div className="">
                              <div className="studiotimeinfo d-flex customtoggledropdowndetail">
                                <div className="col-md-4">
                                  <h6 className="toggledropdowninnerinfotext">
                                    Select Time
                                  </h6>
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="time"
                                    className="form-control "
                                    placeholder="Phone Number"
                                    onChange={(e) => {
                                      // console.log(e.target.value);
                                      let i = calendar.findIndex(
                                        (N) => N.id === item.id
                                      );
                                      // console.log(i);
                                      let data1 = calendar;
                                      data1[i].available_from = e.target.value;
                                      // console.log(data1);
                                      setCalendar([...data1]);
                                    }}
                                    value={item.available_from}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <input
                                    type="time"
                                    className="form-control "
                                    placeholder="Phone Number"
                                    onChange={(e) => {
                                      //console.log(e.target.value);
                                      let i = calendar.findIndex(
                                        (N) => N.id === item.id
                                      );
                                      //console.log(i);
                                      let data1 = calendar;
                                      let end = e.target.value?.split(":");
                                      let start =
                                        data1[i].available_from?.split(":");

                                      if (end[0] < start[0]) {
                                        Swal.fire(
                                          "please select end time greater than start time"
                                        );
                                      } else if (end[0] === start[0]) {
                                        if (end[1] < start[1]) {
                                          Swal.fire(
                                            "please select end time greater than start time"
                                          );
                                        }
                                      } else {
                                        data1[i].available_to = e.target.value;
                                        //console.log(data1);
                                        setCalendar([...data1]);
                                      }
                                    }}
                                    value={item.available_to}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null
                          // <> <p>closed</p> </>
                        }
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <button
                className="savebtnsetting mt-3"
                type="button"
                onClick={() => editStudioDetails()}
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default EditStudioDetail;
