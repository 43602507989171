import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper";
import bootstrap from "bootstrap";
import { updatepasswordApi } from "../Apis";

function UpdatePassword() {
  const [formValues, setFormValues] = useState({
    userid: "",
    password: "",
  });
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [message, setMessage] = useState("");
  let location = useLocation();

  const handleData = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: "" });
  };
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye" ? setIcon("fa fa-eye-slash") : setIcon("fa fa-eye");
  };

  const myData = (e) => {
    //console.log("hello submit");
    e.preventDefault();
    setError(validate(formValues));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  //form validations
  const validate = (formValues) => {
    let error = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!formValues.password) {
      error.password = "password is required.";
    } else if (formValues.password.length < 6) {
      error.password = "password is not valid.";
    } else if (!passwordRegex.test(formValues.password)) {
      error.password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }
    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      CallApi();
    }
    return error;
  };

  const CallApi = () => {
    setIsLoading(true);
    let data1 = location.state.data;
    //console.log("userid", data1);
    var data = JSON.stringify({
      userid: data1,
      password: formValues.password,
    });

    var config = {
      method: "post",
      url: updatepasswordApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          navigate("/login");
          setMessage(response.data.message);
          //alert(response.data.message);
        } else {
          setMessage(response.data.message);
          //alert(response.data.message);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="loginmainsection">
        <div className="container-fluid">
          <div className="row flex-direction-row">
            <div className="col-md-6">
              <div className="loginleftsection">
                <div className="mainloginlogobox">
                  <Link to="/">
                    <img src="images/mainlogo.png" />
                  </Link>
                </div>
                <h2 className="customcreateaccountheadingsetting">
                  Reset Password
                </h2>

                <form onSubmit={myData}>
                  <div className="row">
                    {message && (
                      <span className="customvalidationdesignlogin">
                        {message}
                      </span>
                    )}
                    <div className="col-md-12">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Password
                        </label>
                        <div className="iconbox2">
                          <i onClick={show} className={icon}></i>
                        </div>
                        <input
                          type={type}
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="**********"
                          name="password"
                          value={formValues.password}
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                        />

                        {error.password && (
                          <span style={{ color: "red" }}>{error.password}</span>
                        )}
                        <span>
                          * Password must have at least 1 uppercase letter, 1
                          lowercase letter, 1 special character and 1 number.
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mainloginbutton">
                        <div className="loginbuttonlink">
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="loginrighttsection">
                <div className="loginrightinnertextsectionupper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2800,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                    //navigation
                    //pagination={{ clickable: true }}
                    //scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log("slide change")}
                  >
                    <SwiperSlide>
                      <div className="sliderwidthtext">
                        <h1>
                          Booking your next <span>session</span> <br />
                          is now at your fingertips
                        </h1>
                        <p>
                          Unlock your creative potential within extraordinary
                          studio spaces.Discover - Reserve - Create for all
                          creatives such as  musicians, photographers, dancers,
                          filmmaker more.  Elevate your artistry with
                          state-of-the-art studios for musicians, photographers,
                          dancers, and filmmakers.
                        </p>
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h1>
                        Modern <span>Interior</span> <br />
                        Design Studio
                      </h1>
                      <p>
                        Unleash creativity in extraordinary studio spaces.
                        Discover, book, and create in inspiring sanctuaries at
                        Stubie.
                        <br />
                        Elevate your artistry with state-of-the-art studios for
                        musicians, photographers, dancers, and filmmakers.
                      </p>
                    </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UpdatePassword;
