import React from "react";
function Loader() {
  return (
    <div className="loader">
      <div className="loader">
        <img src="images/loader.gif" />
      </div>
    </div>
  );
}
export default Loader;
