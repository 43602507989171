import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

function Protected(props) {
  let Cmp = props.Cmp;
  let navigate = useNavigate();

  useEffect(() => {
      let local = localStorage.getItem("userid");

    if (!local) {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <Cmp />
    </div>
  );
}
export default Protected;
