import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Modal } from "bootstrap";
import { set } from "firebase/database";

function TermsAndConditions() {
  const [Details, setDetails] = useState({});
  const [images, setImages] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [phoneno1, setPhoneno1] = useState("");
  const [studoType, setStudioType] = useState("");
  const [checkbox, setCheckbox] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [street, setStreet] = useState("");
  const [Country, setCountry] = useState([]);
  const [address, setAddress] = useState("");
  let location = useLocation();
  const navigate = useNavigate();

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [country_code, setCountryCode] = useState("");
  useEffect(() => {
    let Detail = location.state.name;
    let images = location.state.image;
    let StudioType = location.state.studioType;
    let phoneno = location.state.phoneno;
    let phoneno1 = location.state.phoneno1;
    let checkbox = location.state.checkbox;
    let city = location.state.city;
    let zipcode = location.state.zipcode;
    let street = location.state.street;
    let state = location.state.state;
    let address = location.state.address;
    let country = location.state.countryname;
    let lng = location.state.lng;
    let lat = location.state.lat;
    let countryCode = location.state.country_code;
    setLng(lng);
    setLat(lat);
    setCountryCode(countryCode);
    setCountry(country);
    setAddress(address);
    setCity(city);
    setZipcode(zipcode);
    setStreet(street);
    setState(state);
    setCheckbox(checkbox);
    setPhoneno(phoneno);
    setPhoneno1(phoneno1);
    setStudioType(StudioType);
    setImages(images);
    setDetails(Detail);
    // console.log("detaillll", Detail);
  }, []);
  return (
    <>
      <div className="stubieprofilebannersection">
        <a
          className="backlink"
          onClick={() => {
            navigate("/signup", {
              state: {
                data: Details,
                images: images,
                studioType: studoType,
                phoneno: phoneno,
                phoneno1: phoneno1,
                checkbox: checkbox,
                city: city,
                state: state,
                zipcode: zipcode,
                street: street,
                address: address,
                Country: Country,
                lat: lat,
                lng: lng,
                country_code: country_code,
              },
            });
          }}
        >
          <img src="images/backbtn1.png" />
        </a>
        {/* <button
          onClick={() => {
            navigate("/myprofile", { state: {visible:true } });
          }}
        >
          back
        </button> */}
        <div className="container-fluid">
          <h1 className="changepasswordmainheadingsetting">
            Terms And Conditions
          </h1>
        </div>
      </div>
      <div className="bookingdeatailmainsection bookingdetails-studio">
        <div className="container">
          <div className="row">
            <h3> Terms and Conditions</h3>
            <p>
              Welcome to Website Name! These terms and conditions outline the
              rules and regulations for the use of Company Name's Website,
              located at Website.com. <br /> By accessing this website we assume
              you accept these terms and conditions. Do not continue to use
              Website Name if you do not agree to take all of the terms and
              conditions stated on this page. <br />
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              “Client”, “You” and “Your” refers to you, the person log on this
              website and compliant to the Company's terms and conditions. “The
              Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our
              Company. “Party”, “Parties”, or “Us”, refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client's needs in respect of
              provision of the Company's stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same. <br />
              <h3> Cookies </h3> <br />
              We employ the use of cookies. By accessing Website Name, you
              agreed to use cookies in agreement with the Company Name's Privacy
              Policy. <br />
              Most interactive websites use cookies to let us retrieve the
              user's details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies. <br />
              <h3>License </h3> <br />
              Unless otherwise stated, Company Name and/or its licensors own the
              intellectual property rights for all material on Website Name. All
              intellectual property rights are reserved. You may access this
              from Website Name for your own personal use subjected to
              restrictions set in these terms and conditions. <br /> You must
              not: <br /> Republish material from Website Name <br />
              Sell, rent or sub-license material from Website Name Reproduce,{" "}
              <br /> duplicate or copy material from Website Name <br />{" "}
              Redistribute content from Website Name <br /> This Agreement shall
              begin on the date hereof. <br /> Parts of this website offer an
              opportunity for users to post and exchange opinions and
              information in certain areas of the website. Company Name does not
              filter, edit, publish or review Comments prior to their presence
              on the website. Comments do not reflect the views and opinions of
              Company Name,its agents and/or affiliates. Comments reflect the
              views and opinions of the person who post their views and
              opinions. To the extent permitted by applicable laws, Company Name
              shall not be liable for the Comments or for any liability, damages
              or expenses caused and/or suffered as a result of any use of
              and/or posting of and/or appearance of the Comments on this
              website. <br />
              Company Name reserves the right to monitor all Comments and to
              remove any Comments which can be considered inappropriate,
              offensive or causes breach of these Terms and Conditions.
              <br /> You warrant and represent that:
              <br /> You are entitled to post the Comments on our website and
              have all necessary licenses and consents to do so;
              <br /> The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party; <br />
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy The Comments will not be used to solicit or promote
              business or custom or present commercial activities or unlawful
              activity.
              <br /> You hereby grant Company Name a non-exclusive license to
              use, reproduce, edit and authorize others to use, reproduce and
              edit any of your Comments in any and all forms, formats or media.{" "}
              <br />
              <h3> Hyperlinking to our Content</h3> <br />
              The following organizations may link to our Website without prior
              written approval:
              <br /> Government agencies; <br />
              Search engines;
              <br /> News organizations;
              <br />
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and <br />
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site. <br />
              These organizations may link to our home page, to publications or
              to other Website information so long as the link: (a) is not in
              any way deceptive; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking
              party's site.
              <br /> We may consider and approve other link requests from the
              following types of organizations: <br />
              commonly-known consumer and/or business information sources;{" "}
              <br />
              dot.com community sites; <br />
              associations or other groups representing charities;
              <br /> online directory distributors;<p>
                {" "}
                internet portals;
              </p>{" "}
              <p>accounting, law and consulting firms; and</p>{" "}
              <p>educational institutions and trade associations.</p>
              <p>
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of Company Name; and (d) the link is in the context of general
                resource information.
              </p>
              <p>
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
              </p>
              <p>
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to Company Name. Please include your name,
                your organization name, contact information as well as the URL
                of your site, a list of any URLs from which you intend to link
                to our Website, and a list of the URLs on our site to which you
                would like to link. Wait 2-3 weeks for a response.
              </p>
              <h3>
                Approved organizations may hyperlink to our Website as follows:
              </h3>
              <p>
                By use of our corporate name; or By use of the uniform resource
                locator being linked to; or By use of any other description of
                our Website being linked to that makes sense within the context
                and format of content on the linking party's site. No use of
                Company Name's logo or other artwork will be allowed for linking
                absent a trademark license agreement.
              </p>
              <h3>iFrames</h3>
              <p>
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>
              <h3> Content Liability</h3>
              <p>
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>
              <h3> Reservation of Rights</h3>
              <p>
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it's linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>
              <h3>Removal of links from our website</h3>
              <p>
                {" "}
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>{" "}
              <p>
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>{" "}
              <h3>Disclaimer </h3>
              <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <p>
                {" "}
                limit or exclude our or your liability for death or personal
                injury;
              </p>{" "}
              <p>
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation; limit any of our or your liabilities in any
                way that is not permitted under applicable law;
              </p>{" "}
              or
              <p>
                exclude any of our or your liabilities that may not be excluded
                under applicable law. The limitations and prohibitions of
                liability set in this Section and elsewhere in this disclaimer:
              </p>
              (a) are subject to the preceding paragraph; and (b) govern all
              liabilities arising under the disclaimer, including liabilities
              arising in contract, in tort and for breach of statutory duty.
              <p>
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default TermsAndConditions;
