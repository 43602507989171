import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyBOpXLkyiSANStwYphX88tT_-UXJk46DLU",
  authDomain: "stubie-a31b2.firebaseapp.com",
  databaseURL: "https://stubie-a31b2.firebaseio.com",
  projectId: "stubie-a31b2",
  storageBucket: "stubie-a31b2.appspot.com",
  messagingSenderId: "692666827204",
  appId: "1:692666827204:web:021a55393d93d60f8d6491",
  measurementId: "G-WF30Y852SW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
// export default app;
export default database;
