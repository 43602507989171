import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import Loader from "../Loader/Loader";
import { changepasswordApi } from "../Apis";
import Swal from "sweetalert2";
function ChangePassword() {
  const [formValues, setFormValues] = useState({
    userid: "",
    new_password: "",
    old_password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [type1, setType1] = useState("password");
  const [icon1, setIcon1] = useState("fa fa-eye-slash");
  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState("fa fa-eye-slash");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye" ? setIcon("fa fa-eye-slash") : setIcon("fa fa-eye");
  };
  const show1 = () => {
    type1 === "password" ? setType1("text") : setType1("password");
    icon1 === "fa fa-eye" ? setIcon1("fa fa-eye-slash") : setIcon1("fa fa-eye");
  };
  const show2 = () => {
    type2 === "password" ? setType2("text") : setType2("password");
    icon2 === "fa fa-eye" ? setIcon2("fa fa-eye-slash") : setIcon2("fa fa-eye");
  };
  const handleData = (e) => {
    const { name, value } = e.target;
    //console.log(name);
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const myData = (e) => {
    //console.log("hello submit");
    e.preventDefault();
    //CallApi();
    // setIsLoading(true);
    setError(validate(formValues));
  };

  //form validations
  const validate = (formValues) => {
    let error = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!formValues.new_password) {
      error.new_password = "New password is required.";
    } else if (formValues.new_password.length < 6) {
      error.new_password = "password is not valid.";
    } else if (!passwordRegex.test(formValues.new_password)) {
      error.new_password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }

    if (!formValues.confirm_password) {
      error.confirm_password = "Confirm password is required.";
    } else if (formValues.confirm_password.length < 6) {
      error.confirm_password = "password is not valid.";
    } else if (formValues.confirm_password !== formValues.new_password) {
      error.confirm_password = "password is not valid.";
    }

    if (!formValues.old_password) {
      error.old_password = "Old password is required.";
    } else if (formValues.old_password.length < 6) {
      error.old_password = "password is not valid.";
    }

    //console.log(Object.keys(error).length);
    if (Object.keys(error).length === 0) {
      CallApi();
    }
    return error;
  };

  const CallApi = () => {
    let userId = localStorage.getItem("userid");
    setIsLoading(true);
    var data = JSON.stringify({
      userid: userId,
      new_password: formValues.new_password,
      old_password: formValues.old_password,
    });

    var config = {
      method: "post",
      url: changepasswordApi,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.error === "0") {
          setShowModal(true);
          setTimeout(() => {
            navigate("/login");
          }, 4000);
          //setMessage(response.data.message);
          Swal.fire(
            "Please login again using new password",
            response.data.message
          );
          //alert(response.data.message);
        } else {
          setTimeout(() => {}, 4000);
          setShowModal(true);
          //setMessage(response.data.message);
          Swal.fire(response.data.message);
          //alert(response.data.message);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      // handleSubmit(event);
      myData();
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}

      <Header />

      <div className="stubieprofilebannersection">
        <div className="container-fluid">
          <h1 className="changepasswordmainheadingsetting">Change Password</h1>
        </div>
      </div>
      <div
        className="tistpop"
        style={{
          visibility: showModal === true ? "visible" : "hidden",
        }}
      >
        {message && (
          <span className="customvalidationdesignlogin">{message}</span>
        )}
      </div>
      <div className="changepasswordmainsection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="bankdeatilinnersectionbox">
                <h4 className="bankdetailmaintextheading">Change Password</h4>
                <form onSubmit={myData}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Old Password
                        </label>
                        <div className="iconbox2">
                          <i onClick={show} className={icon}></i>
                        </div>
                        <input
                          type={type}
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="**********"
                          name="old_password"
                          value={formValues.old_password}
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                        />
                        {error.old_password && (
                          <span className="customvalidationdesignlogin">
                            {error.old_password}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          New Password
                        </label>
                        <div className="iconbox2">
                          <i onClick={show1} className={icon1}></i>
                        </div>
                        <input
                          type={type1}
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="*********"
                          name="new_password"
                          value={formValues.new_password}
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                        />

                        {error.new_password && (
                          <span className="customvalidationdesignlogin">
                            {error.new_password}
                          </span>
                        )}
                        <span>
                          * Password must have at least 1 uppercase letter, 1
                          lowercase letter, 1 special character and 1 number.
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="eyegroup">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label customlabelloginpagesetting"
                        >
                          Confirm Password
                        </label>
                        <div className="iconbox2">
                          <i onClick={show2} className={icon2}></i>
                        </div>
                        <input
                          type={type2}
                          className="form-control custominputtypeloginpagesetting"
                          placeholder="**********"
                          name="confirm_password"
                          value={formValues.confirm_password}
                          onChange={handleData}
                          onKeyDown={handleKeyPress}
                        />
                        {error.confirm_password && (
                          <span className="customvalidationdesignlogin">
                            {error.confirm_password}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary customsavebtnchangespopup  customsavebuttonsettingbankinfotab   customsavebtnchangepasswordsetting"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default ChangePassword;
